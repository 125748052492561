import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import moment from "moment";
import {
  BookCourseState,
  RootState,
  BookCourseStepOne,
  BookCourseStepTwo,
  BookCourseStepThree,
  FetchCourseParams,
  BookCourseParam,
  Instructor,
  Course,
} from "@/@types";
import { Container } from "skygear";
import { parseCourse, parseInstructor } from "./parsers";
import { AsyncStates, AsyncCompletion } from "./async";
import { sortTimeslots } from "@/helpers/timeslot";

export const defaultState = (): BookCourseState => ({
  instructor: AsyncStates.default(),
  course: AsyncStates.default(),
  stepOne: {
    userIs: "STUDENT",
    studentName: "",
    studentGender: "MALE",
    studentAgeRange: "",
    remark: "",
    contactPhone: "",
  },
  stepTwo: {
    venueType: null,
    region: null,
    address: "",
    timeslots: [
      { date: null, fromTime: null, toTime: null },
      { date: null, fromTime: null, toTime: null },
      { date: null, fromTime: null, toTime: null },
    ],
  },
  stepThree: {
    cardHolderName: "",
    email: "",
  },
  done: {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
  },
});

const createState = (): BookCourseState => {
  return defaultState();
};

const createGetters = (): GetterTree<BookCourseState, RootState> => {
  return {};
};

const createActions = (
  skygear: Container
): ActionTree<BookCourseState, RootState> => {
  return {
    async fetchInstructor({ state, commit }, params) {
      commit("fetchInstructorBegin");
      const requestId = state.instructor.currentRequestId;
      let data: Instructor | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:get_instructor",
          params
        );
        data = parseInstructor(response);
      } catch (e) {
        error = e;
      }
      commit("fetchInstructorCompleted", { requestId, data, error });
    },
    async fetchCourse({ state, commit }, params: FetchCourseParams) {
      commit("fetchCourseBegin");
      const requestId = state.course.currentRequestId;
      let data: Course | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:get_instructor_offer_course",
          params
        );
        data = parseCourse(response);
      } catch (e) {
        error = e;
      }
      commit("fetchCourseCompleted", { requestId, data, error });
    },
    setStepOne({ commit, rootGetters }, params: BookCourseStepOne | null) {
      commit(
        "setStepOne",
        params === null
          ? {
              ...defaultState().stepOne,
              studentName: rootGetters["auth/defaultStudentName"],
              contactPhone: rootGetters["auth/phoneNumber"],
            }
          : params
      );
      commit("setDone", { stepOne: params !== null });
    },
    setStepTwo({ commit }, params: BookCourseStepTwo | null) {
      commit("setStepTwo", params === null ? defaultState().stepTwo : params);
      commit("setDone", { stepTwo: params !== null });
    },
    setStepThree({ commit, rootGetters }, params: BookCourseStepThree | null) {
      commit(
        "setStepThree",
        params === null
          ? {
              ...defaultState().stepThree,
              cardHolderName: rootGetters["auth/name"],
              email: rootGetters["auth/email"],
            }
          : params
      );
      commit("setDone", { stepThree: params !== null });
    },
    async bookCourse({ state }, params: BookCourseParam) {
      const { duration, stripeToken } = params;
      const { timeslots } = state.stepTwo;
      const response = await skygear.lambda("musicmap:create_course_booking", {
        courseId: state.course.data!.id,
        duration,
        price: (state.course.data as any)![`${duration}MinsActualPrice`],
        stripeToken,
        email: state.stepThree.email,
        data: {
          ...state.stepOne,
          ...state.stepTwo,
          timeslots: sortTimeslots(timeslots).map((timeslot) => ({
            date: timeslot.date ? moment(timeslot.date).utc() : null,
            fromTime: timeslot.fromTime
              ? moment(timeslot.fromTime).utc()
              : null,
            toTime: timeslot.toTime ? moment(timeslot.toTime).utc() : null,
          })),
        },
      });
      return response;
    },
  };
};

const createMutations = (): MutationTree<BookCourseState> => {
  return {
    fetchInstructorBegin(state: BookCourseState) {
      AsyncStates.begin(state.instructor, true);
    },
    fetchInstructorCompleted(
      state: BookCourseState,
      params: AsyncCompletion<Instructor>
    ) {
      AsyncStates.completed(state.instructor, params);
    },

    fetchCourseBegin(state: BookCourseState) {
      AsyncStates.begin(state.course, true);
    },
    fetchCourseCompleted(
      state: BookCourseState,
      params: AsyncCompletion<Course>
    ) {
      AsyncStates.completed(state.course, params);
    },

    setStepOne(state: BookCourseState, params: BookCourseStepOne) {
      state.stepOne = params;
    },
    setStepTwo(state: BookCourseState, params: BookCourseStepTwo) {
      state.stepTwo = params;
    },
    setStepThree(state: BookCourseState, params: BookCourseStepThree) {
      state.stepThree = params;
    },
    setDone(state: BookCourseState, params: { [key: string]: boolean }) {
      state.done = {
        ...state.done,
        ...params,
      };
    },
  };
};

export const createBookCourseModule = (
  skygear: Container
): Module<BookCourseState, RootState> => {
  return {
    namespaced: true,
    state: createState(),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  };
};
