







import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class TextInput extends Vue {
  @Prop({ default: "" }) public value!: string;
  @Prop({ default: "" }) public type!: string;
}
