import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import {
  CodesState,
  RootState,
  Area,
  Region,
  Skill,
  TuningInstrument,
  TuningPackage,
  PageTuning,
} from "@/@types";
import {
  parseSkill,
  parseRegion,
  parseTuningInstrument,
  parseTuningPackage,
  parsePageTuning,
} from "@/store/parsers";
import { Container } from "skygear";
import { AsyncStates, AsyncCompletion } from "./async";

const createState = (): CodesState => {
  return {
    priceRanges: [
      { code: "0", min: 150, max: 250 },
      { code: "1", min: 251, max: 400 },
      { code: "2", min: 401, max: null },
    ],
    regions: AsyncStates.default(),
    skills: AsyncStates.default(),
    tuningInstruments: AsyncStates.default(),
    tuningPackages: AsyncStates.default(),
    studentGender: ["MALE", "FEMALE"],
    userIs: ["STUDENT", "PARENT"],
    venueType: ["STUDENT", "INSTRUCTOR"],
    pageTuning: AsyncStates.default(),
  };
};

const createGetters = (): GetterTree<CodesState, RootState> => {
  return {
    filterRegionsByArea(state: CodesState) {
      return (area: Area) =>
        (state.regions.data || []).filter((r) => r.area === area);
    },
    findPriceRangeByCode(state: CodesState) {
      return (code: string) => state.priceRanges.find((p) => p.code === code);
    },
    findRegionByCode(state: CodesState) {
      return (code: string) =>
        (state.regions.data || []).find((s) => s.code === code);
    },
    findSkillByCode(state: CodesState) {
      return (code: string) =>
        (state.skills.data || []).find((s) => s.code === code);
    },
    findSkillLevelByCode(state: CodesState) {
      return (skillCode: string, levelCode: string) => {
        const skill = (state.skills.data || []).find(
          (s) => s.code === skillCode
        );
        return skill
          ? skill.levels.find((l) => l.code === levelCode)
          : undefined;
      };
    },
  };
};

const createActions = (
  skygear: Container
): ActionTree<CodesState, RootState> => {
  return {
    async fetchRegions({ state, commit }, params) {
      if (state.regions.data) {
        return;
      }

      commit("fetchRegionsBegin");
      const requestId = state.regions.currentRequestId;
      let data: Region[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:list_regions", params);
        data = response.map(parseRegion);
      } catch (e) {
        error = e;
      }
      commit("fetchRegionsCompleted", { requestId, data, error });
    },
    async fetchSkills({ state, commit }, params) {
      if (state.skills.data) {
        return;
      }

      commit("fetchSkillsBegin");
      const requestId = state.skills.currentRequestId;
      let data: Skill[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:list_skills", params);
        data = response.map(parseSkill);
      } catch (e) {
        error = e;
      }
      commit("fetchSkillsCompleted", { requestId, data, error });
    },
    async fetchTuningInstruments({ state, commit }, params) {
      if (state.tuningInstruments.data) {
        return;
      }

      commit("fetchTuningInstrumentsBegin");
      const requestId = state.tuningInstruments.currentRequestId;
      let data: TuningInstrument[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:list_tuning_instruments",
          params
        );
        data = response.map(parseTuningInstrument);
      } catch (e) {
        error = e;
      }
      commit("fetchTuningInstrumentsCompleted", { requestId, data, error });
    },
    async fetchTuningPackages({ state, commit }, params) {
      if (state.tuningPackages.data) {
        return;
      }

      commit("fetchTuningPackagesBegin");
      const requestId = state.tuningPackages.currentRequestId;
      let data: TuningPackage[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:list_tuning_packages",
          params
        );
        data = response.map(parseTuningPackage);
      } catch (e) {
        error = e;
      }
      commit("fetchTuningPackagesCompleted", { requestId, data, error });
    },
    async fetchPageTuning({ state, commit }, params) {
      if (state.pageTuning.data) {
        return;
      }

      commit("fetchPageTuningBegin");
      const requestId = state.pageTuning.currentRequestId;
      let data: PageTuning | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:get_page_tuning",
          params
        );
        data = parsePageTuning(response);
      } catch (e) {
        error = e;
      }
      commit("fetchPageTuningCompleted", { requestId, data, error });
    },
  };
};

const createMutations = (): MutationTree<CodesState> => {
  return {
    fetchRegionsBegin(state: CodesState) {
      AsyncStates.begin(state.regions, false);
    },
    fetchRegionsCompleted(
      state: CodesState,
      params: AsyncCompletion<Region[]>
    ) {
      AsyncStates.completed(state.regions, params);
    },

    fetchSkillsBegin(state: CodesState) {
      AsyncStates.begin(state.skills, false);
    },
    fetchSkillsCompleted(state: CodesState, params: AsyncCompletion<Skill[]>) {
      AsyncStates.completed(state.skills, params);
    },

    fetchTuningInstrumentsBegin(state: CodesState) {
      AsyncStates.begin(state.tuningInstruments, false);
    },
    fetchTuningInstrumentsCompleted(
      state: CodesState,
      params: AsyncCompletion<TuningInstrument[]>
    ) {
      AsyncStates.completed(state.tuningInstruments, params);
    },

    fetchTuningPackagesBegin(state: CodesState) {
      AsyncStates.begin(state.tuningPackages, false);
    },
    fetchTuningPackagesCompleted(
      state: CodesState,
      params: AsyncCompletion<TuningPackage[]>
    ) {
      AsyncStates.completed(state.tuningPackages, params);
    },

    fetchPageTuningBegin(state: CodesState) {
      AsyncStates.begin(state.pageTuning, false);
    },
    fetchPageTuningCompleted(
      state: CodesState,
      params: AsyncCompletion<PageTuning>
    ) {
      AsyncStates.completed(state.pageTuning, params);
    },
  };
};

export const createCodesModule = (skygear: Container) => {
  return {
    namespaced: true,
    state: createState(),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  } as Module<CodesState, RootState>;
};
