

































import { Mixins, Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import SuccessForm from "@/components/SuccessForm.vue";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

@Component({
  components: {
    Layout,
    Top,
    Bottom,
    SuccessForm,
    I18nRouterLink,
  },
})
export default class PaymentSuccess extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  get title() {
    return this.$t("bookTuning.success.title");
  }
}
