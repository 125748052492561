import { render, staticRenderFns } from "./ActivityDate.vue?vue&type=template&id=209d81f2&scoped=true&"
import script from "./ActivityDate.vue?vue&type=script&lang=ts&"
export * from "./ActivityDate.vue?vue&type=script&lang=ts&"
import style0 from "./ActivityDate.vue?vue&type=style&index=0&id=209d81f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209d81f2",
  null
  
)

export default component.exports