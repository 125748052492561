import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState, BookingsState, PagingResult, Booking } from "@/@types";
import { Container } from "skygear";
import { parseBooking, parseBookingCourse } from "@/store/parsers";
import { AsyncStates, AsyncCompletion } from "./async";

const createState = (): BookingsState => {
  return {
    bookings: AsyncStates.default(),
  };
};

const createGetters = (): GetterTree<BookingsState, RootState> => {
  return {};
};

const createActions = (
  skygear: Container
): ActionTree<BookingsState, RootState> => {
  return {
    async listBookings({ state, commit }, params) {
      const { offset, limit } = params;
      const appending = offset > 0;
      commit("listBookingsBegin", appending);
      const requestId = state.bookings.currentRequestId;

      let data: PagingResult<Booking[]> | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:list_bookings", {
          offset,
          limit,
        });
        const result = response.models.map(parseBooking);
        const hasMore = (response.total as number) > offset + limit;
        data = { value: result, hasMore };
      } catch (e) {
        error = e;
      }
      commit("listBookingsCompleted", { requestId, data, error });
    },
    async checkInCourseBooking({ state, commit }, params) {
      const { courseBookingId } = params;
      const response = await skygear.lambda(
        "musicmap:check_in_course_booking",
        {
          courseBookingId,
        }
      );
      commit("checkInCourseBooking", {
        courseBookingId: response._id,
        status: response.status,
      });
    },
    async rateCourseBooking({ state, commit }, params) {
      const { courseBookingId, rating, comment } = params;
      const response = await skygear.lambda("musicmap:rate_course_booking", {
        courseBookingId,
        rating,
        comment,
      });
      commit("rateCourseBooking", {
        courseBookingId: response._id,
        rating: response.rating,
        comment: response.comment,
      });
    },
  };
};

const createMutations = (): MutationTree<BookingsState> => {
  return {
    listBookingsBegin(state: BookingsState, appending: boolean) {
      AsyncStates.begin(state.bookings, !appending);
    },
    listBookingsCompleted(
      state: BookingsState,
      params: AsyncCompletion<PagingResult<Booking[]>>
    ) {
      if (state.bookings.data && params.data) {
        params.data.value = [
          ...state.bookings.data.value,
          ...params.data.value,
        ];
      }
      AsyncStates.completed(state.bookings, params);
    },
    checkInCourseBooking(state: BookingsState, params) {
      const bookings = state.bookings.data!.value;
      const { courseBookingId, status } = params;
      state.bookings.data!.value = bookings.map((booking) => {
        const { bookingCourse } = booking;
        if (!bookingCourse || bookingCourse.id !== courseBookingId) {
          return booking;
        }
        bookingCourse.status = status;
        return booking;
      });
    },
    rateCourseBooking(state: BookingsState, params) {
      const bookings = state.bookings.data!.value;
      const { courseBookingId, rating, comment } = params;
      state.bookings.data!.value = bookings.map((booking) => {
        const { bookingCourse } = booking;
        if (!bookingCourse || bookingCourse.id !== courseBookingId) {
          return booking;
        }
        bookingCourse.rating = rating;
        bookingCourse.comment = comment;
        return booking;
      });
    },
  };
};

export const createBookingsModule = (
  skygear: Container
): Module<BookingsState, RootState> => {
  return {
    namespaced: true,
    state: createState(),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  };
};
