













import { Mixins, Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Route } from "vue-router";
import moment from "moment";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import TabBar from "@/components/TabBar.vue";
import ActivitiesTabBar from "@/components/Activities/ActivitiesTabBar.vue";
import ActivitiesList from "@/components/Activities/ActivitiesList.vue";
import MonthSelector from "@/components/Activities/MonthSelector.vue";
import Bottom from "@/components/Bottom.vue";
import ErrorView from "@/components/ErrorView.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import { AsyncStates, AsyncStatus } from "@/store/async";
import {
  ActivityType,
  AsyncDataParam,
  AsyncState,
  ListActivitiesResult,
  Activity,
} from "@/@types";

const VueScrollTo =
  // tslint:disable-next-line:no-var-requires
  typeof window === "undefined" ? {} : require("vue-scrollto");

const Activity = namespace("activity");
const PAGE_SIZE = 100;

const getActivityType = (route: Route): ActivityType | null => {
  switch (route.params.type) {
    case "concert":
      return "CONCERT";
    case "workshop":
      return "WORKSHOP";
    case "contest":
      return "CONTEST";
    default:
      return null;
  }
};

@Component({
  components: {
    Layout,
    Top,
    TabBar,
    ActivitiesTabBar,
    ActivitiesList,
    MonthSelector,
    Bottom,
    ErrorView,
  },
})
export default class Activities extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public static async asyncData({ store, route }: AsyncDataParam) {
    await store.dispatch("activity/listActivities", {
      type: getActivityType(route),
      offset: 0,
      limit: PAGE_SIZE,
    });
  }

  public page = 0;
  public monthOptions: Array<{
    value: string;
    label: string;
  }> = [];
  public selectedMonth = "";

  get title() {
    const code = (getActivityType(this.$route) || "all").toLowerCase();
    const type = this.$t(`activities.tabbar.${code}`);
    return this.$t("activities.title", { type });
  }

  @Activity.State("activityList")
  public activityListState!: AsyncState<ListActivitiesResult>;

  get activities() {
    const { data } = this.activityListState;
    return data ? data.value : [];
  }

  @Watch("activities")
  public onActivitiesChange() {
    this.loadMonthOptions();
  }

  public loadMonthOptions() {
    const months = this.activities.map(
      (activity) => moment(activity.startDate).month() + 1
    );
    this.monthOptions = [...new Set(months)].map((month) => ({
      label: this.$t("activities.monthSelector.optionLabel", {
        month: this.$t(`common.month.${month}`),
      }).toString(),
      value: month.toString(),
    }));
    const preserveSelectedMonth = this.monthOptions.find(
      (option) => option.value === this.selectedMonth
    );
    if (!preserveSelectedMonth) {
      this.selectedMonth = "";
    }
  }

  @Watch("selectedMonth")
  public onSelectedMonthChange() {
    const el = this.$el as HTMLElement;
    if (el) {
      const header = el.firstElementChild as HTMLElement;
      VueScrollTo.scrollTo(`.month-${this.selectedMonth}`, 2000, {
        offset: header.clientHeight * -1,
      });
    }
  }

  get isValidParams() {
    switch (this.$route.params.type) {
      case "concert":
      case "workshop":
      case "contest":
        return true;
    }
    return !this.$route.params.type;
  }

  @Watch("$route")
  public onRouteChange(route: Route) {
    this.page = 0;
  }

  public async mounted() {
    this.loadMonthOptions();
    if (this.page === 0) {
      await this.loadMore();
    }
  }

  public async loadMore() {
    const vm = this.$refs.content as Vue;
    await this.listActivities();
    if (
      vm &&
      vm.$el.clientHeight < (vm.$el.parentNode as HTMLElement).clientHeight
    ) {
      await new Promise<void>((resolve) => {
        window.setTimeout(() => resolve(this.listActivities()), 200);
      });
    }
  }

  public async listActivities() {
    if (
      AsyncStates.statusOf(this.activityListState) === AsyncStatus.LOADING ||
      (this.activityListState.data && !this.activityListState.data.hasMore) ||
      this.activityListState.error
    ) {
      return;
    }
    this.page = this.page + 1;
    await this.$store.dispatch("activity/listActivities", {
      type: getActivityType(this.$route),
      offset: this.page * PAGE_SIZE,
      limit: PAGE_SIZE,
    });
  }
}
