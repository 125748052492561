












import { Component, Vue, Prop } from "vue-property-decorator";
import { Skill, TuningInstrument, Translatable } from "@/@types";

@Component
export default class SkillTag extends Vue {
  @Prop({ default: "" }) public name!: Translatable;
  @Prop({ default: "" }) public iconUrl!: string;

  get localizedName() {
    const { locale } = this.$i18n;
    return this.name[locale] || "";
  }
}
