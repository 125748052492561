





import { Component, Vue } from "vue-property-decorator";
import { AsyncDataParam } from "@/@types";
import ErrorView from "@/components/ErrorView.vue";

@Component({
  components: {
    ErrorView,
  },
})
export default class Transparent extends Vue {
  public error: Error | null = null;

  public errorCaptured(error: Error) {
    this.error = error;
  }
}
