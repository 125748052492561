















































































import { Mixins, Component, Watch, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageTitle from "@/components/FormFields/PageTitle.vue";
import RadioField from "@/components/FormFields/RadioField.vue";
import TextField from "@/components/FormFields/TextField.vue";
import BookingSelector from "@/components/BookingSelector.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  AsyncState,
  Tuning,
  TuningInstrument,
  BookTuningStepOne,
  BookingSelectorOption,
} from "@/@types";

const Codes = namespace("codes");
const BookTuning = namespace("bookTuning");
@Component({
  components: {
    PageTitle,
    RadioField,
    TextField,
    BookingSelector,
  },
})
export default class StepOne extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @BookTuning.State("tunings") public tuningsState!: AsyncState<Tuning[]>;
  @BookTuning.State("stepOne") public stepOne!: BookTuningStepOne;
  @BookTuning.Getter("findTuningById") public findTuningById!: (
    id: string
  ) => Tuning | undefined;
  @Codes.State("tuningInstruments")
  public tuningInstruments!: AsyncState<TuningInstrument[]>;
  public tuningId = "";
  public requireAccessaryReplacement = false;
  public requireAccessaryReplacementRemark = "";
  public requireRelocation = false;
  public contactName = "";
  public contactPhone = "";
  public selectedInstrument = "";
  public submitted = false;

  get title() {
    return this.$t("bookTuning.step1.title");
  }
  get tunings() {
    return this.tuningsState.data;
  }

  get instrumentOptions() {
    const { locale } = this.$i18n;
    const instruments = this.tuningInstruments.data || [];
    return instruments.map((instrument) => ({
      value: instrument.code,
      label: instrument.name[locale],
      hint: instrument.remark[locale],
    }));
  }

  get tuningOptions() {
    const { locale } = this.$i18n;
    return (this.tunings || [])
      .filter(
        (tuning) => tuning.tuningInstrument.code === this.selectedInstrument
      )
      .map((tuning) => ({
        value: tuning.id,
        text: tuning.tuningPackage.name[locale],
        namedPrice: tuning.namedPrice,
        actualPrice: tuning.actualPrice,
      }));
  }

  @Watch("tunings")
  public onTuningsChanged() {
    this.updateSelectedInstrument();
  }

  @Watch("instrumentOptions")
  public onInstrumentOptionsChanged() {
    this.updateSelectedInstrument();
  }

  public updateSelectedInstrument() {
    const tuning = this.findTuningById(this.tuningId);
    if (tuning) {
      this.selectedInstrument = tuning.tuningInstrument.code;
    } else if (this.instrumentOptions.length > 0) {
      this.selectedInstrument = this.instrumentOptions[0].value;
    } else {
      this.selectedInstrument = "";
    }
  }

  public mounted() {
    const {
      tuningId,
      requireAccessaryReplacement,
      requireAccessaryReplacementRemark,
      requireRelocation,
      contactName,
      contactPhone,
    } = this.stepOne;
    this.tuningId = tuningId;
    this.requireAccessaryReplacement = requireAccessaryReplacement;
    this.requireAccessaryReplacementRemark = requireAccessaryReplacementRemark;
    this.requireRelocation = requireRelocation;
    this.contactName = contactName;
    this.contactPhone = contactPhone;
    this.updateSelectedInstrument();
  }

  get validateAccessaryReplacementRemarkError() {
    if (this.submitted && !this.validateAccessaryReplacementRemark()) {
      return this.$t(
        "bookTuning.requireAccessaryReplacementRemark.errors.required"
      );
    }
    return "";
  }
  get contactNameError() {
    if (this.submitted && !this.validateContactName()) {
      return this.$t("bookTuning.step1.contactName.errors.required");
    }
    return "";
  }

  get contactPhoneError() {
    if (this.submitted) {
      if (!this.validateContactPhoneRequired()) {
        return this.$t("bookTuning.step1.contactPhone.errors.required");
      }
      if (!this.validateContactPhoneFormat()) {
        return this.$t("bookTuning.step1.contactPhone.errors.format");
      }
    }
    return "";
  }

  public validateAccessaryReplacementRemark() {
    return (
      !this.requireAccessaryReplacement ||
      this.requireAccessaryReplacementRemark.length > 0
    );
  }
  public validateContactName() {
    return this.contactName.length > 0;
  }
  public validateContactPhoneRequired() {
    return this.contactPhone.length > 0;
  }
  public validateContactPhoneFormat() {
    return this.contactPhone.match(/^[0-9]{8}$/);
  }
  public validate() {
    return (
      this.validateAccessaryReplacementRemark() &&
      this.validateContactName() &&
      this.validateContactPhoneRequired() &&
      this.validateContactPhoneFormat()
    );
  }
  public onNext(option: BookingSelectorOption) {
    this.submitted = true;
    if (this.validate()) {
      this.$store.dispatch("bookTuning/setStepOne", {
        tuningId: option.value,
        requireAccessaryReplacement: this.requireAccessaryReplacement,
        requireAccessaryReplacementRemark: this
          .requireAccessaryReplacementRemark,
        requireRelocation: this.requireRelocation,
        contactName: this.contactName,
        contactPhone: this.contactPhone,
      });
      this.$router.push("2");
    }
  }
  public onBack() {
    const { locale } = this.$i18n;
    this.$router.push(`/${locale}/tuning`);
  }
}
