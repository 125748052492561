export function isWebview() {
  const useragent = navigator.userAgent;
  const rules = [
    "WebView",
    "(iPhone|iPod|iPad)(?!.*Safari/)",
    "Android.*(wv|.0.0.0)",
  ];
  const regex = new RegExp(`(${rules.join("|")})`, "ig");
  return Boolean(useragent.match(regex));
}
