







import { Component, Vue, Prop } from "vue-property-decorator";
import { Region } from "@/@types";

@Component
export default class RegionList extends Vue {
  @Prop() public regions!: Region[];

  get list() {
    return this.regions
      .map((r) => r.name[this.$i18n.locale])
      .join(this.$t("common.comma") as string);
  }
}
