







import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MenuButton from "@/components/Top/MenuButton.vue";
import LoginButton from "@/components/Top/LoginButton.vue";

const Auth = namespace("auth");

@Component({
  components: {
    MenuButton,
    LoginButton,
  },
})
export default class Login extends Vue {
  @Auth.Getter("isLoggedIn") public isLoggedIn!: boolean;
}
