







import { Component, Prop, Vue } from "vue-property-decorator";
import PaymentRequestButton from "@/components/PaymentForm/PaymentRequestButton.vue";

@Component({
  components: { PaymentRequestButton },
})
export default class ApplePayButton extends Vue {
  @Prop({ required: true }) public price!: number;
  @Prop({ default: "" }) public totalLabel!: string;
  public stripe = "";
  public options = {};
  public created() {
    this.stripe = `${this.configs.VUE_APP_STRIPE_API_KEY}`;
    this.options = {
      country: "HK",
      currency: "hkd",
      total: {
        label: this.totalLabel || this.$t("applePay.total.label"),
        amount: this.price * 100, // convert from dollars to cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
    };
  }
}
