

























import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import ProfileImage from "@/components/InstructorCard/ProfileImage.vue";
import ProfileTag from "@/components/ProfileTag.vue";
import RecordStatus from "@/components/Record/RecordStatus.vue";
import CourseDetails from "@/components/Record/Course/CourseDetails.vue";
import CheckInButton from "@/components/Record/Course/CheckInButton.vue";
import RatingField from "@/components/Record/Course/RatingField.vue";
import { BookingCourse, Payment } from "@/@types";

@Component({
  components: {
    ProfileImage,
    ProfileTag,
    RecordStatus,
    CourseDetails,
    CheckInButton,
    RatingField,
  },
})
export default class CourseRecordCard extends Vue {
  @Prop({ required: true }) public bookingCourse!: BookingCourse;
  @Prop({ required: true }) public payment!: Payment;

  get isConfirmed() {
    const { status, confirmedDatetime } = this.bookingCourse;
    if (!confirmedDatetime) {
      return false;
    }
    if (status !== "CONFIRMED") {
      return false;
    }
    return moment(confirmedDatetime)
      .subtract(1, "hours")
      .isBefore();
  }

  get isCheckedIn() {
    const { status } = this.bookingCourse;
    return status === "PUNCHEDIN";
  }

  get isRated() {
    const { rating } = this.bookingCourse;
    return !!rating;
  }
}
