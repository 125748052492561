
import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
const env = typeof window === "undefined" ? "server" : "client";

@Component
export default class OGImageMixin extends Vue {
  get fallbackOGImage() {
    return require("@/assets/img/mm-logo-red.png");
  }
  public setOGImageOnServerSide() {
    if (env === "server") {
      const self = this as any;
      self.$ssrContext.ogImageUrl = self.ogImage || this.fallbackOGImage;
    }
  }
  public created() {
    this.setOGImageOnServerSide();
  }
  public setOGImageOnClientSide() {
    if (env === "client") {
      const els = document.getElementsByTagName("meta");
      const el = Array.from(els).find(
        (el) => el.getAttribute("property") === "og:image"
      );
      if (el) {
        const self = this as any;
        el.setAttribute("content", self.ogImage || this.fallbackOGImage);
      }
    }
  }
  public mounted() {
    this.setOGImageOnClientSide();
  }
  public updated() {
    this.setOGImageOnClientSide();
  }
}
