









































































import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageTitle from "@/components/FormFields/PageTitle.vue";
import TextField from "@/components/FormFields/TextField.vue";
import SegmentedField from "@/components/FormFields/SegmentedField.vue";
import SelectField from "@/components/FormFields/SelectField.vue";
import NextButton from "@/components/FormFields/NextButton.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import { AsyncState, SkillLevel, Course, BookCourseStepOne } from "@/@types";

const Codes = namespace("codes");
const BookCourse = namespace("bookCourse");
@Component({
  components: {
    PageTitle,
    TextField,
    SegmentedField,
    SelectField,
    NextButton,
  },
})
export default class StepOne extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @BookCourse.State("course") public courseState!: AsyncState<Course>;
  @BookCourse.State("stepOne") public stepOne!: BookCourseStepOne;
  @Codes.State("studentGender") public studentGenderCodes!: string[];
  @Codes.State("userIs") public userIsCodes!: string[];

  public userIs = "STUDENT";
  public studentName = "";
  public studentGender = "MALE";
  public studentAgeRange = "";
  public remark = "";
  public contactPhone = "";
  public submitted = false;

  get title() {
    return this.$t("bookCourse.step1.title");
  }

  get course() {
    return this.courseState.data;
  }

  get level() {
    if (!this.course) {
      return "";
    }
    const { locale } = this.$i18n;
    const { duration } = this.$route.params;
    return this.$t("bookCourse.step1.level.value", {
      name: this.course.level.name[locale],
      duration,
    });
  }
  get studentNameError() {
    if (this.submitted && !this.validateStudentName()) {
      return this.$t("bookCourse.step1.studentName.errors.required");
    }
    return "";
  }
  get ageRangeError() {
    if (this.submitted && !this.validateStudentAgeRange()) {
      return this.$t("bookCourse.step1.studentAgeRange.errors.required");
    }
    return "";
  }
  get contactPhoneError() {
    if (this.submitted) {
      if (!this.validateContactPhoneRequired()) {
        return this.$t("bookCourse.step1.contactPhone.errors.required");
      }
      if (!this.validateContactPhoneFormat()) {
        return this.$t("bookCourse.step1.contactPhone.errors.format");
      }
    }
    return "";
  }
  public mounted() {
    this.userIs = this.stepOne.userIs;
    this.studentName = this.stepOne.studentName;
    this.studentGender = this.stepOne.studentGender;
    this.studentAgeRange = this.stepOne.studentAgeRange;
    this.remark = this.stepOne.remark;
    this.contactPhone = this.stepOne.contactPhone;
  }
  public validateStudentName() {
    return this.studentName.length > 0;
  }
  public validateStudentAgeRange() {
    return this.studentAgeRange.length > 0;
  }
  public validateContactPhoneRequired() {
    return this.contactPhone.length > 0;
  }
  public validateContactPhoneFormat() {
    return this.contactPhone.match(/^[0-9]{8}$/);
  }
  public validate() {
    return (
      this.validateStudentName() &&
      this.validateStudentAgeRange() &&
      this.validateContactPhoneRequired() &&
      this.validateContactPhoneFormat()
    );
  }
  public onNext() {
    this.submitted = true;
    if (this.validate()) {
      this.$store.dispatch("bookCourse/setStepOne", {
        userIs: this.userIs,
        studentName: this.studentName,
        studentGender: this.studentGender,
        studentAgeRange: this.studentAgeRange,
        remark: this.remark,
        contactPhone: this.contactPhone,
      });
      this.$router.push("2");
    }
  }
  public onBack() {
    const { locale } = this.$i18n;
    const { slug, skill } = this.$route.params;
    this.$router.push(`/${locale}/tutor/${slug}/${skill}`);
  }
}
