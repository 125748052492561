declare module "vue/types/vue" {
  interface Vue {
    configs: {
      NODE_ENV: string;
      BASE_URL: string;
      GA_TRACKING_ID: string;
      FACEBOOK_APP_ID: number;
      VUE_APP_SKYGEAR_SERVER_ENDPOINT: string;
      VUE_APP_SKYGEAR_CLIENT_ENDPOINT: string;
      VUE_APP_SKYGEAR_API_KEY: string;
      VUE_APP_STRIPE_API_KEY: string;
      VUE_APP_DEBUG: string;
    };
  }
}

export async function getRuntimeConfig() {
  try {
    const runtimeConfig = await fetch("/runtime.json");
    return await runtimeConfig.json();
  } catch (err) {
    console.log("Failed to fetch runtime.json");
    return {};
  }
}
