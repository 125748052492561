












import { Component, Vue } from "vue-property-decorator";

@Component
export default class LanguageSwitcher extends Vue {
  public selectedOption: string | null = null;
  public options = [
    { value: "en", text: "EN" },
    { value: "hk", text: "繁" },
    { value: "cn", text: "简" },
  ];
  public created() {
    this.selectedOption = this.$i18n.locale;
  }
  public changeLanguage() {
    const locale = this.selectedOption || this.$i18n.fallbackLocale;
    const regex = /(en|hk|cn)/i;
    const path = this.$route.path.replace(regex, locale);
    this.$router.push({ path });
  }
}
