



























import { Component, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import InstructorCard from "@/components/InstructorCard.vue";
import {
  Instructor,
  Skill,
  AsyncState,
  PagingResult,
  QueryInstructorsParam,
} from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";

const Instructors = namespace("instructors");
const Codes = namespace("codes");
@Component({
  components: { I18nRouterLink, LoadingIcon, InstructorCard },
})
export default class MatchedInstructors extends Vue {
  get skill() {
    return this.findSkillByCode(this.$route.params.skill)!;
  }

  get matchResult(): PagingResult<Instructor[]> {
    return this.matchState.data || { value: [], hasMore: true };
  }

  get isEmpty() {
    return !this.isLoading && this.matchResult.value.length === 0;
  }

  get isLoading(): boolean {
    return AsyncStates.statusOf(this.matchState) === AsyncStatus.LOADING;
  }

  get disableInfiniteScroll() {
    return this.isLoading || !this.matchResult.hasMore;
  }
  @Instructors.State("matchedInstructors")
  public matchState!: AsyncState<PagingResult<Instructor[]>>;

  @Codes.Getter("findSkillByCode") public findSkillByCode!: (
    code: string
  ) => Skill | undefined;

  public onScroll() {
    this.$emit("load-data");
  }
}
