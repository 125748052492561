










import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Layout extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: "" }) public error!: string;
}
