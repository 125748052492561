


























































import moment from "moment";
import { getMomentLocale } from "@/i18n";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingCourse, Payment } from "@/@types";

@Component
export default class CourseDetails extends Vue {
  @Prop({ required: true }) public bookingCourse!: BookingCourse;
  @Prop({ required: true }) public payment!: Payment;

  get status() {
    return this.bookingCourse.status;
  }

  get courseDetail() {
    return this.bookingCourse.courseDetail;
  }

  get studentDetailString() {
    const { studentGender, studentAgeRange } = this.bookingCourse;
    const gender = this.$t(`common.studentGenderShort.${studentGender}`);
    return `(${gender}, ${studentAgeRange})`;
  }

  get venueDetailString() {
    const { venueType, region } = this.bookingCourse;
    const venue = this.$t(`common.venueType.${venueType}`);
    const hyphen = this.$t("common.hyphen");
    const regionName = region ? region.name[this.$i18n.locale] : "";
    return `${venue} ${hyphen} ${regionName}`;
  }

  get confirmedTimeString() {
    const { confirmedDatetime } = this.bookingCourse;
    if (!confirmedDatetime) {
      return this.$t("record.course.details.unconfirmed");
    }
    const date = moment(confirmedDatetime)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL");
    const time = moment(confirmedDatetime).format("h:mma");
    return `${date} ${time}`;
  }

  get paymentDetailString() {
    const { amount, source } = this.payment;
    const bracketOpen = this.$t("common.bracket.open");
    const bracketClose = this.$t("common.bracket.close");
    let method = "";
    if (source.object === "card" && source.tokenization_method === null) {
      method = this.$t("common.paymentMethod.card").toString();
    } else if (
      source.object === "card" &&
      source.tokenization_method === "apple_pay"
    ) {
      method = this.$t("common.paymentMethod.applepay").toString();
    } else if (source.object === "source" && source.type === "alipay") {
      method = this.$t("common.paymentMethod.alipay").toString();
    }
    return `HK$ ${amount} ${bracketOpen}${method}${bracketClose}`;
  }

  get paymentDateString() {
    return moment(this.payment.createdAt)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL");
  }
}
