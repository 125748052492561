var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid-container form-fields"},[_c('div',{staticClass:"grid-x"},[_c('page-title',{staticClass:"cell small-12",on:{"back":_vm.onBack}},[_vm._v("\n        "+_vm._s(_vm.$t("bookTuning.step1.title"))+"\n      ")]),_c('radio-field',{staticClass:"cell small-12",attrs:{"selected":_vm.selectedInstrument,"label":_vm.$t('bookTuning.step1.instrument.label'),"options":_vm.instrumentOptions},on:{"update:selected":function($event){_vm.selectedInstrument=$event}}}),_c('radio-field',{staticClass:"cell small-12",attrs:{"selected":_vm.requireAccessaryReplacement,"label":_vm.$t('bookTuning.step1.requireAccessaryReplacement.label'),"options":[
          {
            value: false,
            label: _vm.$t("common.requireAccessaryReplacement.FALSE"),
          },
          {
            value: true,
            label: _vm.$t("common.requireAccessaryReplacement.TRUE"),
          } ]},on:{"update:selected":function($event){_vm.requireAccessaryReplacement=$event}}}),(_vm.requireAccessaryReplacement)?_c('text-field',{staticClass:"cell small-12",attrs:{"value":_vm.requireAccessaryReplacementRemark,"placeholder":_vm.$t('bookTuning.step1.requireAccessaryReplacementRemark.placeholder'),"error":_vm.requireAccessaryReplacementRemarkError},on:{"update:value":function($event){_vm.requireAccessaryReplacementRemark=$event}}}):_vm._e(),_c('radio-field',{staticClass:"cell small-12",attrs:{"selected":_vm.requireRelocation,"label":_vm.$t('bookTuning.step1.requireRelocation.label'),"options":[
          {
            value: false,
            label: _vm.$t("common.requireRelocation.FALSE"),
          },
          {
            value: true,
            label: _vm.$t("common.requireRelocation.TRUE"),
          } ]},on:{"update:selected":function($event){_vm.requireRelocation=$event}}}),_c('text-field',{staticClass:"cell small-12",attrs:{"value":_vm.contactName,"label":_vm.$t('bookTuning.step1.contactName.label'),"placeholder":_vm.$t('bookTuning.step1.contactName.placeholder'),"error":_vm.contactNameError},on:{"update:value":function($event){_vm.contactName=$event}}}),_c('text-field',{staticClass:"cell small-12",attrs:{"input-type":"tel","value":_vm.contactPhone,"label":_vm.$t('bookTuning.step1.contactPhone.label'),"placeholder":_vm.$t('bookTuning.step1.contactPhone.placeholder'),"error":_vm.contactPhoneError},on:{"update:value":function($event){_vm.contactPhone=$event}}})],1)]),_c('booking-selector',{staticClass:"cell small-12",attrs:{"options":_vm.tuningOptions,"selected":_vm.tuningId,"selectLabel":_vm.$t('bookTuning.step1.bookingSelector.selectLabel'),"bookNowLabel":_vm.$t('bookTuning.step1.bookingSelector.bookNowLabel')},on:{"click":_vm.onNext}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }