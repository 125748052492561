













import { Component, Prop, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import { CodesState, Skill } from "@/@types";

const Codes = namespace("codes");

@Component({
  components: {
    MinifiedSearchBar,
  },
})
export default class MinifiedSearchBar extends Vue {
  @Codes.Getter("findSkillByCode") public findSkillByCode!: (
    code: string
  ) => Skill | undefined;

  get skill() {
    return this.findSkillByCode(this.$route.params.skill);
  }
  get name() {
    return this.skill ? this.skill.name[this.$i18n.locale] : "";
  }
  get icon() {
    return this.skill ? this.skill.badgeImageUrl : "";
  }
}
