















import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  AsyncState,
  AsyncDataParam,
  Course,
  Instructor,
  Skill,
  BookingSelectorOption,
} from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import InstructorDetail from "@/components/InstructorDetailModal/InstructorDetail.vue";
import BookingSelector from "@/components/BookingSelector.vue";
import ErrorView from "@/components/ErrorView.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

const Codes = namespace("codes");
const Instructors = namespace("instructors");
@Component({
  components: {
    Layout,
    Top,
    InstructorDetail,
    BookingSelector,
    ErrorView,
  },
})
export default class InstructorDetailModal extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public static async asyncData({ store, route }: AsyncDataParam) {
    await Promise.all([
      store.dispatch("codes/fetchSkills"),
      store.dispatch("instructors/getInstructorDetail", {
        slug: route.params.slug,
      }),
    ]);
    const instructor = store.state.instructors.instructorDetail.data;
    const skill = store.getters["codes/findSkillByCode"](route.params.skill);
    if (instructor && skill) {
      await store.dispatch("instructors/listInstructorCoursesForSkill", {
        instructorId: instructor.id,
        skillId: skill.id,
      });
    }
  }

  @Codes.State("skills")
  public skillsState!: AsyncState<Skill>;
  @Codes.Getter("findSkillByCode")
  public findSkillByCode!: (code: string) => Skill | undefined;
  @Instructors.State("instructorDetail")
  public instructorState!: AsyncState<Instructor>;
  @Instructors.State("instructorCourseDetail")
  public instructorCourseState!: AsyncState<Course[]>;
  @Instructors.Getter("instructorHasSkill")
  public instructorHasSkill!: (code: string) => boolean;

  get title() {
    const { locale } = this.$i18n;
    const instructor = this.instructor
      ? this.instructor.displayName[locale]
      : "";
    const skillModel = this.findSkillByCode(this.$route.params.skill);
    const skill = skillModel ? skillModel.name[locale] : "";
    return `${instructor} - ${skill}`;
  }

  get ogDescription() {
    return this.instructor ? this.instructor.bio : "";
  }

  get ogImage() {
    if (this.instructor) {
      if (this.instructor.profileImageUrl) {
        return this.instructor.profileImageUrl;
      }
      switch (this.instructor.gender) {
        case "MALE":
          return require("@/assets/img/mm-profile-dummy-male@3x.png");
        case "FEMALE":
          return require("@/assets/img/mm-profile-dummy-female@3x.png");
      }
    }
    return "";
  }

  get instructor() {
    return this.instructorState.data;
  }
  get courses() {
    return this.instructorCourseState.data;
  }
  get isParamsValid() {
    if (
      AsyncStates.statusOf(this.skillsState) === AsyncStatus.LOADING ||
      AsyncStates.statusOf(this.instructorState) === AsyncStatus.LOADING ||
      AsyncStates.statusOf(this.instructorCourseState) === AsyncStatus.LOADING
    ) {
      return true;
    }
    if (!this.instructor || !this.courses) {
      return false;
    }
    if (!this.instructorHasSkill(this.$route.params.skill)) {
      return false;
    }
    return true;
  }

  get options() {
    const { locale } = this.$i18n;
    const { slug } = this.$route.params;
    return (this.courses || []).reduce(
      (arr, course) => {
        const { skill, level } = course;
        const c = course as any;
        const opts = [30, 45, 60]
          .map((duration) => ({
            value: `${slug}-${skill.code}-${level.code}-${duration}`,
            text: this.$t("instructorDetailModal.courseName", {
              level: level.name[locale],
              duration,
            }),
            url: `/tutor/${slug}/${skill.code}/${level.code}/${duration}/book`,
            namedPrice: c[`${duration}MinsNamedPrice`] as number | null,
            actualPrice: c[`${duration}MinsActualPrice`] as number | null,
          }))
          .filter(({ namedPrice, actualPrice }) => namedPrice && actualPrice);
        return arr.concat(opts as BookingSelectorOption[]);
      },
      [] as BookingSelectorOption[]
    );
  }
  get returnPath() {
    const { locale } = this.$i18n;
    const { skill } = this.$route.params;
    return `/${locale}/search/${skill}`;
  }
}
