





















































import { Component, Vue, Prop } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import {
  AsyncState,
  Area,
  PriceRange,
  Region,
  Skill,
  SkillLevel,
} from "@/@types";
import SelectField from "@/components/FormFields/SelectField.vue";
import AddressField from "@/components/FormFields/AddressField.vue";
import PopularSkill from "@/components/Landing/PopularSkill.vue";
import SkillItem from "@/components/Search/SkillItem.vue";
import RawLocation from "vue-router";

const VueScrollTo =
  // tslint:disable-next-line:no-var-requires
  typeof window === "undefined" ? {} : require("vue-scrollto");

const BREAKPOINT = 450;
const SCROLL_SPEED = 2000;
const Codes = namespace("codes");
@Component({
  components: {
    PopularSkill,
    SkillItem,
    SelectField,
    AddressField,
  },
})
export default class FilterModal extends Vue {
  @Codes.Getter("filterRegionsByArea") public filterRegionsByArea!: (
    area: Area
  ) => Region[];
  @Codes.Getter("findSkillByCode") public findSkillByCode!: (
    code: string
  ) => Skill | null;
  @Codes.Getter("findRegionByCode") public findRegionByCode!: (
    code: string
  ) => Region | null;
  @Codes.Getter("findPriceRangeByCode") public findPriceRangeByCode!: (
    code: string
  ) => PriceRange | null;
  @Codes.Getter("findSkillLevelByCode") public findSkillLevelByCode!: (
    skillCode: string,
    levelCode: string
  ) => SkillLevel | null;

  @Codes.State("skills") public skillsState!: AsyncState<Skill[]>;
  @Codes.State("regions") public regionsState!: AsyncState<Region[]>;

  get skills() {
    return this.skillsState.data || [];
  }
  get regions() {
    return this.regionsState.data || [];
  }

  @Codes.State("priceRanges") public priceRanges!: PriceRange[];
  get levels() {
    return this.selectedSkill ? this.selectedSkill.levels : [];
  }

  public selectedSkillCode = "";
  public selectedRegionCode = "";
  public selectedPriceRangeCode = "";
  public selectedLevelCode = "";

  get selectedSkill() {
    return this.findSkillByCode(this.selectedSkillCode);
  }

  get selectedRegion() {
    return this.findRegionByCode(this.selectedRegionCode);
  }

  get selectedPriceRange() {
    return this.findPriceRangeByCode(this.selectedPriceRangeCode);
  }

  get selectedLevel() {
    return this.findSkillLevelByCode(
      this.selectedSkillCode,
      this.selectedLevelCode
    );
  }

  get regionOptions() {
    return (this.regions || []).map((region) => ({
      value: region.code,
      label: region.name[this.$i18n.locale],
      group: region.area,
    }));
  }

  get regionOptionGroups() {
    return [
      { value: "HK", label: this.$t("common.area.hk") },
      { value: "KL", label: this.$t("common.area.kl") },
      { value: "NT", label: this.$t("common.area.nt") },
    ];
  }

  get priceRangeOptions() {
    return this.priceRanges.map((priceRange) => ({
      value: priceRange.code,
      label: this.$t(`search.filterModal.priceRange[${priceRange.code}]`),
    }));
  }

  get levelOptions() {
    return this.levels.map((level) => ({
      value: level.code,
      label: level.name[this.$i18n.locale],
    }));
  }

  public setMenuMaxHeight = () => {
    const el = document.getElementsByClassName(
      "filter__instcat"
    )[0] as HTMLElement;
    el.style.maxHeight = `${window.innerHeight - 435}px`;
  };

  public setAllowTouchMove = () => {
    const el = document.getElementsByClassName(
      "filter__options__container"
    )[0] as HTMLElement;
    if (window.innerHeight <= BREAKPOINT) {
      el.classList.add("allowtouchmove");
    } else {
      el.classList.remove("allowtouchmove");
    }
  };

  public scrollToSelectedSkill() {
    const el = this.$el.getElementsByClassName(
      "filter__inst__container"
    )[0] as HTMLElement;
    el.scrollTo(0, el.scrollHeight);
    VueScrollTo.scrollTo(
      `#filter__skill-${this.selectedSkillCode}`,
      SCROLL_SPEED,
      {
        container: `.filter__inst__container`,
        onStart: () => {
          if (window.innerHeight <= BREAKPOINT) {
            const formEl = this.$el.getElementsByClassName(
              "filter__options__container"
            )[0] as HTMLElement;
            formEl.scrollTo(0, el.scrollHeight);
          }
        },
        onDone: () => {
          if (window.innerHeight <= BREAKPOINT) {
            VueScrollTo.scrollTo(`.grid-x`, SCROLL_SPEED, {
              container: `.filter__options__container`,
            });
          }
        },
      }
    );
  }

  public mounted() {
    this.selectedSkillCode = this.$route.params.skill;
    this.selectedRegionCode = this.$route.query.region || "";
    this.selectedPriceRangeCode = this.$route.query["price-range"] || "";
    this.selectedLevelCode = this.$route.query.level || "";

    if (!window.onresize) {
      this.setMenuMaxHeight();
      this.setAllowTouchMove();
      window.onresize = () => {
        this.setMenuMaxHeight();
        this.setAllowTouchMove();
      };
      this.scrollToSelectedSkill();
    }
  }

  public destroyed() {
    window.onresize = null;
  }

  public setSelectedSkill(code: string) {
    this.selectedSkillCode = code;
    this.selectedLevelCode = "";
  }

  public reset() {
    this.selectedSkillCode = this.$route.params.skill;
    this.selectedRegionCode = "";
    this.selectedPriceRangeCode = "";
    this.selectedLevelCode = "";
  }

  public submit() {
    const params = { skill: this.selectedSkill!.code };
    const query: { [key: string]: string } = {};

    if (this.selectedPriceRangeCode.length > 0) {
      query["price-range"] = this.selectedPriceRangeCode;
    }
    if (this.selectedRegionCode.length > 0) {
      query.region = this.selectedRegionCode;
    }
    if (this.selectedLevelCode.length > 0) {
      query.level = this.selectedLevelCode;
    }

    this.$router.push({ params, query });
    this.dismiss();
  }
  public dismiss() {
    this.$emit("dismiss");
  }
}
