





import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import * as Sentry from "@sentry/browser";
@Component
export default class AliPayButton extends Vue {
  @Prop({ required: true }) public price!: number;
  @Prop({ required: true }) public returnPath!: string;
  public posting = false;
  public stripe = "";
  public baseUrl = "";

  get returnUrl() {
    const baseUrl = `${this.configs.VUE_APP_SKYGEAR_CLIENT_ENDPOINT}`.replace(
      /\/$/,
      ""
    );
    const basePath = (this.$router.options.base || "").replace(/\/$/, "");
    return `${baseUrl}${basePath}${this.returnPath}`;
  }
  public async onClick() {
    this.posting = true;
    const stripe = `${this.configs.VUE_APP_STRIPE_API_KEY}`;
    const stripeInstance = (window as any).Stripe(stripe);
    try {
      const { source } = await stripeInstance.createSource({
        type: "alipay",
        amount: this.price * 100,
        currency: "hkd",
        redirect: {
          return_url: this.returnUrl,
        },
      });
      this.$emit("saveSource", source);
    } catch (error) {
      Sentry.captureEvent(error);
      window.alert(this.$t("common.error.unknown"));
      this.posting = false;
    }
  }
}
