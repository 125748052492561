

























import moment, { Moment } from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/FormFields/Layout.vue";
import TimeslotDateInput from "@/components/FormFields/TimeslotDateInput.vue";
import TimeslotTimeInput from "@/components/FormFields/TimeslotTimeInput.vue";

@Component({
  components: {
    Layout,
    TimeslotDateInput,
    TimeslotTimeInput,
  },
})
export default class TimeslotField extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: 30 }) public duration!: number;
  @Prop({ required: true }) public date!: Moment | null;
  @Prop({ required: true }) public from!: Moment | null;
  @Prop({ required: true }) public to!: Moment | null;
  @Prop({ default: "" }) public error!: string;

  public timeFrom: Moment | null = null;
  public timeTo: Moment | null = null;

  public created() {
    const today = moment().startOf("day");
    this.timeFrom = moment(today)
      .add(8, "hours")
      .add(this.duration, "minutes");
    this.timeTo = moment(today)
      .add(21, "hours")
      .subtract(this.duration, "minutes");
  }

  public onFromTimeInputChange(event: Moment | null) {
    if (event) {
      const minToTime = moment(event).add(this.duration, "minutes");
      if (!this.to || this.to.isBefore(minToTime)) {
        this.$emit("update:to", moment(minToTime));
      }
    }
    this.$emit("update:from", event);
  }

  public onToTimeInputChange(event: Moment | null) {
    if (event) {
      const minFromTime = moment(event).subtract(this.duration, "minutes");
      if (!this.from || this.from.isAfter(minFromTime)) {
        this.$emit("update:from", moment(minFromTime));
      }
    }
    this.$emit("update:to", event);
  }
}
