





























import { Component, Prop, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import TabBarItem from "@/components/TabBarItem.vue";
import { AsyncState, Skill } from "@/@types";

const Codes = namespace("codes");
@Component({
  components: { TabBarItem },
})
export default class TabBar extends Vue {
  @Prop({ default: false }) public active!: "search" | "activities" | "tuning";
  @Prop({ default: true }) public show!: boolean;
  @Codes.State("skills") public skillsState!: AsyncState<Skill[]>;
  public async created() {
    await this.$store.dispatch("codes/fetchSkills");
  }
  get skills() {
    return this.skillsState.data;
  }
}
