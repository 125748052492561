























import { Vue, Component, Prop } from "vue-property-decorator";
import Star from "@/components/Record/Course/Star.vue";

@Component({
  components: {
    Star,
  },
})
export default class StarInput extends Vue {
  @Prop({ default: 0 }) public value!: number;
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: "#f5a623" }) public activeColor!: string;
  @Prop({ default: "#e7e7e7" }) public inactiveColor!: string;
  @Prop({ default: "gold" }) public hoverColor!: string;

  public hoverStarIndex = 0;

  public color(i: number) {
    if (this.hoverStarIndex && !this.readonly) {
      return i <= this.hoverStarIndex ? this.hoverColor : this.inactiveColor;
    }
    return i <= this.value ? this.activeColor : this.inactiveColor;
  }
}
