import Vue from "vue";
import { sync } from "vuex-router-sync";
import { Container } from "skygear";
import App from "./App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";
import { createI18n } from "./i18n";
import { RootState } from "./@types";

Vue.config.productionTip = false;

export const createApp = (skygear: Container, initialState?: RootState) => {
  const i18n = createI18n();
  const store = createStore(skygear);
  const router = createRouter(i18n, store);

  if (initialState) {
    store.replaceState(initialState);
  }
  sync(store, router);

  Vue.prototype.$skygear = skygear;
  const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  });
  return { app, router, store };
};
