






















import { Component, Prop, Vue } from "vue-property-decorator";

interface OptGroup {
  value: string;
  label: string;
}

interface Option {
  value: string;
  label: string;
  group: string;
}

@Component
export default class SelectGroupInput extends Vue {
  @Prop({ default: [] }) public optgroups!: OptGroup[];
  @Prop({ default: [] }) public options!: Option[];
  @Prop({ default: "" }) public selected!: string;
  @Prop({ default: "" }) public emptyOptionLabel!: string;
}
