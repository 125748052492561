











import { Component, Prop, Vue } from "vue-property-decorator";
import { CardExpiry } from "vue-stripe-elements-plus";
import Layout from "@/components/FormFields/Layout.vue";
import { StripeElementEvent } from "@/@types";

@Component({
  components: { Layout, CardExpiry },
})
export default class CardExpiryField extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: false }) public submitted!: boolean;
  @Prop({ required: true }) public value!: string;
  public stripe = `${this.configs.VUE_APP_STRIPE_API_KEY}`;
  public options = {
    style: {
      base: {
        fontSize: "14px",
        color: "#4a4a4a",
        "::placeholder": { color: "#9b9b9b" },
      },
      invalid: { color: "#4a4a4a" },
    },
  };
  public error = "";
  public created() {
    this.error = this.$t("paymentForm.expiryDate.errors.required").toString();
  }
  public onChange(event: StripeElementEvent) {
    if (event.empty) {
      this.error = this.$t("paymentForm.expiryDate.errors.required").toString();
    } else if (!event.complete || event.error) {
      this.error = this.$t("paymentForm.expiryDate.errors.format").toString();
    } else {
      this.error = "";
    }
    this.$emit("update:value", !!event.complete);
  }
}
