import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { Container } from "skygear";
import { createAuthModule } from "./auth";
import { createCodesModule } from "./codes";
import { createInstructorsModule } from "./instructors";
import { createBookCourseModule } from "./bookCourse";
import { createBookTuningModule } from "./bookTuning";
import { createBookingsModule } from "./bookings";
import { createActivityModule } from "./activity";
import { RootState } from "@/@types";

Vue.use(Vuex);

export const createStore = (skygear: Container) => {
  const store: StoreOptions<RootState> = {
    modules: {
      auth: createAuthModule(skygear),
      codes: createCodesModule(skygear),
      instructors: createInstructorsModule(skygear),
      bookCourse: createBookCourseModule(skygear),
      bookTuning: createBookTuningModule(skygear),
      bookings: createBookingsModule(skygear),
      activity: createActivityModule(skygear),
    },
    state: { error: null } as any,
  };
  return new Vuex.Store<RootState>(store);
};
