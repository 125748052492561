












































































import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import JoinUs from "@/components/Bottom/JoinUs.vue";

@Component({
  components: {
    I18nRouterLink,
    JoinUs,
  },
})
export default class Bottom extends Vue {
  @Prop({ default: false }) public showJoinUs!: boolean;
  public year = moment().format("YYYY");
}
