





































import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/Top/Layout.vue";
import CloseButton from "@/components/Top/CloseButton.vue";
import Login from "@/components/Top/Login.vue";
import Logo from "@/components/Top/Logo.vue";
import LanguageSwitcher from "@/components/Top/LanguageSwitcher.vue";

@Component({
  components: {
    Layout,
    Login,
    CloseButton,
    Logo,
    LanguageSwitcher,
  },
})
export default class Top extends Vue {
  @Prop({ default: "white" }) public theme!: "white" | "grey";
  @Prop({ default: "default" }) public mode!: "default" | "close" | "none";
  @Prop({ default: "" }) public returnPath!: string;
}
