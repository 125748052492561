































import { Component, Vue, Prop } from "vue-property-decorator";
import { Activity } from "@/@types";
import ActivityCard from "@/components/Activities/ActivityCard.vue";

@Component({
  components: {
    ActivityCard,
  },
})
export default class LatestActivities extends Vue {
  @Prop({ required: true })
  public activities!: Activity[];
}
