









import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Layout from "@/components/FormFields/Layout.vue";
import SelectGroupInput from "@/components/FormFields/SelectGroupInput.vue";
import { AsyncState, Region } from "@/@types";

const Codes = namespace("codes");

@Component({
  components: { Layout, SelectGroupInput },
})
export default class RegionInput extends Vue {
  @Prop({ default: "" }) public selected!: string;
  @Prop({ default: false }) public error!: boolean;
  @Prop({ required: true }) public options!: Region[];

  get _options() {
    const { locale } = this.$i18n;
    return (this.options || []).map((r) => ({
      value: r.code,
      label: r.name[locale],
      group: r.area,
    }));
  }
  get optionGroups() {
    return [
      { value: "HK", label: this.$t("common.area.hk") },
      { value: "KL", label: this.$t("common.area.kl") },
      { value: "NT", label: this.$t("common.area.nt") },
    ];
  }
}
