







import { Mixins, Component, Vue } from "vue-property-decorator";
import { StripeSourceTuningBookingPayload, StripeSource } from "@/@types";
import { parseStripeSource } from "@/store/parsers";
import * as Sentry from "@sentry/browser";
import LoadingIcon from "@/components/LoadingIcon.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

@Component({
  components: { LoadingIcon },
})
export default class AliPay extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public MAX_ATTEMPT = 5;
  public attempt = 0;

  get title() {
    return this.$t("bookTuning.alipay.title");
  }

  public created() {
    const { source, client_secret } = this.$route.query;
    if (source && client_secret) {
      this.handleAlipay();
    }
  }

  public async handleAlipay() {
    const { locale } = this.$i18n;
    const { slug, skill, level, duration } = this.$route.params;
    const { source, client_secret } = this.$route.query;
    try {
      const stripeSource = await this.pollForStripeSource(
        source,
        client_secret
      );
      if (stripeSource.status === "PAID") {
        this.$router.replace("success");
      } else if (
        stripeSource.status === "FAILED" ||
        stripeSource.status === "CANCELED"
      ) {
        this.copyStripeSourceToStore(stripeSource);
        this.$router.replace("3?error=1");
      } else {
        // PENDING or CHAREABLE
        this.$router.replace(`/${locale}/bookings`);
      }
    } catch (error) {
      Sentry.captureEvent(error);
      this.$router.replace(`/${locale}/bookings`);
    }
  }

  public async pollForStripeSource(sourceId: string, clientSecret: string) {
    const response = await this.$skygear.lambda("musicmap:get_stripe_source", {
      sourceId,
      clientSecret,
    });
    const stripeSource = parseStripeSource(response);
    this.attempt += 1;
    if (
      stripeSource.status === "PENDING" ||
      stripeSource.status === "CHARGEABLE"
    ) {
      if (this.attempt < this.MAX_ATTEMPT) {
        return await new Promise<StripeSource>((resolve, reject) => {
          window.setTimeout(
            () => resolve(this.pollForStripeSource(sourceId, clientSecret)),
            2000
          );
        });
      }
    }
    return stripeSource;
  }

  public copyStripeSourceToStore(stripeSource: StripeSource) {
    const {
      tuningId,
      requireAccessaryReplacement,
      requireAccessaryReplacementRemark,
      requireRelocation,
      contactName,
      contactPhone,
      region,
      address,
      timeslots,
      remark,
    } = (stripeSource.payload as StripeSourceTuningBookingPayload).data;
    this.$store.dispatch("bookTuning/setStepOne", {
      tuningId,
      requireAccessaryReplacement,
      requireAccessaryReplacementRemark,
      requireRelocation,
      contactName,
      contactPhone,
    });
    this.$store.dispatch("bookTuning/setStepTwo", {
      region,
      address,
      timeslots,
      remark,
    });
  }
}
