





import { Component, Vue, Prop } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import { AsyncState, Skill } from "@/@types";

const Codes = namespace("codes");
@Component({
  components: { I18nRouterLink },
})
export default class SearchRouterLink extends Vue {
  @Prop({ default: "" }) public skill!: string;
  @Codes.State("skills") public skillsState!: AsyncState<Skill[]>;
  public async created() {
    if (!this.skillsState.error) {
      await this.$store.dispatch("codes/fetchSkills");
    }
  }
  get computedSkill() {
    if (
      this.skill === "" &&
      this.skillsState.data &&
      this.skillsState.data.length > 0
    ) {
      return this.skillsState.data[0].code;
    }
    return this.skill;
  }
}
