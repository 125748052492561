











import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AsyncState, AsyncDataParam, Activity } from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import ActivityDetail from "@/components/ActivityDetailModal/ActivityDetail.vue";
import ErrorView from "@/components/ErrorView.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

const Activity = namespace("activity");

@Component({
  components: {
    Layout,
    Top,
    ActivityDetail,
    ErrorView,
    LoadingIcon,
  },
})
export default class ActivityDetailModal extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public static async asyncData({ store, route }: AsyncDataParam) {
    await store.dispatch("activity/getActivity", route.params.slug);
  }

  @Activity.State("activityDetail")
  public activityState!: AsyncState<Activity>;

  get title() {
    return this.activity ? this.activity.title : "";
  }

  get ogDescription() {
    return this.activity ? this.activity.content : "";
  }

  get ogImage() {
    return this.activity ? this.activity.coverImageUrl : "";
  }

  get activity() {
    return this.activityState.data;
  }

  get isLoading() {
    return AsyncStates.statusOf(this.activityState) === AsyncStatus.LOADING;
  }

  get isParamsValid() {
    return this.isLoading || !!this.activityState.data;
  }

  get returnPath() {
    const { locale } = this.$i18n;
    return `/${locale}/events`;
  }
}
