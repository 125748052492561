import moment from "moment";
import {
  Skill,
  SkygearModel,
  CodeModel,
  SkillLevel,
  Translatable,
  KeyValuePairs,
  Instructor,
  _Instructor,
  Region,
  _InstructorSkill,
  _Skill,
  Award,
  PerformanceExperience,
  TeachingExperience,
  Language,
  User,
  Course,
  CourseDetail,
  TuningInstrument,
  TuningPackage,
  Tuning,
  Booking,
  BookingCourse,
  BookingTuning,
  Payment,
  PageTuning,
  StripeSource,
  Activity,
  ActivityBanner,
  StripeSourceCourseBookingPayload,
  StripeSourceTuningBookingPayload,
} from "@/@types";

const parseTranslatable = (obj: KeyValuePairs): Translatable => {
  return { ...obj };
};

const parseSkygearModel = (obj: KeyValuePairs): SkygearModel => {
  const { id, createdAt, createdBy, updatedAt, updatedBy } = obj;
  return {
    id,
    createdAt: moment(createdAt),
    createdBy,
    updatedAt: moment(updatedAt),
    updatedBy,
  };
};

const parseCodeModel = (obj: KeyValuePairs): CodeModel => {
  const { code, name, isEnabled, seq } = obj;
  return {
    ...parseSkygearModel(obj),
    code,
    name: parseTranslatable(name),
    isEnabled,
    seq,
  };
};

export const parseTuningInstrument = (obj: KeyValuePairs): TuningInstrument => {
  const { iconImageUrl, remark } = obj;
  return {
    ...parseCodeModel(obj),
    iconImageUrl,
    remark: parseTranslatable(remark),
  };
};

export const parseTuningPackage = (obj: KeyValuePairs): TuningPackage => {
  return {
    ...parseCodeModel(obj),
  };
};

export const parseTuning = (obj: KeyValuePairs): Tuning => {
  const { tuningInstrument, tuningPackage, namedPrice, actualPrice } = obj;
  return {
    ...parseSkygearModel(obj),
    tuningInstrument: parseTuningInstrument(tuningInstrument),
    tuningPackage: parseTuningPackage(tuningPackage),
    namedPrice,
    actualPrice,
  };
};

export const parseBookingCourse = (obj: KeyValuePairs): BookingCourse => {
  const {
    course,
    userIs,
    studentName,
    studentGender,
    studentAgeRange,
    contactPhone,
    remark,
    venueType,
    region,
    address,
    status,
    rating,
    comment,
    confirmedDatetime,
  } = obj;
  return {
    ...parseSkygearModel(obj),
    courseDetail: parseCourseDetail(course),
    userIs,
    studentName,
    studentGender,
    studentAgeRange,
    contactPhone,
    remark,
    venueType,
    region: region ? parseRegion(region) : null,
    address: address || null,
    status,
    rating,
    comment,
    confirmedDatetime,
  };
};

export const parseBookingTuning = (obj: KeyValuePairs): BookingTuning => {
  const {
    confirmedDatetime,
    contactName,
    contactPhone,
    region,
    requireAccessaryReplacement,
    requireAccessaryReplacementRemark,
    requireRelocation,
    status,
    tuning,
  } = obj;
  return {
    ...parseSkygearModel(obj),
    confirmedDatetime,
    contactName,
    contactPhone,
    region: parseRegion(region),
    requireAccessaryReplacement,
    requireAccessaryReplacementRemark,
    requireRelocation,
    status,
    tuning: parseTuning(tuning),
  };
};

const parsePayment = (obj: KeyValuePairs): Payment => {
  const { amount, stripeChargeId, source } = obj;
  return {
    ...parseSkygearModel(obj),
    amount,
    stripeChargeId,
    source,
  };
};

export const parseBooking = (obj: KeyValuePairs): Booking => {
  const { type, course, tuning, payment } = obj;
  return {
    ...parseSkygearModel(obj),
    type,
    bookingCourse: type === "COURSE" ? parseBookingCourse(course) : null,
    bookingTuning: type === "TUNING" ? parseBookingTuning(tuning) : null,
    payment: parsePayment(payment),
  };
};

export const parseSkillLevel = (obj: KeyValuePairs): SkillLevel => {
  return {
    ...parseCodeModel(obj),
    seq2: obj.seq2,
  };
};

export const parseSkill = (obj: KeyValuePairs): Skill => {
  const { badgeImageUrl, iconImageUrl, levels } = obj;
  return {
    ...parseCodeModel(obj),
    badgeImageUrl,
    iconImageUrl,
    levels: levels.map(parseSkillLevel),
  };
};

export const parseRegion = (obj: KeyValuePairs): Region => {
  const { area } = obj;
  return {
    ...parseCodeModel(obj),
    area,
  };
};

export const parseLanguage = (obj: KeyValuePairs): Language => {
  return {
    ...parseCodeModel(obj),
  };
};

const parseAward = (obj: KeyValuePairs): Award => {
  const { instructorId, date, description } = obj;
  return {
    ...parseSkygearModel(obj),
    instructorId,
    date,
    description,
  };
};

const parsePerformanceExperience = (
  obj: KeyValuePairs
): PerformanceExperience => {
  const { instructorId, date, description } = obj;
  return {
    ...parseSkygearModel(obj),
    instructorId,
    date,
    description,
  };
};

const parseTeachingExperience = (obj: KeyValuePairs): TeachingExperience => {
  const { instructorId, date, description } = obj;
  return {
    ...parseSkygearModel(obj),
    instructorId,
    date,
    description,
  };
};

const _parseSkill = (obj: KeyValuePairs): _Skill => {
  const { badgeImageUrl, iconImageUrl } = obj;
  return {
    ...parseCodeModel(obj),
    badgeImageUrl,
    iconImageUrl,
  };
};

const _parseInstructorSkill = (obj: KeyValuePairs): _InstructorSkill => {
  const {
    instructorId,
    skillId,
    isFeatured,
    offerCourseMinPrice,
    offerCourseMaxPrice,
    skill,
  } = obj;
  return {
    ...parseSkygearModel(obj),
    instructorId,
    skillId,
    isFeatured,
    offerCourseMaxPrice,
    offerCourseMinPrice,
    skill: _parseSkill(skill),
  };
};

const _parseInstructor = (obj: KeyValuePairs): _Instructor => {
  const {
    realName,
    displayName,
    slug,
    gender,
    profileImageUrl,
    tagline,
    bio,
    education,
    expTeachingYears,
    isEnabled,
    rating,
  } = obj;
  return {
    ...parseSkygearModel(obj),
    realName,
    displayName: parseTranslatable(displayName),
    slug,
    gender,
    profileImageUrl,
    tagline,
    bio,
    education,
    expTeachingYears,
    isEnabled,
    rating,
  };
};

export const parseInstructor = (obj: KeyValuePairs): Instructor => {
  const {
    awards,
    performanceExperiences,
    teachingExperiences,
    languages,
    regions,
    skills,
  } = obj;
  return {
    ..._parseInstructor(obj),
    awards: awards.map(parseAward),
    performanceExperiences: performanceExperiences.map(
      parsePerformanceExperience
    ),
    teachingExperiences: teachingExperiences.map(parseTeachingExperience),
    languages: languages.map(parseLanguage),
    regions: regions.map(parseRegion),
    skills: skills.map(_parseInstructorSkill),
  };
};

export const parseSkygearUser = (obj: KeyValuePairs): User => {
  return {
    id: obj._id,
    lastLoginAt: obj.last_login_at,
    displayName: obj.display_name,
    email: obj.email,
    phoneNumber: obj.phone_number,
    defaultStudentName: obj.default_student_name,
    ssoProviders: {
      google: obj.sso_google,
      facebook: obj.sso_facebook,
    },
  };
};

export const parseUser = (obj: KeyValuePairs): User => {
  const {
    defaultStudentName,
    displayName,
    email,
    lastLoginAt,
    phoneNumber,
    ssoFacebook,
    ssoGoogle,
  } = obj;
  return {
    ...parseSkygearModel(obj),
    defaultStudentName,
    displayName,
    email,
    lastLoginAt,
    phoneNumber,
    ssoProviders: {
      facebook: ssoFacebook,
      google: ssoGoogle,
    },
  };
};

export const parseCourse = (obj: KeyValuePairs): Course => {
  const { skill, level } = obj;
  return {
    ...parseSkygearModel(obj),
    "30MinsNamedPrice": obj["30MinsNamedPrice"],
    "45MinsNamedPrice": obj["45MinsNamedPrice"],
    "60MinsNamedPrice": obj["60MinsNamedPrice"],
    "30MinsActualPrice": obj["30MinsActualPrice"],
    "45MinsActualPrice": obj["45MinsActualPrice"],
    "60MinsActualPrice": obj["60MinsActualPrice"],
    skill: _parseSkill(skill),
    level: parseSkillLevel(level),
  };
};

const parseCourseDetail = (obj: KeyValuePairs): CourseDetail => {
  const { instructor } = obj;
  return {
    ...parseCourse(obj),
    instructor: _parseInstructor(instructor),
  };
};

export const parsePageTuning = (obj: KeyValuePairs): PageTuning => {
  const { bannerImageUrl, title, description, remark } = obj;
  return {
    ...parseSkygearModel(obj),
    bannerImageUrl,
    title: parseTranslatable(title),
    description: parseTranslatable(description),
    remark: parseTranslatable(remark),
  };
};

const parseStripeSourceCourseBookingPayload = (
  obj: KeyValuePairs
): StripeSourceCourseBookingPayload => {
  const { courseId, duration, price, data } = obj;
  const {
    userIs,
    studentName,
    studentGender,
    studentAgeRange,
    contactPhone,
    remark,
    venueType,
    region,
    address,
    timeslots,
  } = data;
  return {
    courseId,
    duration,
    price,
    data: {
      userIs,
      studentName,
      studentGender,
      studentAgeRange,
      contactPhone,
      remark,
      venueType,
      region: region ? parseRegion(region) : null,
      address,
      timeslots: timeslots.map((timeslot: any) => ({
        date: timeslot.date ? moment(timeslot.date).local() : null,
        fromTime: timeslot.fromTime ? moment(timeslot.fromTime).local() : null,
        toTime: timeslot.toTime ? moment(timeslot.toTime).local() : null,
      })),
    },
  };
};

const parseStripeSourceTuningBookingPayload = (
  obj: KeyValuePairs
): StripeSourceTuningBookingPayload => {
  const { tuningId, price, data } = obj;
  const {
    requireAccessaryReplacement,
    requireAccessaryReplacementRemark,
    requireRelocation,
    contactName,
    contactPhone,
    region,
    address,
    timeslots,
    remark,
  } = data;
  return {
    tuningId,
    price,
    data: {
      tuningId,
      requireAccessaryReplacement,
      requireAccessaryReplacementRemark,
      requireRelocation,
      contactName,
      contactPhone,
      region: region ? parseRegion(region) : null,
      address,
      timeslots: timeslots.map((timeslot: any) => ({
        date: timeslot.date ? moment(timeslot.date).local() : null,
        fromTime: timeslot.fromTime ? moment(timeslot.fromTime).local() : null,
        toTime: timeslot.toTime ? moment(timeslot.toTime).local() : null,
      })),
      remark,
    },
  };
};

export const parseStripeSource = (obj: KeyValuePairs): StripeSource => {
  const { type, source, payload, status } = obj;
  switch (type) {
    case "COURSE":
      return {
        ...parseSkygearModel(obj),
        type,
        source,
        payload: parseStripeSourceCourseBookingPayload(payload),
        status,
      };
    case "TUNING":
      return {
        ...parseSkygearModel(obj),
        type,
        source,
        payload: parseStripeSourceTuningBookingPayload(payload),
        status,
      };
    default:
      throw new Error("Invalid stripe source type");
  }
};

const parseActivityBanner = (obj: KeyValuePairs): ActivityBanner => {
  return { imageUrl: obj.imageUrl };
};

export const parseActivity = (obj: KeyValuePairs): Activity => {
  return {
    ...parseSkygearModel(obj),
    type: obj.type,
    title: obj.title,
    slug: obj.slug,
    isPublished: obj.isPublished,
    content: obj.content,
    performanceUnit: obj.performanceUnit,
    startDate: moment(obj.startDate).toISOString(),
    endDate: obj.endDate ? moment(obj.endDate).toISOString() : null,
    displayDate: obj.displayDate,
    displayTime: obj.displayTime,
    address: obj.address,
    fee: obj.fee,
    link: obj.link,
    coverImageUrl: obj.coverImageUrl,
    banners: obj.banners.map(parseActivityBanner),
  };
};
