export default {
  common: {
    comma: "、",
    slash: "／",
    hyphen: "－",
    bracket: {
      open: "（",
      close: "）",
    },
    area: {
      hk: "港島區",
      kl: "九龍區",
      nt: "新界區",
    },
    month: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },
    userIs: {
      STUDENT: "學生本人",
      PARENT: "家長",
    },
    studentGender: {
      MALE: "男生",
      FEMALE: "女生",
    },
    studentGenderShort: {
      MALE: "男",
      FEMALE: "女",
    },
    venueType: {
      STUDENT: "學生家中",
      INSTRUCTOR: "導師工作室／家中／鄰近琴行＊",
    },
    requireAccessaryReplacement: {
      TRUE: "需要（另外收費）",
      FALSE: "不需要",
    },
    requireRelocation: {
      TRUE: "需要（另外收費）",
      FALSE: "不需要",
    },
    paymentMethod: {
      card: "信用卡",
      applepay: "蘋果支付",
      alipay: "支付寶",
    },
    og: {
      description:
        "以音樂譜出地圖，伴隨一生的旅程，尋找音樂生命中的快樂。當你聆聽，可耳聞喜樂。當你演奏，可觸摸音樂。尋樂MusicMap就是為了與你樂在此樂，讓音樂伴隨你的左右。",
    },
    error: {
      unknown: "對不起，出了些故障。請稍後再嘗試。",
      userDisabled: "帳號被封鎖，請聯絡管理員。",
      transactionDeclined: "交易被拒，請使用另一張信用卡或其他付款方式。",
    },
  },
  formFields: {
    selectField: {
      emptyOption: {
        label: "請選擇",
      },
    },
    timeslotDateInput: {
      placeholder: "選擇日期",
    },
    timeslotTimeInput: {
      emptyOption: {
        label: "選擇時間",
      },
    },
    nextButton: {
      label: "下一步",
    },
  },
  landing: {
    title: "尋找香港音樂導師、鋼琴調音、音樂活動",
    banner: {
      tagline1: "尋找香港音樂導師、鋼琴調音、音樂活動",
    },
    popularSkills: {
      title: "熱門樂器",
      moreSkill: "更多",
    },
    featuredInstructors: {
      title: "推薦音樂導師",
    },
    latestActivities: {
      title: "最新音樂活動/音樂比賽",
    },
  },
  login: {
    loginForm: {
      title: "開始使用Musicmap",
      description: "請選擇以下其中一個方法連接您的賬號",
      button: {
        fb: "以Facebook登入",
        gl: "以Google登入",
      },
    },
    successForm: {
      title: "註冊成功",
      description: "您現在可以預約我們的專業音樂導師和調音服務。",
      button: {
        search: "立即搜尋",
      },
    },
  },
  search: {
    title: "學{skill}香港 ｜學{skill}班｜成人兒童幼兒學{skill}｜{skill}課程",
    minifiedSearchBar: {
      text: "{skill} - 尋找導師",
    },
    noResult: {
      instructors: "暫時未有導師",
      activities: "暫時沒有活動",
    },
    filterModal: {
      all: "全部",
      region: "上課地區",
      price: "價錢",
      priceRange: {
        "0": "150-250",
        "1": "251-400",
        "2": "400以上",
      },
      level: "級別",
      reset: "重設所有篩選項目",
      search: "搜尋",
    },
  },
  activities: {
    title: "活動／比賽 - {type}",
    tabbar: {
      all: "所有活動",
      concert: "音樂會",
      workshop: "工作坊",
      contest: "比賽",
    },
    monthSelector: {
      emptyOptionLabel: "請選擇月份",
      optionLabel: "{month}份活動",
    },
  },
  instructorCard: {
    ratingScore: {
      title: "評分",
    },
  },
  instructorDetailModal: {
    bio: "自我介紹／理念",
    education: "學歷",
    awards: "獎項與成就",
    performanceExperiences: "演出經驗",
    teachingExperiences: "教學經驗",
    languages: "上課語言",
    regions: "上課地區",
    selectLevel: "選擇級別",
    bookCourse: "預約上課",
    courseName: "{level}（{duration}分鐘）",
  },
  header: {
    auth: {
      login: "登入",
      register: "註冊",
    },
  },
  tabbar: {
    search: "尋找導師",
    activities: "活動／比賽",
    tuning: "預約調音",
  },
  footer: {
    copyright: "© 尋樂有限公司 {year}",
    aboutUs: "關於我們",
    howWeWork: "如何運作",
    contactUs: "聯繫我們",
    terms: "服務條款及私隱聲明",
  },
  bookCourse: {
    step1: {
      title: "1 學生資料",
      userIs: {
        label: "你是...",
      },
      studentName: {
        label: "學生姓名",
        placeholder: "請輸入學生姓名",
        errors: {
          required: "請輸入學生姓名",
        },
      },
      studentGender: {
        label: "學生性別",
      },
      studentAgeRange: {
        label: "學生年齡",
        errors: {
          required: "請選擇學生年齡",
        },
      },
      level: {
        label: "學習級別",
        value: "{name}（{duration}分鐘）",
      },
      remark: {
        label: "備註（如有）",
        placeholder: "例如：學生特殊需要、學習偏好等",
      },
      contactPhone: {
        label: "聯絡電話",
        placeholder: "請輸入聯絡電話",
        errors: {
          required: "請輸入聯絡電話",
          format: "請輸入香港電話號碼",
        },
      },
    },
    step2: {
      title: "2 上課地區及時間",
      venueType: {
        label: "上課地區",
        errors: {
          required: "請選擇上課地區",
        },
      },
      studentAddress: {
        label: "學生地址",
        placeholder: "請輸入學生地址",
        errors: {
          regionRequired: "請選擇學生地區",
          addressRequired: "請輸入學生地址",
        },
      },
      instructorAddress: {
        label: "導師地區",
        errors: {
          required: "請輸入導師地區",
        },
        remark1: "我們將會在成功預約後提供詳細地址。",
        remark2: "＊租用鄰近琴行需另外收費（每小時約$40-$120元）",
      },
      timeslot: {
        intro: "請提供三個您方便試堂的時段，導師將會盡量配合您的意願安排試堂：",
        label: "時段",
        errors: {
          required: "請提供試堂時段",
          duplicated: "試堂時段重覆",
        },
      },
    },
    step3: {
      title: "3 確認及付款",
      instructor: {
        label: "導師",
        value: "{name}（{skill}）",
      },
      student: {
        label: "學生",
        value: "{name}（{gender}，{ageRange}）",
      },
      level: {
        label: "學習級別",
        value: "{name}（{duration}分鐘）",
      },
      contactPhone: {
        label: "聯絡電話",
      },
      venueType: {
        label: "上課地區",
      },
      studentAddress: {
        label: "學生住址",
      },
      instructorAddress: {
        label: "導師地區",
      },
      timeslot: {
        label: "選擇時間",
      },
      price: {
        label: "首堂預繳費用",
      },
      remark1: "我們將在您預繳首堂費用後，為您聯絡導師安排上課。",
      remark2:
        "如因導師未能安排試堂，學費不會退回，可另選其他導師（如另作安排的導師學費超過原本所選導師學費，需補回差額）",
      paymentMethods: "請選擇付款方式：",
    },
    alipay: {
      title: "處理中...",
    },
    success: {
      title: "預約成功",
      description:
        "我們已收到閣下的資料，<br />與導師接洽後將盡快與你確認預約。",
      button: {
        label: "查看我的預約記錄",
        whatsapp: "聯絡Music Map",
      },
    },
  },
  bookTuning: {
    intro: {
      charge: "收費",
      button: "預約調音",
    },
    step1: {
      title: "1 基本資料",
      instrument: {
        label: "調音樂器",
      },
      requireAccessaryReplacement: {
        label: "是否需要更換配件",
      },
      requireAccessaryReplacementRemark: {
        placeholder: "請註明所需配件",
        errors: {
          required: "請註明所需配件",
        },
      },
      requireRelocation: {
        label: "是否需要搬琴",
      },
      contactName: {
        label: "聯絡人",
        placeholder: "請輸入聯絡人姓名",
        errors: {
          required: "請輸入聯絡人姓名",
        },
      },
      contactPhone: {
        label: "聯絡電話",
        placeholder: "請輸入聯絡電話",
        errors: {
          required: "請輸入聯絡電話",
          format: "請輸入香港電話號碼",
        },
      },
      bookingSelector: {
        selectLabel: "選擇套票",
        bookNowLabel: "預約調音",
      },
    },
    step2: {
      title: "2 地址及時間",
      address: {
        label: "地址",
        placeholder: "請輸入地址",
        errors: {
          regionRequired: "請輸入地區",
          addressRequired: "請輸入地址",
        },
      },
      timeslot: {
        intro:
          "請提供三個最少三天後您想進行調音的時段（約為1小時），我們將按照您所選的時段提供調音師。",
        label: "時段",
        errors: {
          required: "請提供調音時段",
          duplicated: "調音時段重覆",
        },
      },
      remark: {
        label: "備註（如有）",
        placeholder: "例如：樂器特殊需要等",
      },
    },
    step3: {
      title: "3 確認及付款",
      instrument: {
        label: "調音樂器",
      },
      requireAccessaryReplacement: {
        yesLabel: "需要更換配件",
        noLabel: "不需要更換配件",
      },
      requireRelocation: {
        yesLabel: "需要搬琴",
        noLabel: "不需要搬琴",
      },
      contactName: {
        label: "聯絡人",
      },
      contactPhone: {
        label: "聯絡電話",
      },
      address: {
        label: "地址",
      },
      timeslot: {
        label: "選擇時間",
      },
      price: {
        label: "預約費用",
      },
      paymentMethods: "請選擇付款方式：",
    },
    alipay: {
      title: "處理中...",
    },
    success: {
      title: "預約成功",
      description:
        "我們已收到閣下的資料，<br />將為你安排適合的調音師後與你確認預約。",
      button: {
        label: "查看我的預約記錄",
        whatsapp: "聯絡Music Map",
      },
    },
  },
  sidemenu: {
    search: "尋找導師",
    events: "活動／比賽",
    tuning: "預約調音",
    bookings: "我的預約記錄",
    account: "更改帳戶資料",
    aboutUs: "關於MusicMap",
    contactUs: "聯繫我們",
    howWeWork: "如何運作",
    logout: "登出",
  },
  applePay: {
    total: {
      label: "費用",
    },
  },
  alipay: {
    error: {
      CANCELED: "付款已取消。",
      FAILED: "付款失敗，請稍後再嘗試。",
    },
  },
  paymentForm: {
    title: "信用卡支付",
    cardHolderName: {
      label: "持卡人姓名",
      placeholder: "請輸入持卡人姓名",
      errors: {
        required: "請輸入持卡人姓名",
      },
    },
    cardNumber: {
      label: "卡號",
      placeholder: "請輸入卡號",
      errors: {
        required: "請輸入卡號",
        format: "卡號不正確",
      },
    },
    expiryDate: {
      label: "到期日期",
      errors: {
        required: "請輸入",
        format: "不正確",
      },
    },
    cvc: {
      label: "CVC",
      errors: {
        required: "請輸入",
        format: "不正確",
      },
    },
    email: {
      label: "電郵地址",
      placeholder: "請輸入電郵地址",
      hint: "收據將發送至電郵地址",
      errors: {
        required: "請輸入電郵地址",
        format: "電郵地址不正確",
      },
    },
    submitButton: {
      label: "確認及付款",
    },
  },
  errorView: {
    message: "找不到頁面或系統錯誤",
  },
  record: {
    empty: "你未有任何預約",
    button: {
      label: "立即搜尋",
    },
    notice:
      "預約一旦確認後，將不能取消試堂<br />如需更改時間，請於試堂日期三天前向導師更改",
    status: {
      PENDING: "處理中",
      CONFIRMED: "已確認",
      CANCELLED: "已取消",
      PUNCHEDIN: "已簽到",
    },
    course: {
      details: {
        instructor: "導師",
        student: "學生",
        level: "學習級別",
        venue: "上課地區",
        time: "上課時間",
        payment: "已付款",
        unconfirmed: "待導師確認",
      },
      checkin: "簽到",
      rating: {
        title: "評分",
        comment: {
          title: "評語",
          placeholder: "評語 (選填)",
        },
        submit: "送出",
      },
    },
    tuning: {
      title: "調音",
      details: {
        requireAccessaryReplacement: {
          yesLabel: "需要更換配件",
          noLabel: "不需要更換配件",
        },
        requireRelocation: {
          yesLabel: "需要搬琴",
          noLabel: "不需要搬琴",
        },
        contactName: "聯絡人",
        contactPhone: "聯絡電話",
        address: "地址",
        timeslot: "預約時段",
        unconfirmed: "待確認",
        date: "預約日期",
        time: "預約時間",
        payment: "已付款",
      },
    },
  },
  account: {
    name: {
      label: "姓名",
      placeholder: "請輸入姓名",
      errors: {
        required: "請輸入姓名",
      },
    },
    phone: {
      label: "電話",
      placeholder: "請輸入電話",
      errors: {
        format: "請輸入香港電話號碼",
      },
    },
    email: {
      label: "電郵地址",
    },
    link: {
      title: "帳號連結",
      linked: "已連結",
      nolink: "未連結",
    },
    submit: "儲存變更",
    success: {
      description: "帳戶資料已更新",
      okay: "確定",
    },
  },
  activityDetail: {
    performanceUnit: "表演單位",
    date: "日期",
    time: "時間",
    address: "地點",
    fee: "費用",
    link: "瀏覽詳情",
  },
  aboutUs: {
    title: "關於我們",
    content: `
      <p>以音樂譜出地圖，伴隨一生的旅程，尋找音樂生命中的快樂。</p>
      <p>當你聆聽，可耳聞喜樂。</p>
      <p>當你演奏，可觸摸音樂。</p>
      <p>尋樂MusicMap就是為了與你樂在此樂，讓音樂伴隨你的左右。</p>
      <h3>使命</h3>
      <p>整合不同音樂機構和專業人士，提供各方面的音樂活動資訊和服務，培養和加強音樂的修養和素質。</p>
      <h3>為家長/學生</h3>
      <p>提供專業和多元化的音樂服務，音樂活動資訊，培養對音樂的興趣。</p>
      <h3>導師</h3>
      <p>能將知識和技術傳授給予學生，透過音樂來幫助學生陶冶性情，和良好的品格。</p>
      <h3>與音樂機構</h3>
      <p>音樂會、工作坊、活動班、比賽；匯聚不同的機構共建在「地圖」上，在不同的地區推動和發展音樂。</p>
      <h3>願景</h3>
      <p>在香港這美麗的土地上，傳承音樂的知識，技巧和分享；在音樂的路上，學習到音樂和快樂，以音樂伴隨人生的每一段旅程。</p>
    `,
  },
  howWeWork: {
    title: "如何使用／Q&A",
    content: `
      <ol>
        <li>登記成為會員，隨時搜尋鄰近地區的音樂導師，音樂活動和預約調音服務</li>
        <li>選擇所想樂器，音樂活動，所有資料均一目了然</li>
        <li>提供三個預約上課時間，並繳交一堂的費用</li>
        <li>老師時間確認後，預約完成</li>
      </ol>
      <h3>MusicMap 是什麼？</h3>
      <p>MusicMap 網站讓您搜尋香港各式各樣的音樂活動，按照您所選的地區，您能預約各種不同樂器導師、鋼琴調音和音樂活動。</p>
      <h3>MusicMap 的導師資歷</h3>
      <p>每一名導師均經過資歷認證，才能成為 MusicMap 的導師。當中的導師不乏已考獲演奏級，或是現就讀於／畢業於香港或海外的音樂系。</p>
      <h3>試堂需要收費嗎？</h3>
      <p>我們將預約的第一堂稱為試堂，需要於預約時繳付一堂費用。試堂收費的本意之一是認為導師需要付出時間準備教材和心力教授。二是希望學生和導師雙方試一堂後，了解雙方的教學方針或教法會否合適，希望您能於 MusicMap 中找到最合適的老師。</p>
      <h3>預約試堂後更改課堂時間安排</h3>
      <p>如預約試堂後需更改試堂時間，須於試堂日子不少於三天前向導師提出更改，但不接受即日請假及單方面取消，若因天氣（八號風球、十號風球、黑色暴雨）和能夠出示試堂日子的醫生紙證明，則可獲豁免，並須於 14 天內向導師另約試堂日期和時間。若付款後兩天內未獲本公司的任何通知，您可致電／Whatsapp +85256062052 或發送電郵至<a href="mailto:info@musicmap.hk">info@musicmap.hk</a>查詢。</p>
      <h3>如想預約導師，有哪些付款方式及有收據嗎？</h3>
      <p>付款方式可透過 Apple Pay/Google Pay、支付寶、VISA 和 MasterCard 支付。不論預約了導師或鋼琴調音服務，在確認及付款的頁面上，必需填寫 Email，在付款後，收據將發送至您所填寫的 Email。</p>
      <h3>付款過程安全嗎？</h3>
      <p>MusicMap 的付款過程由具 PCI Compliance 認證的一級服務供應商 Stripe 所代理，並以嚴格的行業標準來執行付款防護措施。顧客直接在「確認及付款」的頁面輸入信用卡資料結帳後，通過 Stripe 的系統，所有敏感的信用卡資料在最安全的方式下處理，過程簡便快捷。欲了解更多 Stripe 的安全準則，可瀏覽：<a href="https://stripe.com/docs/security">https://stripe.com/docs/security</a></p>
      <h3>未能預約試堂的情況安排</h3>
      <p>如因導師未能安排試堂，學費不會退回，可另選其他導師（如另作安排的導師學費超過原本所選導師學費，需補回差額）</p>
      <h3>簽到及評價系統</h3>
      <p>簽到系統於試堂當天便可使用，可作當天上課憑證，簽到後可給予評價，以供其他使用者作為參考。</p>
    `,
  },
  contactUs: {
    title: "聯繫我們",
    content: `
      <p></p>
      <p>聯絡電話／WhatsApp：+85256062052</p>
      <p>地址：漆咸道南87-105號百利商業中心地下G15號鋪</p>
      <p>一般資料查詢：<a href="mailto:info@musicmap.hk">info@musicmap.hk</a></p>
      <p>活動／合作查詢：<a href="mailto:marketing@musicmap.hk">marketing@musicmap.hk</a></p>
      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSf7BycSJexQDkYEynimeRLuXVYLbQta8snpgP8gDJ1cZhusLQ/viewform">刊登活動表格</a></p>
      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSdwNjsiqfYZiAvosJ1xAIOxUJIQKqfvpJeLdR3GlVpeWK_dmA/viewform?fbclid=IwAR2u9qgdiRLjQALOKkoyfPZkAJucRmO1r2XFRGRrpRtE-CGUVkB5aJsrIQw">加入我們（成為導師）</a></p>
      <p>本網站之節目資訊來源包括由主辦機構／藝術家提供、節目宣傳單張、社交網絡平台等</p>
      <p>如不欲有關節目資訊在本網站顯示，請電郵 <a href="mailto:info@musicmap.hk">info@musicmap.hk</a>告知</p>
    `,
  },
  termsAndConditions: {
    title: "服務條款及私隱聲明",
    serviceTerms: {
      title: "服務條款",
      content: `
        <p>生效日期: 2018年 12月 21日</p>
        <p>尋樂有限公司（以下簡稱 尋樂）是依據本服務條款提供本網站各項服務。當您註冊完成或開始使⽤本服務時，即表示您已閱讀、了解並同意接受本服務條款之所有內容。如果您不同意本服務條款的內容，或者您所屬的國家或地域排除本服務條款內容之全部或部分時，您應立即停止使用本服務。本網站有權於任何時間修改或變更本服務條款之內容，並公告於本網站上，請您隨時注意該等修改或變更。若您於任何修改或變更後繼續使⽤本服務， 則視為您已閱讀、了解並同意接受該等修改或變更。</p>
        <p>若您為未滿⼗八歲之未成年⼈，則應請您的⽗母或監護人閱讀、了解並同意本服務條款之所有內容及其後之修改變更，方得使⽤本服務。當您使用本服務時，即推定您的⽗⺟或監護⼈已閱讀、了解並同意接受本服務條款之所有內容及其後之修改變更。</p>
        <p>完成登記後即代表完全明白有關內容，並會遵守所有條款和規則之約束。</p>
        <ol>
          <li>會員個⼈資料及帳戶管理
            <ol>
              <li>在您使用網上會員服務之前，請先註冊成為尋樂網上會員，無須支付任何會員費用；在完成註冊後，可使用網上會員服務及本網站提供相關之活動。如所提供的資料有變動，請即時更新，以保障您的權益。</li>
              <li>請您妥善保管您的電郵地址、 帳戶密碼及個⼈資料，不應將任何該等資料提供給任何⼈使⽤，以保障您的資料安全。如您發現您的資料遭他人非法使⽤，應立即通知本公司。如您的資料遭黑客取得或因個⼈的保管疏忽導致您的資料遭他⼈非法使用，本公司概不承擔任何責任。</li>
            </ol>
          </li>
          <li>特別授權事項
            <ol>
              <li>因使⽤本網站的服務所提供之網路交易或活動，須透過導師、服務提供者及相關之機構等來聯絡用戶，因此，會員同意並授權 尋樂 來得視該次網路交易或活動之需求及⽬的，將由會員所提供且為配送所必要之個人資料（包括但不限於⽤⼾姓名、上課地址、聯絡電話等），提供予導師、服務提供者及相關之機構，以利完成該次服務。</li>
            </ol>
          </li>
          <li>評分系統
            <ol>
              <li>當會員撰寫「評分」並上傳送出，或開始使用本項服務功能時，即表示已閱讀、瞭解並同意接受本項服務功能條款約定之所有內容，並完全接受本項服務功能現有與未來衍⽣的項⽬及內容。</li>
              <li>本網站得依「評分」內容，⾃行決定這篇「評分」是否被刊登或刪除。</li>
              <li>對於會員上載、傳送、輸入或提供之資料，會員同意並授權本網站得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進⾏關於網路行為之調查或研究，或為任何之合法使⽤。</li>
              <li>任何資料一經會員上載、傳送、輸入或提供⾄本網站時，視為會員已允許並授權本網站得無條件使⽤、修改、重製、公開播送、改作、散布、發⾏、公開發表該等資料，並得將前述權利轉授權他⼈，會員對此絕無異議。</li>
              <li>會員並授權本網站，得以依 業務需求，自⾏決定將「評分」之全部或部分內容，轉載刊登於他處。</li>
            </ol>
          </li>
          <li>第三⽅方網站；附加產品及服務
            <ol>
              <li>在有關服務中，您可能會遇⾒⾄第三⽅網站的連結或提及第三⽅網站之處（「已連結網站」）。尋樂 並未就任何已連結網站或者任何已連結網站上所載的資訊、產品或服務進⾏背書或擔保。已連結網站受其⾃⾝的使⽤條款及私隱政策所管限。您可能需要使⽤或獲得其他產品或服務才能對有關服務進⾏使⽤，例如，⾏動設備、互聯網存取及資料連接。您必須單獨獲得或使⽤該等產品或服務，並且⽀付所有相關費⽤（包括⽤於互聯網存取或其他資料傳輸）。</li>
            </ol>
          </li>
          <li>其他事項
            <ol>
              <li>用⼾不得：
                <ol>
                  <li>使⽤任何裝置、軟件或程式⼲預或企圖⼲預此網站之正常運作或任何在此網站進⾏的交易；</li>
                  <li>進⾏任何令此網站的基礎設施承受不合理或不合比例的巨⼤負荷的活動；</li>
                  <li>把其密碼向第三者透露或與第三者使用同⼀密碼或將其密碼⽤作未經許可的用途。</li>
                  <li>不得濫⽤本網站，蓄意引入病毒、⽊⾺程式、蠕蟲病毒及邏輯炸彈，或任何其他惡意或技術上有害的素材，且不得以任何⽅式（包括但不限於透過阻斷服務攻擊或分散式阻斷服務攻擊）攻擊本網站。</li>
                </ol>
              </li>
              <li>當你表示同意及遵守這份合約，這代表你能夠理解這份合約的內容及有正常的判斷⼒去作出決定。若果你不同意本公司之守則，請不要登記使⽤該項服務。本站有權停⽌提供服務給任何會員。 </li>
            </ol>
          </li>
          <li>免責聲明及責任限制
            <ol>
              <li>鑒於科技及在線環境屬不可預測，本公司並不保證（不論明示或暗示）本網站的功能、運作或連接能⼒會不受⼲預及無誤，亦不保證當中的缺陷會得到修正，或本網站或可供本網站使⽤的伺服器將無病毒、⽊⾺程式、蠕蟲程式或其他有害成份。您有責任確保您備有合適的設備以正常瀏覽及使⽤本網站，並排除任何可能損害您電腦的事項。本公司不會就由您使⽤或未能瀏覽本網站及／或與之有關的任何事項承擔責任。</li>
              <li>本網站及其內容按「現狀」提供，風險須由您自⾏承擔，且無任何形式的保證。在適用法律法規所允許的最⼤程度下，本公司不會就本網站及當中所載資訊概不作出任何形式的保證（不論明示或暗示）。</li>
              <li>本公司不會就任何因天災、火災、洪澇、意外、暴亂、戰爭、恐怖襲擊、政府⼲預、禁運、示威、勞資糾紛、設備故障（包括但不限於互聯網系統故障)，或任何超出本公司合理控制的原因，⽽直接或間接引致本公司未能、延誤及違反本公司的責任及義務而承擔任何責任。</li>
              <li>下列情形發⽣時，本網站有權可以停⽌、中斷提供本服務：
                <ol>
                  <li>對本服務相關軟硬體設備進⾏更換、升級、保養或施⼯時。</li>
                  <li>發生突發性之電子通信設備故障時。</li>
                  <li>天災或其他不可抗⼒之因素致使本網站無法提供服務時。</li>
                </ol>
              </li>
              <li>不擔保⽤⼾、導師、服務提供者⼈⽣安全<br />尋樂 明確聲明⽤戶、導師、服務提供者的⼈⽣安全或其他個⼈利益得失，亦非 尋樂 控制範圍之內。 尋樂 概不接納或承擔有關導師和家長或學生之間之任何法律責任。</li>
              <li>免除 尋樂 的法律責任<br />若家長或學⽣與導師、服務提供者之間出現任何糾紛或因使⽤服務⽽引起任何糾紛，與該等糾紛有關或因而引起的所有責任、索償、索求、賠償⾦（實質或間接），不論屬何性質，已知及未知、懷疑與否、已公開及未公開者， 尋樂 ⼀概毋須負上有關法律責任。</li>
            </ol>
          </li>
          <li>私隱政策
            <ol>
              <li>本公司尊重您的私隱，並致⼒於保護您的個⼈資料。詳情請參閱本網站的私隱政策。該政策為本條款的組成部分。</li>
            </ol>
          </li>
          <li>智慧財產權的保護
            <ol>
              <li>本網站所使⽤之軟體、程式及網站上所有內容，包括但不限於著作、圖⽚、檔案、資訊、資料、網站架構、網⾴設計，均由本網站或其他權利⼈依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權等。</li>
              <li>任何⼈不得逕⾏使⽤、修改、重製、公開播送、改作、散布、發⾏、公開發表、進⾏還原⼯程、解編或反向組譯。如欲引⽤或轉載前述之軟體、程式或網站內容，必須依法取得本網站或其他權利⼈的事前書⾯同意。如有違反之情事，您應對本網站或其他權利⼈負損害賠償責任（包括但不限於訴訟費⽤及律師費⽤等）。</il>
            </ol>
          </li>
          <li>修改權
            <ol>
              <li>當您開始使⽤本服務時，即表示您已充分閱讀、瞭解與同意接受本條款之內容。本公司有權於任何時間修改與變更本條款之內容，並將不個別通知會員，建議您定期查閱本服務條款。如您於本條款修改與變更後仍繼續使⽤本服務，則視為您已閱讀、瞭解與同意接受本條款修改或變更。</li>
              <li>本公司有權暫時或永久修改或中⽌提供本服務給您，您不得因此要求任何賠償。</li>
            </ol>
          </li>
          <li>查詢、意⾒
            <ol>
              <li>倘您對本網站有任何查詢、意⾒，請電郵⾄info@musicmap.hk。本公司會在可⾏情況下盡量即時回應您的每項查詢，但本公司不會就任何延誤回應或不回應承擔責任及／或法律律責任。</li>
            </ol>
          </li>
          <li>遵守法律
            <ol>
              <li>所有⽤⼾在使⽤此網站服務時，須遵守所有適⽤法律、規程、 條例和守則。</li>
              <li>條款的獨立性<br />倘根據任何具司法管轄權的法院所實際引⽤的適⽤法例，此合約中的任何條款屬違法或無法實施，此等條款將與合約分開及視作無效論， ⽽絕不影響此合約的其餘條款。然⽽，在有關法律容許的情況下，若有關法律條⽂可獲寬免，各⽅須同意其獲寬免，以便此合約的有關條款能夠有效、具約束⼒及得以實施。</li>
            </ol>
          </li>
          <li>網站內容變更
            <ol>
              <li>您同意本公司可隨時調整、變更、修改或撤銷本條款及相關的服務的全部或部分，並於本公司公告後立即生效，不作另⾏個別通知。在任何情況下，本公司不會就本條款及或相關服務的修改或撤銷向您承擔任何責任。</li>
            </ol>
          </li>
          <li>終⽌服務
            <ol>
              <li>您有權隨時終⽌使⽤本公司提供的⼀切服務，及要求刪除⽤⼾所提供的個⼈資料，本公司在收到電郵／書⾯有關通知後的30天內，終⽌有關服務及刪除您的個⼈資料。</li>
            </ol>
          </li>
        </ol>
      `,
    },
    privacyPolicy: {
      title: "私隱政策",
      content: `
        <p>尋樂 致力保障用戶的私隱，並確保用戶、導師、服務提供者和合作夥伴提供的所有資料被尊重看待和嚴格保密。</p>
        <p>生效日期： 2018年 9月 15日</p>
        <p>尋樂 或其代表會收集以下資料：</p>
        <ol>
          <li>你提供的資料
            <ol>
              <li>用戶帳號<br />我們會在你建立或更新 尋樂 帳戶時收集資料。這可包括但不限於:你的姓名、性別、年齡、電郵、電話號碼、登入名稱與密碼、地址。</li>
              <li>用戶內容<br />當你聯絡 尋樂 客戶服務、對其他用戶評分或讚賞，又或者聯絡 尋樂 時，我們可收集你所提交的資料。</li>
            </ol>
          </li>
          <li>使用服務時產生的資料
            <ol>
              <li>位置資料<br />視乎你使用的 尋樂 服務、Web 設定或裝置權限而定，我們可透過 GPS、IP 位址和 WiFi 等數據收集你的精確或大概位置資料。</li>
              <li>信用卡資料<br />當使用信用卡進行網上付款時，你的信用卡資料會保存於Stripe – 一間提供安全支付服務的機構，目的純粹是處理付款，尋樂在任何時候都不會保存這些資料。在即時簽帳授權過程中，客戶的付款詳情會以SSL(Secure Socket Layer)技術安全傳送。</li>
              <li>裝置資料<br />我們可收集你存取服務時所使用的裝置資料，包括硬件型號、裝置 IP 位址、操作系統與版本、軟件、檔案名稱與版本、偏好語言、唯一裝置識別碼、廣告識別碼、序號、裝置動態資料及流動網絡資料。</li>
            </ol>
          </li>
          <li>其他來源的資料
            <ol>
              <li>用戶意見，例如評分或讚賞。</li>
              <li>在推薦計劃中提供你資料的用戶。</li>
              <li>尋樂 可將從上述來源所得的資料，結合 尋樂 所擁有的其他資料一併使用。</li>
            </ol>
          </li>
          <li>會將收集到的資料用於以下用途，包括但不限於：
            <ol>
              <li>提供服務及功能</li>
              <li>使用收集到的資料提供、個人化、維護及改善產品及服務。 這包括使用資料以：
                <ol>
                  <li>建立及更新你的帳戶。</li>
                  <li>驗證你的身分。</li>
                </ol>
              </li>
              <li>提供分享功能，讓你與別人分享資料，包括但不限於:對導師、服務提供者的讚賞、推薦朋友使用 尋樂等。</li>
              <li>進行所需的內部運作，讓服務得以提供，包括解決軟件錯誤和運作問題、進行數據分析、測試和研究，以及監察和分析使用及活動趨勢。</li>
              <li>研發<br />我們可將收集到的資料用於測試、研究、分析及產品開發用途，從而改善和提升服務的安全性與保障、開發新功能和產品，以及支援與服務關連的方案。</li>
              <li>用戶與導師、服務提供者之間的通訊<br />尋樂 使用收集到的資料，讓用戶之間能夠互相通訊。包括但不限於:預約確認後，導師將獲知悉用戶的個人資料：姓名，性別，聯絡電話，年齡，上課地址，而用戶亦獲知悉導師的個人資料：姓名，性別，聯絡電話，上課地址。用戶、導師、服務提供者可致電或傳送文字訊息給對方以確認試堂資料。</li>
              <li>尋樂 發出的通訊<br />尋樂 可使用收集到的資料，就產品、服務、推廣、研究、問卷調查、最新消息、更新和活動與你聯繫。</li>
              <li>法律程序及規定<br />我們可將收集到的資料用於調查或處理你在使用 尋樂 服務時作出的申索或爭議，又或者在適用法律允許、應監管機構、政府實體和官方查詢的要求而使用收集到的資料。</li>
            </ol>
          </li>
          <li>Cookies的運用
            <ol>
              <li>本網站使用記錄精靈(cookies)。記錄精靈(cookies)屬小檔案，大部份網站均使用，以將純文字資料傳送至電腦硬碟或其他存取裝置。</li>
              <li>Musicmap.hk網站會在下列情況下，在您瀏覽器中寫入並讀取Cookies：
                <ol>
                  <li>為提供更好、更個人化的服務，以及方便您參與個人化的互動活動。</li>
                  <li>為統計瀏覽人數及分析瀏覽模式，以了解網頁瀏覽的情況，做為Musicmap.hk網站改善服務的參考。</li>
                </ol>
              </li>
              <li>閣下使用本網站，即表示同意此等記錄精靈(cookies)的使用。</li>
              <li>閣下可修改瀏覽器設定，控制本網站所用記錄精靈(cookies)。有關如何尋找儲存記錄精靈(cookies)檔案或目錄的指示，請參閱閣下互聯網瀏覽器幫助與支援一欄。</li>
              <li>刪除或控制記錄精靈(cookies)<br />如刪除記錄精靈(cookies)或日後停用記錄精靈(cookies)，閣下或無法使用本網站若干網頁或功能。</li>
              <li>本網站或含有連結至外部網站。該等網站自行設定其記錄精靈(cookies)，本網站並不控制該等記錄精靈(cookies)的使用，如閣下按連結至任何外部網站時務請注意。</li>
            </ol>
          </li>
          <li>安全
            <ol>
              <li>所有傳送至 尋樂 的資訊均以SSL(Secure Socket Layer)加密傳輸。SSL是一個公認的編碼系統，令瀏覽器在你發送資料給我們前自動將之加密或擾亂。我們亦會把户口資料存放在網站安全的部分，只限 尋樂 合資格員工存取。但遺憾的是，互聯網上没有百份百安全的數據傳輸。我們在盡力保護你的資料的同時，並不能確保或保證這些資訊的安全。</li>
            </ol>
          </li>
          <li>管轄法律及權限
            <ol>
              <li>使用條款受香港特別行政區法律管轄。你同意服從香港法院的非專屬管轄權。</li>
            </ol>
          </li>
          <li>修改
            <ol>
              <li>保留在任何時候及毋須事先通知的情況下修改私隱政策的權利。任何修改在本網站更新後即時生效。</li>
              <li>我們會不定期更新本政策。我們若進行重大變更，會透過 Musicmap.hk （本網站內）或電子郵件等其他方式通知您。</li>
              <li>我們鼓勵您定期檢閱本政策，以便掌握我們隱私保障作法的最新資訊。</li>
            </ol>
          </li>
          <li>你的同意
            <ol>
              <li>使用本網站代表你同意我們根據此私隱政策收集和使用有關你的資訊。</li>
            </ol>
          </li>
        </ol>
      `,
    },
  },
};
