
















import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import Banner from "@/components/Landing/Banner.vue";
import PopularSkills from "@/components/Landing/PopularSkills.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import FeaturedInstructors from "@/components/Landing/FeaturedInstructors.vue";
import LatestActivities from "@/components/Landing/LatestActivities.vue";
import Bottom from "@/components/Bottom.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  AsyncState,
  AsyncDataParam,
  InstructorSkill,
  ListActivitiesResult,
  Skill,
} from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";

const Codes = namespace("codes");
const Instructors = namespace("instructors");
const Activity = namespace("activity");

@Component({
  components: {
    Layout,
    Top,
    Banner,
    PopularSkills,
    FeaturedInstructors,
    LatestActivities,
    Bottom,
    LoadingIcon,
  },
})
export default class Landing extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public static async asyncData({ store, route }: AsyncDataParam) {
    await Promise.all([
      store.dispatch("codes/fetchSkills"),
      store.dispatch("instructors/fetchFeaturedInstructors"),
      store.dispatch("activity/listActivities", {
        type: null,
        offset: 0,
        limit: 20,
      }),
    ]);
  }

  @Codes.State("skills")
  public skillsState!: AsyncState<Skill[]>;

  @Instructors.State("featuredInstructors")
  public featuredInstructorsState!: AsyncState<InstructorSkill[]>;

  @Activity.State("activityList")
  public activityListState!: AsyncState<ListActivitiesResult>;

  get title() {
    return this.$t("landing.title");
  }

  get ogImage() {
    return require("@/assets/img/mm-landing-banner.png");
  }

  get isLoading() {
    return (
      [
        AsyncStates.statusOf(this.skillsState),
        AsyncStates.statusOf(this.featuredInstructorsState),
        AsyncStates.statusOf(this.activityListState),
      ].indexOf(AsyncStatus.LOADING) >= 0
    );
  }

  get featuredInstructors() {
    return this.featuredInstructorsState.data || [];
  }

  get activityList() {
    return this.activityListState.data
      ? this.activityListState.data.value.slice(0, 20)
      : [];
  }

  public destroyed() {
    this.$store.dispatch("instructors/invalidateFeaturedInstructors");
  }
}
