




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CloseButton extends Vue {
  @Prop({ default: "" }) public returnPath!: string;
  public back() {
    if (!this.returnPath) {
      this.$router.push(`/${this.$i18n.locale}`);
    } else {
      // this.$router.push(this.returnPath);
      this.$router.go(-1);
    }
  }
}
