







































import { Component, Vue, Prop } from "vue-property-decorator";
import { Course, Instructor, Skill } from "@/@types";
import { namespace } from "vuex-class";
import ProfileImage from "@/components/InstructorCard/ProfileImage.vue";
import GenderIcon from "@/components/InstructorCard/GenderIcon.vue";
import RatingScore from "@/components/InstructorCard/RatingScore.vue";
import PriceRange from "@/components/InstructorCard/PriceRange.vue";
import RegionList from "@/components/InstructorCard/RegionList.vue";
import ProfileTag from "@/components/ProfileTag.vue";

@Component({
  components: {
    ProfileImage,
    GenderIcon,
    RatingScore,
    PriceRange,
    RegionList,
    ProfileTag,
  },
})
export default class InstructorDetailCard extends Vue {
  @Prop({ required: true }) public instructor!: Instructor;
  @Prop({ required: true }) public skill!: Skill;

  get instructorSkill() {
    return this.instructor.skills.find(
      (skill) => skill.skill.id === this.skill.id
    );
  }

  get offerCourseMinPrice() {
    return this.instructorSkill ? this.instructorSkill.offerCourseMinPrice : 0;
  }

  get offerCourseMaxPrice() {
    return this.instructorSkill ? this.instructorSkill.offerCourseMaxPrice : 0;
  }
}
