






import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class GenderIcon extends Vue {
  @Prop({ required: true }) public gender!: string;
}
