















































import { Component, Vue, Prop } from "vue-property-decorator";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import ProfileImage from "@/components/InstructorCard/ProfileImage.vue";
import ProfileTag from "@/components/ProfileTag.vue";
import GenderIcon from "@/components/InstructorCard/GenderIcon.vue";
import RatingScore from "@/components/InstructorCard/RatingScore.vue";
import RegionList from "@/components/InstructorCard/RegionList.vue";
import PriceRange from "@/components/InstructorCard/PriceRange.vue";
import { Instructor, Skill } from "@/@types";

@Component({
  components: {
    I18nRouterLink,
    ProfileImage,
    ProfileTag,
    GenderIcon,
    RatingScore,
    RegionList,
    PriceRange,
  },
})
export default class InstructorCard extends Vue {
  @Prop({ required: true }) public instructor!: Instructor;
  @Prop({ required: true }) public skill!: Skill;

  get instructorSkill() {
    return this.instructor.skills.find(
      (skill) => skill.skill.id === this.skill.id
    );
  }

  get offerCourseMinPrice() {
    return this.instructorSkill ? this.instructorSkill.offerCourseMinPrice : 0;
  }

  get offerCourseMaxPrice() {
    return this.instructorSkill ? this.instructorSkill.offerCourseMaxPrice : 0;
  }
}
