
















































import { Component, Vue, Prop } from "vue-property-decorator";
import { Activity } from "@/@types";
import SsrCarousel from "@/components/SsrCarousel.vue";

@Component({
  components: {
    SsrCarousel,
  },
})
export default class ActivityDetail extends Vue {
  @Prop({ required: true })
  public activity!: Activity;

  get imageUrls() {
    return this.activity.banners.map((banner) => banner.imageUrl);
  }
}
