

























































































import moment, { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getMomentLocale } from "@/i18n";
import { sortTimeslots } from "@/helpers/timeslot";
import {
  AsyncState,
  BookCourseStepOne,
  BookCourseStepTwo,
  Course,
  Instructor,
} from "@/@types";

interface FilledTimeslot {
  date: Moment;
  fromTime: Moment;
  toTime: Moment;
}

const BookCourse = namespace("bookCourse");

@Component
export default class ConfirmInfo extends Vue {
  @BookCourse.State("course") public courseState!: AsyncState<Course>;
  @BookCourse.State("instructor") public instructorState!: AsyncState<
    Instructor
  >;
  @BookCourse.State("stepOne") public stepOne!: BookCourseStepOne;
  @BookCourse.State("stepTwo") public stepTwo!: BookCourseStepTwo;

  get course() {
    return this.courseState.data;
  }

  get instructor() {
    return this.instructorState.data;
  }

  get description() {
    if (this.course && this.instructor) {
      const { locale } = this.$i18n;
      const { skill } = this.course;
      const { displayName } = this.instructor;
      return this.$t("bookCourse.step3.instructor.value", {
        name: displayName[locale],
        skill: skill.name[locale],
      });
    }
    return "";
  }

  get student() {
    const { studentName, studentGender, studentAgeRange } = this.stepOne;
    return this.$t("bookCourse.step3.student.value", {
      name: studentName,
      gender: this.$t(`common.studentGender.${studentGender}`),
      ageRange: studentAgeRange,
    });
  }
  get level() {
    if (!this.course) {
      return "";
    }

    const { locale } = this.$i18n;
    const { duration } = this.$route.params;
    return this.$t("bookCourse.step3.level.value", {
      name: this.course.level.name[locale],
      duration,
    });
  }
  get venueType() {
    const { venueType } = this.stepTwo;
    return this.$t(`common.venueType.${venueType}`);
  }
  get region() {
    const { locale } = this.$i18n;
    const { region } = this.stepTwo;
    return region ? region.name[locale] : "";
  }
  get namedPrice(): number | null {
    const { duration } = this.$route.params;
    const c = this.course as any;
    const named: number | null = c[`${duration}MinsNamedPrice`];
    const actual: number | null = c[`${duration}MinsActualPrice`];
    return named && actual && named > actual ? named : null;
  }
  get actualPrice(): number | null {
    if (this.course) {
      const { duration } = this.$route.params;
      const c = this.course as any;
      return c[`${duration}MinsActualPrice`];
    }
    return null;
  }
  get timeslots() {
    return sortTimeslots(this.stepTwo.timeslots) as FilledTimeslot[];
  }
  public getTimeslotDate(timeslot: FilledTimeslot) {
    return moment(timeslot.date)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL dddd");
  }
  public getTimeslotPeriod(timeslot: FilledTimeslot) {
    const from = moment(timeslot.fromTime).format("h:mma");
    const to = moment(timeslot.toTime).format("h:mma");
    return `${from}-${to}`;
  }
}
