











import moment, { Moment } from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import { getMomentLocale } from "@/i18n";
import languages from "@/i18n/datepicker";

@Component({
  components: {
    Datepicker,
  },
})
export default class TimeslotDateInput extends Vue {
  @Prop({ required: true }) public selected!: Moment | null;
  @Prop({ default: false }) public error!: boolean;

  public disabledDates = {
    to: moment()
      .add(2, "days")
      .toDate(),
  };
  get language() {
    return languages[this.$i18n.locale];
  }
  public format(date: Date) {
    return moment(date)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL dddd");
  }
  public onSelected(event: Date) {
    this.$emit("selected", moment(event));
  }
}
