















import { Component, Prop, Vue } from "vue-property-decorator";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import { Skill } from "@/@types";

@Component({
  components: {
    I18nRouterLink,
  },
})
export default class ExpandedSearchBarItem extends Vue {
  @Prop(Object) public skill!: Skill;
  @Prop({ default: false }) public active!: boolean;

  get iconSrc() {
    if (this.active) {
      return this.skill.badgeImageUrl;
    } else {
      return this.skill.iconImageUrl;
    }
  }
  get name() {
    return this.skill.name[this.$i18n.locale];
  }
  public onClick() {
    this.$emit("selected");
    const skill = this.skill.code;
    const { locale } = this.$i18n;
    this.$router.push(`/${locale}/search/${skill}`);
  }
}
