















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface Option {
  value: string;
  label: string;
}

@Component
export default class SelectInput extends Vue {
  @Prop({ default: [] }) public options!: Option[];
  @Prop({ default: "" }) public selected!: string;
  @Prop({ default: "" }) public emptyOptionLabel!: string;

  private selectedState: string = "";

  public mounted() {
    this.selectedState = this.selected;
  }

  @Watch("selected")
  private onSelectedPropChanged(value: string) {
    this.selectedState = value;
  }

  @Watch("selectedState")
  private onSelectedStateChanged(value: string) {
    this.$emit("change", value);
    this.selectedState = this.selected;
  }
}
