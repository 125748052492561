

































import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import PageTitle from "@/components/FormFields/PageTitle.vue";
import ConfirmInfo from "@/components/BookTuning/ConfirmInfo.vue";
import AliPayButton from "@/components/PaymentForm/AliPayButton.vue";
import ApplePayButton from "@/components/PaymentForm/ApplePayButton.vue";
import PaymentForm from "@/components/PaymentForm/PaymentForm.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  Tuning,
  BookTuningStepOne,
  BookTuningStepTwo,
  BookTuningStepThree,
  BookTuningDone,
  AsyncState,
} from "@/@types";

interface TokenEvent {
  token: any;
  name: string;
  email: string;
  successCallback?: () => void;
  failureCallback?: (error: Error) => void;
}
const BookTuning = namespace("bookTuning");
@Component({
  components: {
    PageTitle,
    ConfirmInfo,
    AliPayButton,
    ApplePayButton,
    PaymentForm,
  },
})
export default class StepThree extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @BookTuning.State("done") public done!: BookTuningDone;
  @BookTuning.State("stepOne") public stepOne!: BookTuningStepOne;
  @BookTuning.State("stepTwo") public stepTwo!: BookTuningStepTwo;
  @BookTuning.State("stepThree") public stepThree!: BookTuningStepThree;
  @BookTuning.Getter("findTuningById") public findTuningById!: (
    id: string
  ) => Tuning | undefined;

  get title() {
    return this.$t("bookTuning.step3.title");
  }

  get price(): number | null {
    const tuning = this.findTuningById(this.stepOne.tuningId);
    return tuning ? tuning.actualPrice : null;
  }

  get aliPayReturnPath(): string {
    const { locale } = this.$i18n;
    return `/${locale}/tuning/book/alipay`;
  }

  public mounted() {
    if (!this.done.stepOne || !this.done.stepTwo) {
      this.$router.replace("1");
    }
  }

  public updated() {
    const { error } = this.$route.query;
    if (error) {
      window.onload = () => window.alert(this.$t("common.error.unknown"));
    }
  }

  public destroyed() {
    window.onload = null;
  }

  public async onSaveSource(source: any) {
    const { tuningId } = this.stepOne;
    const { timeslots } = this.stepTwo;
    await this.$skygear.lambda("musicmap:create_stripe_source", {
      type: "TUNING",
      sourceId: source.id,
      clientSecret: source.client_secret,
      source,
      payload: {
        tuningId,
        price: this.findTuningById(tuningId)!.actualPrice,
        data: {
          ...this.stepOne,
          ...this.stepTwo,
          timeslots: timeslots.map((timeslot) => ({
            date: timeslot.date ? moment(timeslot.date).utc() : null,
            fromTime: timeslot.fromTime
              ? moment(timeslot.fromTime).utc()
              : null,
            toTime: timeslot.toTime ? moment(timeslot.toTime).utc() : null,
          })),
        },
      },
    });
    window.location.href = source.redirect.url;
  }

  public async onToken(event: TokenEvent) {
    this.$store.dispatch("bookTuning/setStepThree", {
      cardHolderName: event.name,
      email: event.email,
    });
    try {
      const response = await this.$store.dispatch("bookTuning/bookTuning", {
        stripeToken: event.token.id,
      });
      if (event.successCallback) {
        event.successCallback();
      }
      await this.$store.dispatch("auth/setUser");
    } catch (error) {
      if (event.failureCallback) {
        event.failureCallback(error);
      }
    }
  }
  public onBack() {
    this.$router.push("2");
  }
}
