import Vue from "vue";
import { createApp } from "@/main";
import { isOnlyLocaleChange } from "@/router";
import * as Sentry from "@sentry/browser";
// @ts-ignore
import infiniteScroll from "vue-infinite-scroll";
// @ts-ignore
import vClickOutside from "v-click-outside";
import { configureApi } from "./api-client";
import { getRuntimeConfig } from "./config";

getRuntimeConfig().then(
  (config) => {
    Vue.use(infiniteScroll);
    Vue.use(vClickOutside);

    Sentry.init({
      dsn: config.VUE_APP_SENTRY_DSN || process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Sentry.Integrations.Vue({ Vue })],
    });

    Vue.mixin({
      mounted() {
        const self = this as any;
        const { asyncData } = self.constructor;
        if (asyncData) {
          self.dataPromise = asyncData({
            store: self.$store,
            route: self.$route,
          });
        }
      },
      beforeRouteUpdate(to, from, next) {
        if (isOnlyLocaleChange(from, to)) {
          return next();
        }
        const self = this as any;
        const { asyncData } = self.constructor;
        if (asyncData) {
          asyncData({
            store: self.$store,
            route: to,
          })
            .then(next)
            .catch(next);
        } else {
          next();
        }
      },
    });

    Vue.config.productionTip = false;
    Vue.prototype.configs = {
      NODE_ENV: config.NODE_ENV || process.env.NODE_ENV,
      BASE_URL: config.BASE_URL || process.env.BASE_URL,
      GA_TRACKING_ID: config.GA_TRACKING_ID || process.env.GA_TRACKING_ID,
      FACEBOOK_APP_ID: config.FACEBOOK_APP_ID || process.env.FACEBOOK_APP_ID,
      VUE_APP_SKYGEAR_SERVER_ENDPOINT:
        config.VUE_APP_SKYGEAR_SERVER_ENDPOINT ||
        process.env.VUE_APP_SKYGEAR_SERVER_ENDPOINT,
      VUE_APP_SKYGEAR_CLIENT_ENDPOINT:
        config.VUE_APP_SKYGEAR_CLIENT_ENDPOINT ||
        process.env.VUE_APP_SKYGEAR_CLIENT_ENDPOINT,
      VUE_APP_SKYGEAR_API_KEY:
        config.VUE_APP_SKYGEAR_API_KEY || process.env.VUE_APP_SKYGEAR_API_KEY,
      VUE_APP_STRIPE_API_KEY:
        config.VUE_APP_STRIPE_API_KEY || process.env.VUE_APP_STRIPE_API_KEY,
      VUE_APP_DEBUG: config.VUE_APP_DEBUG || process.env.VUE_APP_DEBUG,
    };

    configureApi(
      (window as any).__INITIAL_STATE__.auth.accessToken,
      config.VUE_APP_SKYGEAR_API_KEY,
      config.VUE_APP_SKYGEAR_CLIENT_ENDPOINT
    )
      .then((skygear) => createApp(skygear, (window as any).__INITIAL_STATE__))
      .then(({ app, router }) => {
        router.onReady(() => {
          app.$mount("#app");
        });
      });
  },
  (error) => {
    console.error("Unable to fetch runtime configs", error);
  }
);
