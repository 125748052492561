















import { Mixins, Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

@Component({
  components: {
    Layout,
    Top,
    Bottom,
  },
})
export default class HowWeWork extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  get title() {
    return this.$t("howWeWork.title");
  }
  get src() {
    switch (this.$i18n.locale) {
      case "hk":
        return require("@/assets/img/mm-howto-hk.png");
      case "cn":
        return require("@/assets/img/mm-howto-cn.png");
      case "en":
        return require("@/assets/img/mm-howto-en.png");
    }
  }
}
