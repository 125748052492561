







import { Component, Vue, Prop } from "vue-property-decorator";
import moment, { Moment } from "moment";
import { Activity } from "@/@types";
import { getMomentLocale } from "@/i18n";

@Component
export default class ActivityDate extends Vue {
  @Prop({ required: true })
  public value!: string;

  private get momentValue() {
    return moment(this.value).locale(getMomentLocale(this.$i18n.locale));
  }

  private get year() {
    return this.momentValue.format("YY");
  }

  private get month() {
    let format: string;
    if (getMomentLocale(this.$i18n.locale).startsWith("zh")) {
      format = "MMMM";
    } else {
      format = "MMM";
    }
    return this.momentValue.format(format).toLocaleUpperCase();
  }

  private get day() {
    return this.momentValue.format("D").toLocaleUpperCase();
  }
}
