import { Timeslot } from "@/@types";

export function sortTimeslots(timeslots: Timeslot[]) {
  const cloned = timeslots.reduce(
    (arr, t) => {
      arr.push({ ...t });
      return arr;
    },
    [] as Timeslot[]
  );
  return cloned
    .filter((t) => t.date && t.fromTime && t.toTime)
    .sort((a, b) => {
      if (a.date!.isBefore(b.date!)) {
        return -1;
      }
      if (a.date!.isAfter(b.date!)) {
        return 1;
      }
      if (a.fromTime!.isBefore(b.fromTime!)) {
        return -1;
      }
      if (a.fromTime!.isAfter(b.fromTime!)) {
        return 1;
      }
      if (a.toTime!.isBefore(b.toTime!)) {
        return -1;
      }
      if (a.toTime!.isAfter(b.toTime!)) {
        return 1;
      }
      return 0;
    });
}
