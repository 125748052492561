










































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { BookingSelectorOption } from "@/@types";

@Component
export default class BookingSelector extends Vue {
  get selectedOption() {
    return this.options.find((option) => option.value === this.selectedState);
  }
  @Prop({ required: true }) public options!: BookingSelectorOption[];
  @Prop({ default: "" }) public selected!: string;
  @Prop({ default: "" }) public selectLabel!: string;
  @Prop({ default: "" }) public bookNowLabel!: string;

  private selectedState = "";

  @Watch("options")
  public onOptionsChanged() {
    this.setSelectedState();
  }

  @Watch("selected")
  public onSelectedChanged() {
    this.setSelectedState();
  }

  public mounted() {
    this.setSelectedState();
  }

  public onButtonClick(event: Event) {
    if (this.selectedOption) {
      const { url } = this.selectedOption;
      if (url) {
        const { locale } = this.$i18n;
        this.$router.push(`/${locale}${url}`);
      } else {
        this.$emit("click", this.selectedOption);
      }
    }
  }

  private setSelectedState() {
    if (this.options.length > 0) {
      const option = this.options.find(
        (option) => option.value === this.selected
      );
      this.selectedState = option ? option.value : this.options[0].value;
    }
  }
}
