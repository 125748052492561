









import { Component, Prop, Vue } from "vue-property-decorator";
import { Skill } from "@/@types";

@Component
export default class SkillItem extends Vue {
  @Prop(Object) public skill!: Skill;
  @Prop({ default: false }) public active!: boolean;

  get iconPath() {
    return this.active ? this.skill.badgeImageUrl : this.skill.iconImageUrl;
  }
}
