



import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class I18nRouterLink extends Vue {
  @Prop({ required: true }) public to!: string;
}
