
import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
const env = typeof window === "undefined" ? "server" : "client";

@Component
export default class TitleMixin extends Vue {
  public getFullTitle(title: string) {
    return `${title} | MusicMap`;
  }
  public setOGTitle(title: string) {
    const els = document.getElementsByTagName("meta");
    const el = Array.from(els).find(
      (el) => el.getAttribute("property") === "og:title"
    );
    if (el) {
      el.setAttribute("content", this.getFullTitle(title));
    }
  }
  public setTitleOnServerSide() {
    if (env === "server") {
      const self = this as any;
      if (self.title) {
        self.$ssrContext.title = this.getFullTitle(self.title);
      }
    }
  }
  public created() {
    this.setTitleOnServerSide();
  }
  public setTitleOnClientSide() {
    if (env === "client") {
      const self = this as any;
      if (self.title) {
        document.title = this.getFullTitle(self.title);
        this.setOGTitle(self.title);
      }
    }
  }
  public mounted() {
    this.setTitleOnClientSide();
  }
  public updated() {
    this.setTitleOnClientSide();
  }
}
