










































import { Vue, Component, Prop } from "vue-property-decorator";
import StarInput from "@/components/Record/Course/StarInput.vue";
import { BookingCourse } from "@/@types";

@Component({
  components: {
    StarInput,
  },
})
export default class RatingField extends Vue {
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ required: true }) public bookingCourse!: BookingCourse;

  public submitting = false;
  public rating = 0;
  public comment = "";

  get displayRating() {
    return this.readonly ? this.bookingCourse.rating : this.rating;
  }

  get displayComment() {
    return this.readonly ? this.bookingCourse.comment : this.comment;
  }

  public updateRating(rating: number) {
    if (!this.readonly) {
      this.rating = rating;
    }
  }

  public updateComment(comment: string) {
    if (!this.readonly) {
      this.comment = comment;
    }
  }

  public async submit() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    try {
      await this.$store.dispatch("bookings/rateCourseBooking", {
        courseBookingId: this.bookingCourse.id,
        rating: this.rating,
        comment: this.comment,
      });
    } catch (e) {
      window.alert(this.$t("common.error.unknown"));
    } finally {
      this.submitting = false;
    }
  }
}
