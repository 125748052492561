




































































import { Component, Prop, Vue } from "vue-property-decorator";
import { createToken } from "vue-stripe-elements-plus";
import * as Sentry from "@sentry/browser";
import TextField from "@/components/FormFields/TextField.vue";
import CardNumberField from "@/components/PaymentForm/CardNumberField.vue";
import CardExpiryField from "@/components/PaymentForm/CardExpiryField.vue";
import CardCvcField from "@/components/PaymentForm/CardCvcField.vue";

@Component({
  components: {
    TextField,
    CardNumberField,
    CardExpiryField,
    CardCvcField,
  },
})
export default class PaymentForm extends Vue {
  @Prop({ default: "" }) public cardHolderName!: string;
  @Prop({ default: "" }) public email!: string;

  public cardNumber = false;
  public expiryDate = false;
  public cvc = false;
  public submitted = false;
  public posting = false;

  get cardHolderNameError() {
    if (this.submitted && !this.validateCardHolderName()) {
      return this.$t("paymentForm.cardHolderName.errors.required");
    }
    return "";
  }
  get emailError() {
    if (this.submitted) {
      if (!this.validateEmailRequired()) {
        return this.$t("paymentForm.email.errors.required");
      }
      if (!this.validateEmailFormat()) {
        return this.$t("paymentForm.email.errors.format");
      }
    }
    return "";
  }
  public validateCardHolderName() {
    return this.cardHolderName.length > 0;
  }

  public validateEmailRequired() {
    return this.email.length > 0;
  }
  public validateEmailFormat() {
    return this.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
  }
  public validate() {
    this.submitted = true;
    return (
      this.validateCardHolderName() &&
      this.validateEmailRequired() &&
      this.validateEmailFormat() &&
      this.cardNumber &&
      this.expiryDate &&
      this.cvc
    );
  }
  public async submit() {
    if (this.validate()) {
      this.posting = true;
      const { token, error } = await createToken({ name: this.cardHolderName });
      if (token) {
        this.$emit("token", {
          token,
          name: this.cardHolderName,
          email: this.email,
          successCallback: () => {
            this.$router.push("success");
          },
          failureCallback: (error: any) => {
            if (error.error && error.error.code === 30001) {
              window.alert(this.$t("common.error.transactionDeclined"));
            } else {
              Sentry.captureEvent(error);
              window.alert(this.$t("common.error.unknown"));
            }
            this.posting = false;
          },
        });
      } else {
        Sentry.captureEvent(error);
        window.alert(this.$t("common.error.unknown"));
        this.posting = false;
      }
    }
  }
}
