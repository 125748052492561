











































import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageTitle from "@/components/FormFields/PageTitle.vue";
import TextField from "@/components/FormFields/TextField.vue";
import AddressField from "@/components/FormFields/AddressField.vue";
import TimeslotField from "@/components/FormFields/TimeslotField.vue";
import NextButton from "@/components/FormFields/NextButton.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  AsyncState,
  BookTuningStepTwo,
  Timeslot,
  Region,
  BookTuningDone,
} from "@/@types";

const Codes = namespace("codes");
const BookTuning = namespace("bookTuning");
@Component({
  components: {
    PageTitle,
    TextField,
    AddressField,
    TimeslotField,
    NextButton,
  },
})
export default class StepTwo extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @BookTuning.State("done") public done!: BookTuningDone;
  @BookTuning.State("stepTwo") public stepTwo!: BookTuningStepTwo;
  @Codes.State("regions") public regionsState!: AsyncState<Region[]>;
  @Codes.Getter("findRegionByCode") public findRegionByCode!: (
    code: string
  ) => Region | undefined;

  public regionCode = "";
  public address = "";
  public timeslots: Timeslot[] = [
    { date: null, fromTime: null, toTime: null },
    { date: null, fromTime: null, toTime: null },
    { date: null, fromTime: null, toTime: null },
  ];
  public remark = "";
  public submitted = false;

  get title() {
    return this.$t("bookTuning.step2.title");
  }

  public mounted() {
    if (!this.done.stepOne) {
      this.$router.replace("1");
    }
    const { region, address, timeslots, remark } = this.stepTwo;
    this.regionCode = region ? region.code : "";
    this.address = address;
    this.timeslots = timeslots;
    this.remark = remark;
  }
  get regionError() {
    if (this.submitted && !this.validateRegionCode()) {
      return this.$t("bookTuning.step2.address.errors.regionRequired");
    }
    return "";
  }
  get addressError() {
    if (this.submitted && !this.validateAddress()) {
      return this.$t("bookTuning.step2.address.errors.addressRequired");
    }
    return "";
  }
  public timeslotError(timeslot: Timeslot) {
    if (this.submitted) {
      if (!this.hasTimeslot(timeslot)) {
        return this.$t("bookTuning.step2.timeslot.errors.required");
      }
      if (
        this.timeslots.filter((ts) => {
          return this.areSameTimeslots(timeslot, ts);
        }).length > 1
      ) {
        return this.$t("bookTuning.step2.timeslot.errors.duplicated");
      }
    }
    return "";
  }
  public validateRegionCode() {
    return this.regionCode.length > 0;
  }
  public validateAddress() {
    return this.address.length > 0;
  }
  public hasTimeslot(timeslot: Timeslot) {
    return !!(timeslot.date && timeslot.fromTime && timeslot.toTime);
  }
  public areSameTimeslots(a: Timeslot, b: Timeslot) {
    return (
      a.date &&
      b.date &&
      a.date.isSame(b.date) &&
      a.fromTime &&
      b.fromTime &&
      a.fromTime.isSame(b.fromTime) &&
      a.toTime &&
      b.toTime &&
      a.toTime.isSame(b.toTime)
    );
  }
  public validateTimeslots() {
    return (
      this.timeslots.every((timeslot) => {
        return this.hasTimeslot(timeslot);
      }) &&
      !this.areSameTimeslots(this.timeslots[0], this.timeslots[1]) &&
      !this.areSameTimeslots(this.timeslots[0], this.timeslots[2]) &&
      !this.areSameTimeslots(this.timeslots[1], this.timeslots[2])
    );
  }
  public validate() {
    return (
      this.validateRegionCode() &&
      this.validateAddress() &&
      this.validateTimeslots()
    );
  }
  public onNext() {
    this.submitted = true;
    if (this.validate()) {
      this.$store.dispatch("bookTuning/setStepTwo", {
        region: this.findRegionByCode(this.regionCode) || null,
        address: this.address,
        timeslots: this.timeslots,
        remark: this.remark,
      });
      this.$router.push("3");
    }
  }
  public onBack() {
    this.$router.push("1");
  }
}
