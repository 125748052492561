


























































import { Mixins, Component, Watch, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageTitle from "@/components/FormFields/PageTitle.vue";
import SelectField from "@/components/FormFields/SelectField.vue";
import RegionField from "@/components/FormFields/RegionField.vue";
import AddressField from "@/components/FormFields/AddressField.vue";
import TimeslotField from "@/components/FormFields/TimeslotField.vue";
import NextButton from "@/components/FormFields/NextButton.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  AsyncState,
  BookCourseStepTwo,
  Timeslot,
  Region,
  Instructor,
  BookCourseDone,
} from "@/@types";

const Codes = namespace("codes");
const BookCourse = namespace("bookCourse");
@Component({
  components: {
    PageTitle,
    SelectField,
    RegionField,
    AddressField,
    TimeslotField,
    NextButton,
  },
})
export default class StepTwo extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @BookCourse.State("done") public done!: BookCourseDone;
  @BookCourse.State("instructor") public instructor!: AsyncState<Instructor>;
  @BookCourse.State("stepTwo") public stepTwo!: BookCourseStepTwo;
  @Codes.State("venueType") public venueTypeCodes!: string[];
  @Codes.State("regions") public regionsState!: AsyncState<Region[]>;
  @Codes.Getter("findRegionByCode") public findRegionByCode!: (
    code: string
  ) => Region | undefined;

  public venueType = "";
  public regionCode = "";
  public address = "";
  public timeslots: Timeslot[] = [
    { date: null, fromTime: null, toTime: null },
    { date: null, fromTime: null, toTime: null },
    { date: null, fromTime: null, toTime: null },
  ];
  public submitted = false;

  get title() {
    return this.$t("bookCourse.step2.title");
  }

  @Watch("venueType")
  public onVenueTypeChange(current: string, old: string) {
    if (old !== "") {
      this.regionCode = "";
    }
  }
  get venueTypeOptions() {
    return this.venueTypeCodes
      .filter((code) => {
        return !(code === "INSTRUCTOR" && this.instructorRegions.length <= 0);
      })
      .map((code) => ({
        value: code,
        label: this.$t(`common.venueType.${code}`),
      }));
  }
  get instructorRegions() {
    return this.instructor.data ? this.instructor.data.regions : [];
  }
  get venueTypeError() {
    if (this.submitted && !this.validateVenueType()) {
      return this.$t("bookCourse.step2.venueType.errors.required");
    }
    return "";
  }
  get studentRegionError() {
    if (this.submitted && !this.validateRegionCode()) {
      return this.$t("bookCourse.step2.studentAddress.errors.regionRequired");
    }
    return "";
  }
  get studentAddressError() {
    if (this.submitted && !this.validateAddress()) {
      return this.$t("bookCourse.step2.studentAddress.errors.addressRequired");
    }
    return "";
  }
  get instructorRegionError() {
    if (this.submitted && !this.validateRegionCode()) {
      return this.$t("bookCourse.step2.instructorAddress.errors.required");
    }
    return "";
  }
  public mounted() {
    if (!this.done.stepOne) {
      this.$router.replace("1");
    }
    const { venueType, region, address, timeslots } = this.stepTwo;
    this.venueType = venueType || "";
    this.regionCode = region ? region.code : "";
    this.address = address;
    this.timeslots = timeslots;
  }
  public validateVenueType() {
    return this.venueType.length > 0;
  }
  public validateRegionCode() {
    return this.regionCode.length > 0;
  }
  public validateAddress() {
    return this.venueType !== "STUDENT" || this.address.length > 0;
  }
  public timeslotError(timeslot: Timeslot) {
    if (this.submitted) {
      if (!this.hasTimeslot(timeslot)) {
        return this.$t("bookCourse.step2.timeslot.errors.required");
      }
      if (
        this.timeslots.filter((ts) => {
          return this.areSameTimeslots(timeslot, ts);
        }).length > 1
      ) {
        return this.$t("bookCourse.step2.timeslot.errors.duplicated");
      }
    }
    return "";
  }
  public hasTimeslot(timeslot: Timeslot) {
    return !!(timeslot.date && timeslot.fromTime && timeslot.toTime);
  }
  public areSameTimeslots(a: Timeslot, b: Timeslot) {
    return (
      a.date &&
      b.date &&
      a.date.isSame(b.date) &&
      a.fromTime &&
      b.fromTime &&
      a.fromTime.isSame(b.fromTime) &&
      a.toTime &&
      b.toTime &&
      a.toTime.isSame(b.toTime)
    );
  }
  public validateTimeslots() {
    return (
      this.timeslots.every((timeslot) => {
        return this.hasTimeslot(timeslot);
      }) &&
      !this.areSameTimeslots(this.timeslots[0], this.timeslots[1]) &&
      !this.areSameTimeslots(this.timeslots[0], this.timeslots[2]) &&
      !this.areSameTimeslots(this.timeslots[1], this.timeslots[2])
    );
  }
  public validate() {
    return (
      this.validateVenueType() &&
      this.validateRegionCode() &&
      this.validateAddress() &&
      this.validateTimeslots()
    );
  }
  public onNext() {
    this.submitted = true;
    if (this.validate()) {
      this.$store.dispatch("bookCourse/setStepTwo", {
        venueType: this.venueType,
        region: this.findRegionByCode(this.regionCode) || null,
        address: this.address,
        timeslots: this.timeslots,
      });
      this.$router.push("3");
    }
  }
  public onBack() {
    this.$router.push("1");
  }
}
