









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InstrumentProfileImage extends Vue {
  @Prop({ default: "" }) public title!: string;
  @Prop({ required: true }) public imageUrl!: string;
}
