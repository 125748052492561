
















import { Component, Vue, Prop } from "vue-property-decorator";
import { Activity } from "@/@types";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import ActivityDate from "@/components/Activities/ActivityDate.vue";

@Component({
  components: {
    I18nRouterLink,
    ActivityDate,
  },
})
export default class ActivityCard extends Vue {
  @Prop({ required: true })
  public activity!: Activity;

  get coverImageUrl() {
    return this.activity.coverImageUrl;
  }
}
