




import { Mixins, Component, Vue } from "vue-property-decorator";
import ErrorView from "@/components/ErrorView.vue";
import { State } from "vuex-class";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/TitleMixin.vue";

@Component({
  components: {
    ErrorView,
  },
})
export default class ErrorPage extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @State("error") private error?: any;
  get title() {
    return this.$t("errorView.message");
  }
}
