














import { Component, Prop, Vue } from "vue-property-decorator";
import I18nRouterLink from "@/components/I18nRouterLink.vue";

@Component({
  components: { I18nRouterLink },
})
export default class TabBarItem extends Vue {
  @Prop({ required: true }) public type!: "search" | "activities" | "tuning";
  @Prop({ default: false }) public active!: boolean;
  @Prop({ default: "" }) public name!: string;
  @Prop({ default: "" }) public link!: string;
}
