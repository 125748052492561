



















import { Component, Vue } from "vue-property-decorator";
import SearchRouterLink from "@/components/Search/SearchRouterLink.vue";

@Component({
  components: {
    SearchRouterLink,
  },
})
export default class Banner extends Vue {}
