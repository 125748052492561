





















import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/FormFields/Layout.vue";
import SelectInput from "@/components/FormFields/SelectInput.vue";
import SelectGroupInput from "@/components/FormFields/SelectGroupInput.vue";

@Component({
  components: { Layout, SelectInput, SelectGroupInput },
})
export default class SelectField extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: "" }) public error!: string;
  @Prop({ default: [] }) public options!: Array<{
    value: string;
    label: string;
  }>;
  @Prop({ default: () => [] }) public optgroups!: Array<{
    value: string;
    label: string;
  }>;
  @Prop({ default: "" }) public selected!: string;
  @Prop({ default: "" }) public emptyOptionLabel!: string;
}
