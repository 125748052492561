import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import hk from "./hk";
import cn from "./cn";

Vue.use(VueI18n);

export const createI18n = () => {
  return new VueI18n({
    locale: "hk",
    fallbackLocale: "hk",
    messages: {
      en,
      hk,
      cn,
    },
  });
};

export const getMomentLocale = (locale: string) => {
  switch (locale) {
    case "en":
      return "en-gb";
    case "cn":
      return "zh-cn";
    case "hk":
    default:
      return "zh-hk";
  }
};
