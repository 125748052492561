
import { Component, Prop, Vue } from "vue-property-decorator";
import * as Sentry from "@sentry/browser";
// This is an adaptation to Typescript from
// https://gist.github.com/lorisleiva/69e537202d35d4ccd1b4a5cb8ddecf1c
@Component
export default class PaymentRequestButton extends Vue {
  @Prop({ required: true }) public stripe!: string;
  @Prop({ required: true }) public options!: object;

  public loading = false;
  public canMakePayment = false;
  public elements: any = null;
  public paymentRequest: any = null;

  public async mounted() {
    const stripeInstance = (window as any).Stripe(this.stripe);
    this.loading = true;
    this.canMakePayment = false;
    this.elements = stripeInstance.elements({ locale: this.$i18n.locale });
    this.paymentRequest = stripeInstance.paymentRequest(this.options);
    // Check the availability of the Payment Request API first.
    const canMakePayment = await this.paymentRequest.canMakePayment();
    this.init(canMakePayment);
    // Notify the parent component when we receive a token.
    this.paymentRequest.on("token", (event: any) => {
      this.$emit("token", {
        token: event.token,
        name: event.payerName,
        email: event.payerEmail,
        successCallback: () => {
          event.complete("success");
          this.$router.push("success");
        },
        failureCallback: (error: any) => {
          event.complete("fail");
          if (error.error && error.error.code === 30001) {
            window.alert(this.$t("common.error.transactionDeclined"));
          } else {
            Sentry.captureEvent(error);
            window.alert(this.$t("common.error.unknown"));
          }
          this.loading = false;
        },
      });
    });
  }
  public render(createElement: any) {
    // Render a loading slot if we are waiting for canMakePayment.
    if (this.loading) {
      return this.$slots.loading && this.$slots.loading[0];
    }
    // Render a warning slot if payment request isn't available.
    if (!this.canMakePayment) {
      return this.$slots.unavailable && this.$slots.unavailable[0];
    }
    // Render scoped slot if provided.
    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        listeners: { click: (event: any) => this.paymentRequest.show() },
        canMakePayment: this.canMakePayment,
      });
    }
    // Otherwise render default Stripe Element button.
    return createElement("div", { ref: "element" });
  }
  public init(canMakePayment: any) {
    this.loading = false;
    this.canMakePayment = !!canMakePayment;
    this.$nextTick(this.createPaymentRequestButton);
  }
  public createPaymentRequestButton() {
    if (!this.canMakePayment || !this.$refs.element) {
      return;
    }
    this.elements
      .create("paymentRequestButton", {
        paymentRequest: this.paymentRequest,
      })
      .mount(this.$refs.element);
  }
}
