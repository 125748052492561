





























import { Component, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import moment from "moment";
import LoadingIcon from "@/components/LoadingIcon.vue";
import ActivityCard from "@/components/Activities/ActivityCard.vue";
import { AsyncStates, AsyncStatus } from "@/store/async";
import { Activity, AsyncState, ListActivitiesResult } from "@/@types";

const Activity = namespace("activity");
@Component({
  components: { LoadingIcon, ActivityCard },
})
export default class ActivitiesList extends Vue {
  @Activity.State("activityList")
  public activityListState!: AsyncState<ListActivitiesResult>;

  get isEmpty() {
    return !this.isLoading && this.activityList.length === 0;
  }

  get isLoading() {
    return AsyncStates.statusOf(this.activityListState) === AsyncStatus.LOADING;
  }

  get activityList() {
    return this.activityListState.data ? this.activityListState.data.value : [];
  }

  get disableInfiniteScroll() {
    const result = this.activityListState.data;
    return this.isLoading || (result && !result.hasMore);
  }
  public getMonth(activity: Activity) {
    return moment(activity.startDate).month() + 1;
  }
  public onScroll() {
    this.$emit("load-data");
  }
}
