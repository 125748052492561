import { AsyncState } from "@/@types";

export enum AsyncStatus {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface AsyncCompletion<T> {
  requestId: number;
  data?: T;
  error?: Error;
}

export const AsyncStates = {
  default<T>(): AsyncState<T> {
    return {
      data: null,
      error: null,
      currentRequestId: -1,
      nextRequestId: 0,
    };
  },
  statusOf<T>(state: AsyncState<T>): AsyncStatus {
    if (state.currentRequestId >= 0) {
      return AsyncStatus.LOADING;
    }
    return state.error ? AsyncStatus.FAILURE : AsyncStatus.SUCCESS;
  },
  begin<T>(state: AsyncState<T>, resetData: boolean) {
    state.error = null;
    if (resetData) {
      state.data = null;
    }
    const requestId = state.nextRequestId++;
    state.currentRequestId = requestId;
  },
  completed<T>(state: AsyncState<T>, params: AsyncCompletion<T>) {
    if (state.currentRequestId !== params.requestId) {
      return;
    }
    state.error = params.error || null;
    // preserve original data if new request failed
    if (!params.error) {
      state.data = params.data || null;
    }
    state.currentRequestId = -1;
  },
};
