


































































import moment, { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getMomentLocale } from "@/i18n";
import { sortTimeslots } from "@/helpers/timeslot";
import {
  AsyncState,
  Tuning,
  Region,
  BookTuningStepOne,
  BookTuningStepTwo,
} from "@/@types";

interface FilledTimeslot {
  date: Moment;
  fromTime: Moment;
  toTime: Moment;
}

const Codes = namespace("codes");
const BookTuning = namespace("bookTuning");

@Component
export default class ConfirmInfo extends Vue {
  @BookTuning.State("stepOne") public stepOne!: BookTuningStepOne;
  @BookTuning.State("stepTwo") public stepTwo!: BookTuningStepTwo;
  @BookTuning.Getter("findTuningById") public findTuningById!: (
    id: string
  ) => Tuning | undefined;

  get tuning() {
    return this.findTuningById(this.stepOne.tuningId);
  }

  get instrument() {
    const { locale } = this.$i18n;
    return this.tuning ? this.tuning.tuningInstrument.name[locale] : "";
  }
  get requirements() {
    const { requireAccessaryReplacement, requireRelocation } = this.stepOne;
    const replacement = this.$t(
      `bookTuning.step3.requireAccessaryReplacement.${
        requireAccessaryReplacement ? "yes" : "no"
      }Label`
    );
    const relocation = this.$t(
      `bookTuning.step3.requireRelocation.${
        requireRelocation ? "yes" : "no"
      }Label`
    );
    const comma = this.$t("common.comma");
    return `${replacement}${comma}${relocation}`;
  }
  get region() {
    const { locale } = this.$i18n;
    const { region } = this.stepTwo;
    return region ? region.name[locale] : "";
  }
  get namedPrice(): number | null {
    const tuning = this.findTuningById(this.stepOne.tuningId);
    if (tuning) {
      const named = tuning.namedPrice;
      const actual = tuning.actualPrice;
      return named && actual && named > actual ? named : null;
    }
    return null;
  }
  get actualPrice(): number | null {
    const tuning = this.findTuningById(this.stepOne.tuningId);
    return tuning ? tuning.actualPrice : null;
  }
  get timeslots() {
    return sortTimeslots(this.stepTwo.timeslots) as FilledTimeslot[];
  }
  public getTimeslotDate(timeslot: FilledTimeslot) {
    return moment(timeslot.date)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL dddd");
  }
  public getTimeslotPeriod(timeslot: FilledTimeslot) {
    const from = moment(timeslot.fromTime).format("h:mma");
    const to = moment(timeslot.toTime).format("h:mma");
    return `${from}-${to}`;
  }
}
