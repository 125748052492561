













































































































import { Mixins, Component, Watch, Vue } from "vue-property-decorator";
import { User, AsyncState, AsyncDataParam, SsoProviders } from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";
import { namespace } from "vuex-class";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import Modal from "@/components/Modal.vue";
import TextField from "@/components/FormFields/TextField.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import ErrorView from "@/components/ErrorView.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

const Auth = namespace("auth");

@Component({
  components: {
    Layout,
    Top,
    Bottom,
    TextField,
    Modal,
    LoadingIcon,
    ErrorView,
  },
})
export default class EditAccount extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  get isParamsValid() {
    return (
      AsyncStates.statusOf(this.userState) === AsyncStatus.LOADING ||
      this.userState.data
    );
  }
  get title() {
    return this.$t("sidemenu.account");
  }
  get isLoading() {
    return AsyncStates.statusOf(this.userState) === AsyncStatus.LOADING;
  }
  get email() {
    const { data } = this.userState;
    if (data && !data.email.endsWith("@musicmap.hk")) {
      return data.email;
    }
    return "";
  }
  get ssoProviders() {
    const { data } = this.userState;
    return data ? data.ssoProviders : "";
  }

  public static async asyncData({ store, route }: AsyncDataParam) {
    return await store.dispatch("auth/fetchUserForEdit");
  }
  @Auth.State("userForEdit")
  public userState!: AsyncState<User>;

  public formName = "";
  public formPhoneNumber = "";
  public didTrySubmit = false;
  public submitting = false;
  public success = false;
  public isParamValid = false;

  get formNameError() {
    if (this.didTrySubmit && !this.validateName()) {
      return this.$t("account.name.errors.required");
    }
    return "";
  }
  get formPhoneNumberError() {
    if (this.didTrySubmit && !this.validatePhoneNumber()) {
      return this.$t("account.phone.errors.format");
    }
    return "";
  }
  public validate() {
    return this.validateName() && this.validatePhoneNumber();
  }
  public validateName() {
    return this.formName.trim().length > 0;
  }
  public validatePhoneNumber() {
    if (this.formPhoneNumber.trim().length > 0) {
      return this.formPhoneNumber.match(/^[0-9]{8}$/);
    }
    return true;
  }

  public submit() {
    this.didTrySubmit = true;
    if (!this.validate()) {
      return;
    }
    this.submitting = true;
    try {
      this.$store.dispatch("auth/updateAccountDetail", {
        displayName: this.formName.trim(),
        phoneNumber: this.formPhoneNumber,
      });
      this.didTrySubmit = false;
      this.success = true;
    } catch (error) {
      window.alert(this.$t("common.error.unknown"));
    } finally {
      this.submitting = false;
    }
  }

  public hideSuccessModal() {
    this.success = false;
  }
  @Watch("isParamsValid")
  private onUserStateChanged(isParamsValid: boolean) {
    if (isParamsValid && this.userState.data) {
      this.formName = this.userState.data.displayName;
      this.formPhoneNumber = this.userState.data.phoneNumber;
    }
  }
}
