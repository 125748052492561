var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.course)?_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-x"},[_c('page-title',{staticClass:"cell small-12",on:{"back":_vm.onBack}},[_vm._v("\n      "+_vm._s(_vm.$t("bookCourse.step1.title"))+"\n    ")]),_c('segmented-field',{staticClass:"cell small-12",attrs:{"selected":_vm.userIs,"label":_vm.$t('bookCourse.step1.userIs.label'),"options":this.userIsCodes.map(function (code) { return ({
          value: code,
          label: _vm.$t(("common.userIs." + code)),
        }); })},on:{"update:selected":function($event){_vm.userIs=$event}}}),_c('text-field',{staticClass:"cell small-12",attrs:{"value":_vm.studentName,"label":_vm.$t('bookCourse.step1.studentName.label'),"placeholder":_vm.$t('bookCourse.step1.studentName.placeholder'),"error":_vm.studentNameError},on:{"update:value":function($event){_vm.studentName=$event}}}),_c('segmented-field',{staticClass:"cell small-12",attrs:{"selected":_vm.studentGender,"label":_vm.$t('bookCourse.step1.studentGender.label'),"options":this.studentGenderCodes.map(function (code) { return ({
          value: code,
          label: _vm.$t(("common.studentGender." + code)),
        }); })},on:{"update:selected":function($event){_vm.studentGender=$event}}}),_c('select-field',{staticClass:"cell small-12",attrs:{"selected":_vm.studentAgeRange,"label":_vm.$t('bookCourse.step1.studentAgeRange.label'),"options":Array(20)
          .fill(0)
          .map(function (v, i) { return ({
            value: ((i * 5) + "-" + ((i + 1) * 5 - 1)),
            label: ((i * 5) + "-" + ((i + 1) * 5 - 1)),
          }); }),"error":_vm.ageRangeError},on:{"update:selected":function($event){_vm.studentAgeRange=$event}}}),_c('text-field',{staticClass:"cell small-12",attrs:{"value":_vm.level,"label":_vm.$t('bookCourse.step1.level.label'),"readonly":true}}),_c('text-field',{staticClass:"cell small-12",attrs:{"value":_vm.remark,"label":_vm.$t('bookCourse.step1.remark.label'),"placeholder":_vm.$t('bookCourse.step1.remark.placeholder')},on:{"update:value":function($event){_vm.remark=$event}}}),_c('text-field',{staticClass:"cell small-12",attrs:{"input-type":"tel","value":_vm.contactPhone,"label":_vm.$t('bookCourse.step1.contactPhone.label'),"placeholder":_vm.$t('bookCourse.step1.contactPhone.placeholder'),"error":_vm.contactPhoneError},on:{"update:value":function($event){_vm.contactPhone=$event}}}),_c('next-button',{staticClass:"cell small-12",on:{"next":_vm.onNext}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }