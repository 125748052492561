










import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class RatingScore extends Vue {
  public FULL_SCORE = 5;
  @Prop({ required: true }) public score!: number;

  get displayScore() {
    if (this.score) {
      return Number.isInteger(this.score)
        ? this.score.toFixed()
        : this.score.toFixed(1);
    }
    return "";
  }
}
