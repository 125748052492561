























import { Component, Prop, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import ExpandedSearchBarItem from "@/components/Search/ExpandedSearchBarItem.vue";
import { AsyncState, Skill } from "@/@types";

const Codes = namespace("codes");
@Component({
  components: {
    ExpandedSearchBarItem,
  },
})
export default class ExpandedSearchBar extends Vue {
  @Codes.State("skills") public skillsState!: AsyncState<Skill[]>;
  public selected = false;
  get skills() {
    return this.skillsState.data || [];
  }
  public mounted() {
    this.scrollToSelectedSkill();
  }
  public updated() {
    if (this.selected) {
      this.selected = false;
    } else {
      this.scrollToSelectedSkill();
    }
  }
  public scrollToSelectedSkill() {
    const { skill } = this.$route.params;
    const el = document.getElementById(`header__skill-${skill}`) as HTMLElement;
    if (el) {
      el.scrollIntoView({ block: "start", inline: "start" });
    }
  }
}
