









import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import RecordNotice from "@/components/Record/RecordNotice.vue";
import RecordList from "@/components/Record/RecordList.vue";
import ErrorView from "@/components/ErrorView.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  AsyncState,
  PagingResult,
  Booking,
  AsyncDataParam,
  PageTuning,
} from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";

const Codes = namespace("codes");
const Bookings = namespace("bookings");
const PAGE_SIZE = 20;
@Component({
  components: {
    Layout,
    Top,
    Bottom,
    RecordNotice,
    RecordList,
    ErrorView,
  },
})
export default class BookingRecordList extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  get isParamsValid() {
    return (
      (AsyncStates.statusOf(this.bookingsState) === AsyncStatus.LOADING ||
        this.bookingsState.data) &&
      (AsyncStates.statusOf(this.pageTuningState) === AsyncStatus.LOADING ||
        this.pageTuningState.data)
    );
  }
  get title() {
    return this.$t("sidemenu.bookings");
  }
  public static async asyncData({ store, route }: AsyncDataParam) {
    return await Promise.all([
      store.dispatch("codes/fetchPageTuning"),
      store.dispatch("bookings/listBookings", {
        offset: 0,
        limit: PAGE_SIZE,
      }),
    ]);
  }
  @Bookings.State("bookings")
  public bookingsState!: AsyncState<PagingResult<Booking[]>>;
  @Codes.State("pageTuning")
  public pageTuningState!: AsyncState<PageTuning>;
}
