












import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/FormFields/Layout.vue";
import TextInput from "@/components/FormFields/TextInput.vue";
@Component({
  components: { Layout, TextInput },
})
export default class TextField extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: "" }) public placeholder!: string;
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: "" }) public value!: string;
  @Prop({ default: "" }) public error!: string;
  @Prop({ default: "" }) public inputType!: string;
}
