
















import { Vue, Component, Prop } from "vue-property-decorator";
import { BookingCourse } from "@/@types";

@Component
export default class CheckInButton extends Vue {
  @Prop({ required: true }) public bookingCourse!: BookingCourse;

  public submitting = false;

  public async checkInCourseBooking() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    try {
      await this.$store.dispatch("bookings/checkInCourseBooking", {
        courseBookingId: this.bookingCourse.id,
      });
    } catch (e) {
      window.alert(this.$t("common.error.unknown"));
    } finally {
      this.submitting = false;
    }
  }
}
