























import { Component, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import PopularSkill from "@/components/Landing/PopularSkill.vue";
import { AsyncState, Skill } from "@/@types";

const Codes = namespace("codes");
@Component({
  components: {
    PopularSkill,
  },
})
export default class PopularSkills extends Vue {
  public MAX_NUM_OF_SKILLS = 7;
  @Codes.State("skills") public skillsState!: AsyncState<Skill[]>;

  get skills() {
    return this.skillsState.data || [];
  }
}
