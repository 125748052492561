




import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AsyncState, AsyncDataParam, Tuning } from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";
import Layout from "@/components/Layout.vue";
import ErrorView from "@/components/ErrorView.vue";
import * as Sentry from "@sentry/browser";

const BookTuningStore = namespace("bookTuning");
@Component({
  components: {
    Layout,
    ErrorView,
  },
})
export default class BookTuning extends Vue {
  public static async asyncData({ store, route }: AsyncDataParam) {
    await Promise.all([
      store.dispatch("codes/fetchRegions"),
      store.dispatch("codes/fetchTuningInstruments"),
      store.dispatch("bookTuning/fetchTunings"),
    ]);
  }

  @BookTuningStore.State("tunings")
  public tuningsState!: AsyncState<Tuning[]>;

  get isParamsValid() {
    return (
      AsyncStates.statusOf(this.tuningsState) === AsyncStatus.LOADING ||
      this.tuningsState.data
    );
  }

  public created() {
    const { error } = this.$route.query;
    if (!error) {
      this.$store.dispatch("bookTuning/setStepOne", null);
      this.$store.dispatch("bookTuning/setStepTwo", null);
      this.$store.dispatch("bookTuning/setStepThree", null);
    }
  }
}
