
















import { Component, Prop, Vue } from "vue-property-decorator";
import SelectInput from "@/components/FormFields/SelectInput.vue";

@Component({
  components: { SelectInput },
})
export default class MonthSelector extends Vue {
  @Prop({ default: [] }) public options!: Array<{
    value: string;
    label: string;
  }>;
  @Prop({ default: "" }) public selected!: string;
}
