
























































































































































import { Component, Vue } from "vue-property-decorator";
import { AsyncState, Skill, Instructor } from "@/@types";
import { namespace } from "vuex-class";
import InstructorDetailCard from "@/components/InstructorDetailModal/InstructorDetailCard.vue";

const Instructors = namespace("instructors");
const Codes = namespace("codes");

@Component({
  components: { InstructorDetailCard },
})
export default class InstructorDetail extends Vue {
  @Instructors.State("instructorDetail")
  public instructorState!: AsyncState<Instructor>;

  @Codes.Getter("findSkillByCode") public findSkillByCode!: (
    code: string
  ) => Skill | undefined;

  get instructor() {
    return this.instructorState.data;
  }

  get skill() {
    return this.findSkillByCode(this.$route.params.skill)!;
  }

  get languageList() {
    return (this.instructor ? this.instructor.languages : [])
      .map((e) => e.name[this.$i18n.locale])
      .join(this.$t("common.comma") as string);
  }

  get regionList() {
    return (this.instructor ? this.instructor.regions : [])
      .map((e) => e.name[this.$i18n.locale])
      .join(this.$t("common.comma") as string);
  }
}
