
































import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import PageTitle from "@/components/FormFields/PageTitle.vue";
import ConfirmInfo from "@/components/BookCourse/ConfirmInfo.vue";
import AliPayButton from "@/components/PaymentForm/AliPayButton.vue";
import ApplePayButton from "@/components/PaymentForm/ApplePayButton.vue";
import PaymentForm from "@/components/PaymentForm/PaymentForm.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  Course,
  BookCourseDone,
  BookCourseStepOne,
  BookCourseStepTwo,
  BookCourseStepThree,
  AsyncState,
} from "@/@types";

interface TokenEvent {
  token: any;
  name: string;
  email: string;
  successCallback?: () => void;
  failureCallback?: (error: Error) => void;
}
const BookCourse = namespace("bookCourse");
@Component({
  components: {
    PageTitle,
    ConfirmInfo,
    AliPayButton,
    ApplePayButton,
    PaymentForm,
  },
})
export default class StepThree extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @BookCourse.State("done") public done!: BookCourseDone;
  @BookCourse.State("course") public courseState!: AsyncState<Course>;
  @BookCourse.State("stepOne") public stepOne!: BookCourseStepOne;
  @BookCourse.State("stepTwo") public stepTwo!: BookCourseStepTwo;
  @BookCourse.State("stepThree") public stepThree!: BookCourseStepThree;

  get title() {
    return this.$t("bookCourse.step3.title");
  }

  get course(): Course | null {
    return this.courseState.data;
  }

  get price(): number | null {
    if (this.course) {
      const { duration } = this.$route.params;
      const c = this.course as any;
      return c[`${duration}MinsActualPrice`];
    }
    return null;
  }
  get aliPayReturnPath() {
    const { locale } = this.$i18n;
    const { slug, skill, level, duration } = this.$route.params;
    return `/${locale}/tutor/${slug}/${skill}/${level}/${duration}/book/alipay`;
  }

  public mounted() {
    if (!this.done.stepOne || !this.done.stepTwo) {
      this.$router.replace("1");
    }
  }
  public updated() {
    const { error } = this.$route.query;
    if (error) {
      window.onload = () => window.alert(this.$t("common.error.unknown"));
    }
  }
  public destroyed() {
    window.onload = null;
  }
  public async onSaveSource(source: any) {
    const { timeslots } = this.stepTwo;
    await this.$skygear.lambda("musicmap:create_stripe_source", {
      type: "COURSE",
      sourceId: source.id,
      clientSecret: source.client_secret,
      source,
      payload: {
        courseId: this.course!.id,
        duration: parseInt(this.$route.params.duration, 10),
        price: this.price,
        data: {
          ...this.stepOne,
          ...this.stepTwo,
          timeslots: timeslots.map((timeslot) => ({
            date: timeslot.date ? moment(timeslot.date).utc() : null,
            fromTime: timeslot.fromTime
              ? moment(timeslot.fromTime).utc()
              : null,
            toTime: timeslot.toTime ? moment(timeslot.toTime).utc() : null,
          })),
        },
      },
    });
    window.location.href = source.redirect.url;
  }

  public async onToken(event: TokenEvent) {
    this.$store.dispatch("bookCourse/setStepThree", {
      cardHolderName: event.name,
      email: event.email,
    });
    try {
      const response = await this.$store.dispatch("bookCourse/bookCourse", {
        duration: this.$route.params.duration,
        stripeToken: event.token.id,
      });
      if (event.successCallback) {
        event.successCallback();
      }
      await this.$store.dispatch("auth/setUser");
    } catch (error) {
      if (event.failureCallback) {
        event.failureCallback(error);
      }
    }
  }
  public onBack() {
    this.$router.push("2");
  }
}
