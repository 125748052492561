




import { Vue, Component, Prop } from "vue-property-decorator";
import { BookingStatus } from "@/@types";

@Component
export default class CourseRecordStatus extends Vue {
  @Prop({ required: true }) public status!: BookingStatus;

  get bookingStatusString() {
    return this.$t(`record.status.${this.status}`);
  }
}
