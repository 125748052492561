




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SuccessForm extends Vue {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: "" }) public description!: string;
}
