
import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
const env = typeof window === "undefined" ? "server" : "client";

@Component
export default class OGDescriptionMixin extends Vue {
  get fallbackOGDescription() {
    return this.$t("common.og.description");
  }
  public setOGDescriptionOnServerSide() {
    if (env === "server") {
      const self = this as any;
      self.$ssrContext.ogDescription =
        self.ogDescription || this.fallbackOGDescription;
    }
  }
  public created() {
    this.setOGDescriptionOnServerSide();
  }
  public setOGDescriptionOnClientSide() {
    if (env === "client") {
      const els = document.getElementsByTagName("meta");
      const el = Array.from(els).find(
        (el) => el.getAttribute("property") === "og:description"
      );
      if (el) {
        const self = this as any;
        el.setAttribute(
          "content",
          self.ogDescription || this.fallbackOGDescription
        );
      }
    }
  }
  public setDescriptionOnClientSide() {
    if (env === "client") {
      const els = document.getElementsByTagName("meta");
      const el = Array.from(els).find(
        (el) => el.getAttribute("name") === "description"
      );
      if (el) {
        const self = this as any;
        el.setAttribute(
          "content",
          self.ogDescription || this.fallbackOGDescription
        );
      }
    }
  }
  public mounted() {
    this.setOGDescriptionOnClientSide();
  }
  public updated() {
    this.setOGDescriptionOnClientSide();
    this.setDescriptionOnClientSide();
  }
}
