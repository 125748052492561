import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import {
  ActivityState,
  RootState,
  ListActivitiesParam,
  ListActivitiesResult,
  Activity,
} from "@/@types";
import { Container } from "skygear";
import { parseActivity } from "@/store/parsers";
import { AsyncStates, AsyncCompletion } from "./async";

const createState = (): ActivityState => {
  return {
    activityList: AsyncStates.default(),
    activityDetail: AsyncStates.default(),
  };
};

const createGetters = (): GetterTree<ActivityState, RootState> => {
  return {};
};

const createActions = (
  skygear: Container
): ActionTree<ActivityState, RootState> => {
  return {
    async listActivities({ state, commit }, params: ListActivitiesParam) {
      const { offset, limit } = params;
      let isAppending = false;
      const prevListResult = state.activityList.data;
      if (prevListResult && prevListResult.queryParams.type === params.type) {
        const {
          offset: oldOffset,
          limit: oldLimit,
        } = prevListResult.queryParams;
        if (limit !== oldLimit || offset + limit <= oldOffset + oldLimit) {
          // query with same params as current query result, reuse result
          return;
        } else {
          // query new page with same params, appending
          isAppending = true;
        }
      }

      commit("listActivitiesBegin", isAppending);
      const requestId = state.activityList.currentRequestId;

      let data: ListActivitiesResult | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:list_activities", {
          ...params,
          offset,
          limit,
        });
        const result = response.models.map(parseActivity);
        const hasMore = response.total > offset + limit;
        data = { value: result, queryParams: params, hasMore };
      } catch (e) {
        error = e;
      }
      commit("listActivitiesCompleted", { requestId, data, error });
    },
    async getActivity({ state, commit }, slug) {
      commit("getActivityBegin");
      const requestId = state.activityDetail.currentRequestId;
      let data: Activity | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:get_activity", {
          slug,
        });
        data = parseActivity(response);
      } catch (e) {
        error = e;
      }
      commit("getActivityCompleted", { requestId, data, error });
    },
  };
};

const createMutations = (): MutationTree<ActivityState> => {
  return {
    listActivitiesBegin(state: ActivityState, appending: boolean) {
      AsyncStates.begin(state.activityList, !appending);
    },
    listActivitiesCompleted(
      state: ActivityState,
      params: AsyncCompletion<ListActivitiesResult>
    ) {
      if (state.activityList.data && params.data) {
        params.data.value = [
          ...state.activityList.data.value,
          ...params.data.value,
        ];
      }
      AsyncStates.completed(state.activityList, params);
    },

    getActivityBegin(state: ActivityState) {
      AsyncStates.begin(state.activityDetail, true);
    },
    getActivityCompleted(
      state: ActivityState,
      params: AsyncCompletion<Activity>
    ) {
      AsyncStates.completed(state.activityDetail, params);
    },
  };
};

export const createActivityModule = (
  skygear: Container
): Module<ActivityState, RootState> => {
  return {
    namespaced: true,
    state: createState(),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  };
};
