import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import moment from "moment";
import {
  Tuning,
  RootState,
  BookTuningState,
  BookTuningStepOne,
  BookTuningStepTwo,
  BookTuningStepThree,
  BookTuningParam,
} from "@/@types";
import { Container } from "skygear";
import { parseTuning } from "./parsers";
import { AsyncStates, AsyncCompletion } from "./async";
import { sortTimeslots } from "@/helpers/timeslot";

export const defaultState = (): BookTuningState => ({
  tunings: AsyncStates.default(),
  stepOne: {
    tuningId: "",
    requireAccessaryReplacement: false,
    requireAccessaryReplacementRemark: "",
    requireRelocation: false,
    contactName: "",
    contactPhone: "",
  },
  stepTwo: {
    region: null,
    address: "",
    timeslots: [
      { date: null, fromTime: null, toTime: null },
      { date: null, fromTime: null, toTime: null },
      { date: null, fromTime: null, toTime: null },
    ],
    remark: "",
  },
  stepThree: {
    cardHolderName: "",
    email: "",
  },
  done: {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
  },
});

const createState = (): BookTuningState => {
  return defaultState();
};

const createGetters = (): GetterTree<BookTuningState, RootState> => {
  return {
    findTuningById(state: BookTuningState) {
      return (id: string) => {
        return (state.tunings.data || []).find((tuning) => tuning.id === id);
      };
    },
    findTuningByCode(state: BookTuningState) {
      return (tuningInstrumentCode: string, tuningPackageCode: string) => {
        return (state.tunings.data || []).find(
          (tuning) =>
            tuning.tuningInstrument.code === tuningInstrumentCode &&
            tuning.tuningPackage.code === tuningPackageCode
        );
      };
    },
  };
};

const createActions = (
  skygear: Container
): ActionTree<BookTuningState, RootState> => {
  return {
    async fetchTunings({ state, commit }) {
      commit("fetchTuningsBegin");
      const requestId = state.tunings.currentRequestId;
      let data: Tuning[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:list_tunings", {});
        data = response.map(parseTuning);
      } catch (e) {
        error = e;
      }
      commit("fetchTuningsCompleted", { requestId, data, error });
    },
    setStepOne({ commit, rootGetters }, params: BookTuningStepOne | null) {
      commit(
        "setStepOne",
        params === null
          ? {
              ...defaultState().stepOne,
              contactName: rootGetters["auth/name"],
              contactPhone: rootGetters["auth/phoneNumber"],
            }
          : params
      );
      commit("setDone", { stepOne: params !== null });
    },
    setStepTwo({ commit }, params: BookTuningStepTwo | null) {
      commit("setStepTwo", params === null ? defaultState().stepTwo : params);
      commit("setDone", { stepTwo: params !== null });
    },
    setStepThree({ commit, rootGetters }, params: BookTuningStepThree | null) {
      commit(
        "setStepThree",
        params === null
          ? {
              ...defaultState().stepThree,
              cardHolderName: rootGetters["auth/name"],
              email: rootGetters["auth/email"],
            }
          : params
      );
      commit("setDone", { stepThree: params !== null });
    },
    async bookTuning({ state, getters }, params: BookTuningParam) {
      const { stripeToken } = params;
      const { tuningId } = state.stepOne;
      const { timeslots } = state.stepTwo;
      const response = await skygear.lambda("musicmap:create_tuning_booking", {
        tuningId,
        price: getters.findTuningById(tuningId)!.actualPrice,
        stripeToken,
        email: state.stepThree.email,
        data: {
          ...state.stepOne,
          ...state.stepTwo,
          timeslots: sortTimeslots(timeslots).map((timeslot) => ({
            date: timeslot.date ? moment(timeslot.date).utc() : null,
            fromTime: timeslot.fromTime
              ? moment(timeslot.fromTime).utc()
              : null,
            toTime: timeslot.toTime ? moment(timeslot.toTime).utc() : null,
          })),
        },
      });
      return response;
    },
  };
};

const createMutations = (): MutationTree<BookTuningState> => {
  return {
    fetchTuningsBegin(state: BookTuningState) {
      AsyncStates.begin(state.tunings, false);
    },
    fetchTuningsCompleted(
      state: BookTuningState,
      params: AsyncCompletion<Tuning[]>
    ) {
      AsyncStates.completed(state.tunings, params);
    },
    setStepOne(state: BookTuningState, params: BookTuningStepOne) {
      state.stepOne = params;
    },
    setStepTwo(state: BookTuningState, params: BookTuningStepTwo) {
      state.stepTwo = params;
    },
    setStepThree(state: BookTuningState, params: BookTuningStepThree) {
      state.stepThree = params;
    },
    setDone(state: BookTuningState, params: { [key: string]: boolean }) {
      state.done = {
        ...state.done,
        ...params,
      };
    },
  };
};

export const createBookTuningModule = (
  skygear: Container
): Module<BookTuningState, RootState> => {
  return {
    namespaced: true,
    state: createState(),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  };
};
