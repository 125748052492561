


































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import I18nRouterLink from "@/components/I18nRouterLink.vue";

@Component({
  components: { I18nRouterLink },
})
export default class ActivitiesTabBar extends Vue {
  @Prop() public active!: undefined | "concert" | "workshop" | "contest";
}
