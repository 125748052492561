







































































import moment from "moment";
import { getMomentLocale } from "@/i18n";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingTuning, Payment } from "@/@types";

@Component
export default class TuningDetails extends Vue {
  @Prop({ required: true }) public bookingTuning!: BookingTuning;
  @Prop({ required: true }) public payment!: Payment;

  get confirmedDateString() {
    const { confirmedDatetime } = this.bookingTuning;
    if (!confirmedDatetime) {
      return this.$t("record.tuning.details.unconfirmed");
    }
    return moment(confirmedDatetime)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL");
  }

  get confirmedTimeString() {
    const { confirmedDatetime } = this.bookingTuning;
    if (!confirmedDatetime) {
      return this.$t("record.tuning.details.unconfirmed");
    }
    return moment(confirmedDatetime).format("h:mma");
  }

  get paymentDetailString() {
    const { amount, source } = this.payment;
    const bracketOpen = this.$t("common.bracket.open");
    const bracketClose = this.$t("common.bracket.close");
    let method = "";
    if (source.object === "card" && source.tokenization_method === null) {
      method = this.$t("common.paymentMethod.card").toString();
    } else if (
      source.object === "card" &&
      source.tokenization_method === "apple_pay"
    ) {
      method = this.$t("common.paymentMethod.applepay").toString();
    } else if (source.object === "source" && source.type === "alipay") {
      method = this.$t("common.paymentMethod.alipay").toString();
    }
    return `HK$ ${amount} ${bracketOpen}${method}${bracketClose}`;
  }

  get paymentDateString() {
    return moment(this.payment.createdAt)
      .locale(getMomentLocale(this.$i18n.locale))
      .format("LL");
  }
}
