






































import { Component, Prop, Vue } from "vue-property-decorator";

// vue-carousel uses global window on require
const VueCarousel =
  // tslint:disable-next-line:no-var-requires
  typeof window === "undefined" ? {} : require("vue-carousel");

@Component({
  components: VueCarousel
    ? { Carousel: VueCarousel.Carousel, Slide: VueCarousel.Slide }
    : {},
})
export default class SsrCarousel extends Vue {
  @Prop({ required: true }) public imageUrls!: string[];

  private isClient = false;
  private currentIndex = 0;

  public mounted() {
    this.isClient = true;
  }
}
