import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import {
  InstructorsState,
  RootState,
  Course,
  Instructor,
  InstructorSkill,
  QueryInstructorsParam,
  InstructorSkillWithOrder,
  QueryInstructorResult,
} from "@/@types";
import { Container } from "skygear";
import { parseInstructor, parseCourse } from "@/store/parsers";
import { AsyncStates, AsyncCompletion } from "./async";

const createState = (): InstructorsState => {
  return {
    instructorDetail: AsyncStates.default(),
    instructorCourseDetail: AsyncStates.default(),
    featuredInstructors: AsyncStates.default(),
    matchedInstructors: AsyncStates.default(),
  };
};

const createGetters = (): GetterTree<InstructorsState, RootState> => {
  return {
    instructorHasSkill(state: InstructorsState) {
      return (code: string) => {
        if (state.instructorDetail.data) {
          return !!state.instructorDetail.data.skills.find(
            (skill) => skill.skill.code === code
          );
        }
        return false;
      };
    },
    isSameMatchedInstructorsQueryParams(state: InstructorsState) {
      return (params: QueryInstructorsParam) => {
        if (!state.matchedInstructors.data) {
          return false;
        }
        const { offset, limit, ...queryParams } = params;
        const {
          offset: oldOffset,
          limit: oldLimit,
          ...oldQueryParams
        } = state.matchedInstructors.data.queryParams;
        const isSameParams =
          JSON.stringify(queryParams) === JSON.stringify(oldQueryParams);
        return isSameParams;
      };
    },
  };
};

const createActions = (
  skygear: Container
): ActionTree<InstructorsState, RootState> => {
  return {
    async getInstructorDetail({ state, commit }, params) {
      commit("fetchInstructorDetailBegin");
      const requestId = state.instructorDetail.currentRequestId;
      let data: Instructor | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:get_instructor",
          params
        );
        data = parseInstructor(response);
      } catch (e) {
        error = e;
      }
      commit("fetchInstructorDetailCompleted", { requestId, data, error });
    },
    async listInstructorCoursesForSkill({ state, commit }, params) {
      commit("fetchInstructorCourseDetailBegin");
      const requestId = state.instructorCourseDetail.currentRequestId;
      let data: Course[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:list_instructor_courses_for_skill",
          params
        );
        data = response.map(parseCourse);
      } catch (e) {
        error = e;
      }
      commit("fetchInstructorCourseDetailCompleted", {
        requestId,
        data,
        error,
      });
    },
    async fetchFeaturedInstructors({ state, commit }, params) {
      if (state.featuredInstructors.data) {
        // do not fetch again if already fetched
        return;
      }

      commit("fetchFeaturedInstructorsBegin");
      const requestId = state.featuredInstructors.currentRequestId;
      let data: InstructorSkillWithOrder[] | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda(
          "musicmap:list_featured_instructors",
          params
        );
        const instructors: InstructorSkill[] = response;
        data = instructors.map((value) => ({ value, order: Math.random() }));
      } catch (e) {
        error = e;
      }
      commit("fetchFeaturedInstructorsCompleted", { requestId, data, error });
    },
    invalidateFeaturedInstructors({ state, commit }) {
      commit("fetchFeaturedInstructorsCompleted", {
        requestId: state.featuredInstructors.currentRequestId,
        data: null,
        error: null,
      });
    },
    async queryInstructors(
      { state, commit, getters },
      params: QueryInstructorsParam
    ) {
      const { offset, limit } = params;
      const isSameParams = getters.isSameMatchedInstructorsQueryParams(params);
      let isAppending = false;
      if (isSameParams) {
        const {
          offset: oldOffset,
          limit: oldLimit,
        } = state.matchedInstructors.data!.queryParams;
        if (offset + limit <= oldOffset + oldLimit) {
          // query with same params as current query result, reuse result
          return;
        } else {
          // query new page with same params, appending
          isAppending = isSameParams;
        }
      }

      const seed = isAppending
        ? state.matchedInstructors.data!.seed
        : Math.random() * 2 - 1; // between -1 and 1
      commit("fetchMatchedInstructorsBegin", isAppending);
      const requestId = state.matchedInstructors.currentRequestId;

      let data: QueryInstructorResult | undefined;
      let error: Error | undefined;
      try {
        const response = await skygear.lambda("musicmap:query_instructors", {
          ...params,
          offset,
          limit,
          seed,
        });
        const result = response.models.map(parseInstructor);
        const hasMore = response.total > offset + limit;
        data = {
          value: result,
          queryParams: params,
          hasMore,
          seed,
        };
      } catch (e) {
        error = e;
      }
      commit("fetchMatchedInstructorsCompleted", { requestId, data, error });
    },
  };
};

const createMutations = (): MutationTree<InstructorsState> => {
  return {
    fetchInstructorDetailBegin(state: InstructorsState) {
      AsyncStates.begin(state.instructorDetail, true);
    },
    fetchInstructorDetailCompleted(
      state: InstructorsState,
      params: AsyncCompletion<Instructor>
    ) {
      AsyncStates.completed(state.instructorDetail, params);
    },

    fetchInstructorCourseDetailBegin(state: InstructorsState) {
      AsyncStates.begin(state.instructorCourseDetail, true);
    },
    fetchInstructorCourseDetailCompleted(
      state: InstructorsState,
      params: AsyncCompletion<Course[]>
    ) {
      AsyncStates.completed(state.instructorCourseDetail, params);
    },

    fetchFeaturedInstructorsBegin(state: InstructorsState) {
      AsyncStates.begin(state.featuredInstructors, true);
    },
    fetchFeaturedInstructorsCompleted(
      state: InstructorsState,
      params: AsyncCompletion<InstructorSkillWithOrder[]>
    ) {
      AsyncStates.completed(state.featuredInstructors, params);
    },

    fetchMatchedInstructorsBegin(state: InstructorsState, appending: boolean) {
      AsyncStates.begin(state.matchedInstructors, !appending);
    },
    fetchMatchedInstructorsCompleted(
      state: InstructorsState,
      params: AsyncCompletion<QueryInstructorResult>
    ) {
      if (state.matchedInstructors.data && params.data) {
        params.data.value = [
          ...state.matchedInstructors.data.value,
          ...params.data.value,
        ];
      }
      AsyncStates.completed(state.matchedInstructors, params);
    },
  };
};

export const createInstructorsModule = (
  skygear: Container
): Module<InstructorsState, RootState> => {
  return {
    namespaced: true,
    state: createState(),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  };
};
