










































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AsyncState, PagingResult, Booking, PageTuning } from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";
import LoadingIcon from "@/components/LoadingIcon.vue";
import CourseRecordCard from "@/components/Record/Course/CourseRecordCard.vue";
import TuningRecordCard from "@/components/Record/Tuning/TuningRecordCard.vue";
import SearchRouterLink from "@/components/Search/SearchRouterLink.vue";

const Codes = namespace("codes");
const Bookings = namespace("bookings");
const PAGE_SIZE = 20;

@Component({
  components: {
    LoadingIcon,
    CourseRecordCard,
    TuningRecordCard,
    SearchRouterLink,
  },
})
export default class RecordList extends Vue {
  @Bookings.State("bookings")
  public bookingsState!: AsyncState<PagingResult<Booking[]>>;
  @Codes.State("pageTuning")
  public pageTuningState!: AsyncState<PageTuning>;

  get result(): PagingResult<Booking[]> {
    return this.bookingsState.data || { value: [], hasMore: true };
  }

  get isLoading(): boolean {
    return (
      AsyncStates.statusOf(this.bookingsState) === AsyncStatus.LOADING ||
      AsyncStates.statusOf(this.pageTuningState) === AsyncStatus.LOADING
    );
  }

  get disableInfiniteScroll() {
    return this.isLoading || !this.result.hasMore;
  }

  public async listMoreBookings() {
    if (
      this.isLoading ||
      this.bookingsState.error ||
      this.pageTuningState.error
    ) {
      return;
    }
    await this.$store.dispatch("bookings/listBookings", {
      offset: this.result.value.length,
      limit: PAGE_SIZE,
    });
  }
}
