







import { Component, Vue } from "vue-property-decorator";
import I18nRouterLink from "@/components/I18nRouterLink.vue";

@Component({
  components: {
    I18nRouterLink,
  },
})
export default class LoginButton extends Vue {}
