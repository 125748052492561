




























import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Layout extends Vue {
  @Prop({ default: false }) public singleCell!: boolean;
}
