






import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Star extends Vue {
  @Prop({ default: "#000" }) public color!: string;
  @Prop({ default: 5 }) public size!: number;
  @Prop({ default: Math.PI / 2 }) public shift!: number;
  @Prop({ default: 0.5 }) public innerRatio!: number;

  public points() {
    if (!this.size) {
      return "";
    }
    const step = (2 * Math.PI) / this.size;
    /* Outer radius is fixed as 1 so that the shape fits in a unit circle,
    sharpness of the star is controlled by the inner radius, which is the inner
    ratio as outer radius is just 1. */
    const r = this.innerRatio;
    return Array(this.size)
      .fill(0)
      .map((_, index) => step * index + this.shift)
      .map(
        (value) => `
          ${Math.cos(value)},${-Math.sin(value)}
          ${r * Math.cos(value + step / 2)},${-r * Math.sin(value + step / 2)}
        `
      )
      .join(" ");
  }
}
