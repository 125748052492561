import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=df5228da&scoped=true&"
import script from "./Layout.vue?vue&type=script&lang=ts&"
export * from "./Layout.vue?vue&type=script&lang=ts&"
import style0 from "./Layout.vue?vue&type=style&index=0&id=df5228da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df5228da",
  null
  
)

export default component.exports