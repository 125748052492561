























import { Mixins, Component, Watch, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Route } from "vue-router";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import TabBar from "@/components/TabBar.vue";
import SearchBar from "@/components/Search/SearchBar.vue";
import MatchedInstructors from "@/components/Search/MatchedInstructors.vue";
import FilterModal from "@/components/Search/FilterModal.vue";
import Bottom from "@/components/Bottom.vue";
import ErrorView from "@/components/ErrorView.vue";
import Modal from "@/components/Modal.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";
import {
  AsyncState,
  AsyncDataParam,
  Instructor,
  Skill,
  PriceRange,
  PagingResult,
  QueryInstructorsParam,
} from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";

const Instructors = namespace("instructors");
const Codes = namespace("codes");

const TAB_BAR_HEIGHT = 82;
const SEARCH_BAR_EXPANDED_HEIGHT = 127;
const SEARCH_BAR_MINIFIED_HEIGHT = 40;
const PAGE_SIZE = 20;
const SCROLL_THRESHOLD = 10;

@Component({
  components: {
    Layout,
    Top,
    TabBar,
    SearchBar,
    MatchedInstructors,
    Bottom,
    FilterModal,
    ErrorView,
    Modal,
  },
})
export default class Search extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public static async asyncData({ store, route }: AsyncDataParam) {
    const priceRange = store.getters["codes/findPriceRangeByCode"](
      route.query["price-range"]
    );
    await Promise.all([
      store.dispatch("codes/fetchRegions"),
      store.dispatch("codes/fetchSkills"),

      store.dispatch("instructors/queryInstructors", {
        skill: route.params.skill,
        level: route.query.level || null,
        region: route.query.region || null,
        minPrice: priceRange ? priceRange.min : null,
        maxPrice: priceRange ? priceRange.max : null,
        limit: PAGE_SIZE,
        offset: 0,
      }),
    ]);
  }

  public contentPaddingTopDelta = 0;
  public showTabBar = true;
  public expandSearchBar = true;
  public stayMinified = false;
  public page = 0;
  public isFilterExpanded = false;

  @Codes.State("skills")
  public skillsState!: AsyncState<Skill[]>;
  @Codes.Getter("findSkillByCode")
  public findSkillByCode!: (code: string) => Skill | undefined;

  @Codes.Getter("findPriceRangeByCode") public findPriceRangeByCode!: (
    code: string
  ) => PriceRange | undefined;

  @Instructors.State("matchedInstructors")
  public matchState!: AsyncState<PagingResult<Instructor[]>>;

  @Watch("$route")
  public onRouteChange(route: Route) {
    this.page = 0;
    this.initializeTopSection();
  }

  public async loadMore() {
    const vm = this.$refs.content as Vue;
    await this.queryInstructors();
    if (
      vm &&
      vm.$el.clientHeight < (vm.$el.parentNode as HTMLElement).clientHeight
    ) {
      await new Promise<void>((resolve) => {
        window.setTimeout(() => resolve(this.queryInstructors()), 200);
      });
    }
  }

  get title() {
    const { locale } = this.$i18n;
    const skill = this.findSkillByCode(this.$route.params.skill);
    return this.$t("search.title", { skill: skill ? skill.name[locale] : "" });
  }

  get ogImage() {
    return require("@/assets/img/mm-landing-banner.png");
  }

  get isParamsValid() {
    if (AsyncStates.statusOf(this.skillsState) === AsyncStatus.LOADING) {
      return true;
    }
    return !!this.findSkillByCode(this.$route.params.skill);
  }

  public onSearchBarToggle() {
    this.expandSearchBar = !this.expandSearchBar;
    this.stayMinified = !this.expandSearchBar;
    this.contentPaddingTopDelta =
      (SEARCH_BAR_EXPANDED_HEIGHT - SEARCH_BAR_MINIFIED_HEIGHT) *
      (this.expandSearchBar ? 1 : -1);
  }

  public showFilter() {
    this.isFilterExpanded = true;
  }

  public async queryInstructors() {
    if (
      AsyncStates.statusOf(this.matchState) === AsyncStatus.LOADING ||
      (this.matchState.data && !this.matchState.data.hasMore) ||
      this.matchState.error
    ) {
      return;
    }
    this.page = this.page + 1;
    const priceRange = this.findPriceRangeByCode(
      this.$route.query["price-range"]
    );
    await this.$store.dispatch("instructors/queryInstructors", {
      skill: this.$route.params.skill,
      level: this.$route.query.level || null,
      region: this.$route.query.region || null,
      minPrice: priceRange ? priceRange.min : null,
      maxPrice: priceRange ? priceRange.max : null,
      limit: PAGE_SIZE,
      offset: this.page * PAGE_SIZE,
    });
  }
  public async mounted() {
    if (this.isParamsValid && !window.onscroll) {
      this.initializeTopSection();
      window.onscroll = (() => {
        let oldOffset = window.pageYOffset;
        return () => {
          const newOffset = window.pageYOffset;
          const atScreenBottom =
            window.innerHeight + newOffset >= document.body.offsetHeight;
          const passThreshold =
            Math.abs(newOffset - oldOffset) >= SCROLL_THRESHOLD;
          const occupiedTopBars =
            newOffset >=
            TAB_BAR_HEIGHT +
              SEARCH_BAR_EXPANDED_HEIGHT -
              SEARCH_BAR_MINIFIED_HEIGHT;
          if (!atScreenBottom && passThreshold && occupiedTopBars) {
            this.showTabBar = newOffset < oldOffset;
            this.expandSearchBar = !this.stayMinified && this.showTabBar;
            oldOffset = newOffset;
          }
        };
      })();
    }
    if (this.page === 0) {
      await this.loadMore();
    }
  }

  public initializeTopSection() {
    this.showTabBar = true;
    this.expandSearchBar = true;
  }

  public destroyed() {
    window.onscroll = null;
  }
}
