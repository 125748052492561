











import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Layout from "@/components/FormFields/Layout.vue";
import RegionInput from "@/components/FormFields/RegionInput.vue";
import { AsyncState, Region } from "@/@types";

const Codes = namespace("codes");

@Component({
  components: { Layout, RegionInput },
})
export default class RegionField extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: "" }) public selected!: string;
  @Prop({ default: "" }) public error!: string;
  @Prop({ required: true }) public options!: Region[];
}
