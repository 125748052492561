
















import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/FormFields/Layout.vue";
@Component({
  components: { Layout },
})
export default class RadioField extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: [] }) public options!: Array<{
    value: string;
    label: string;
    hint?: string;
  }>;
  @Prop({ default: "" }) public selected!: string;
}
