export default {
  common: {
    comma: "、",
    slash: "／",
    hyphen: "－",
    bracket: {
      open: "（",
      close: "）",
    },
    area: {
      hk: "港岛区",
      kl: "九龙区",
      nt: "新界区",
    },
    month: {
      1: "一月",
      2: "二月",
      3: "三月",
      4: "四月",
      5: "五月",
      6: "六月",
      7: "七月",
      8: "八月",
      9: "九月",
      10: "十月",
      11: "十一月",
      12: "十二月",
    },
    userIs: {
      STUDENT: "学生本人",
      PARENT: "家长",
    },
    studentGender: {
      MALE: "男生",
      FEMALE: "女生",
    },
    studentGenderShort: {
      MALE: "男",
      FEMALE: "女",
    },
    venueType: {
      STUDENT: "学生家中",
      INSTRUCTOR: "导师工作室／家中／邻近琴行＊",
    },
    requireAccessaryReplacement: {
      TRUE: "需要（另外收费）",
      FALSE: "不需要",
    },
    requireRelocation: {
      TRUE: "需要（另外收费）",
      FALSE: "不需要",
    },
    paymentMethod: {
      card: "信用卡",
      applepay: "苹果支付",
      alipay: "支付宝",
    },
    og: {
      description:
        "以音乐谱出地图，伴随一生的旅程，寻找音乐生命中的快乐。当你聆听，可耳闻喜乐。当你演奏，可触摸音乐。寻乐MusicMap就是为了与你乐在此乐，让音乐伴随你的左右。",
    },
    error: {
      unknown: "对不起，出了些故障，请稍后再尝试。",
      userDisabled: "帐号被封锁，请联络管理员。",
      transactionDeclined: "交易被拒，请使用另一张信用卡或其他付款方式。",
    },
  },
  formFields: {
    selectField: {
      emptyOption: {
        label: "请选择",
      },
    },
    timeslotDateInput: {
      placeholder: "选择日期",
    },
    timeslotTimeInput: {
      emptyOption: {
        label: "选择时间",
      },
    },
    nextButton: {
      label: "下一步",
    },
  },
  landing: {
    title: "寻找香港音乐导师、钢琴调音、音乐活动",
    banner: {
      tagline1: "寻找香港音乐导师、钢琴调音、音乐活动",
      tagline2: "音乐导师",
    },
    popularSkills: {
      title: "热门乐器",
      moreSkill: "更多",
    },
    featuredInstructors: {
      title: "推荐音乐导师",
    },
    latestActivities: {
      title: "最新音乐活动/音乐比赛",
    },
  },
  login: {
    loginForm: {
      title: "开始使用Musicmap",
      description: "请选择以下其中一个方法连接您的账号",
      button: {
        fb: "以Facebook登入",
        gl: "以Google登入",
      },
    },
    successForm: {
      title: "注册成功",
      description: "您现在可以预约我们的专业音乐导师和调音服务。",
      button: {
        search: "立即搜寻",
      },
    },
  },
  search: {
    title: "学{skill}香港 ｜学{skill}班｜成人儿童幼儿学{skill}｜{skill}课程",
    minifiedSearchBar: {
      text: "{skill} - 寻找导师",
    },
    noResult: {
      instructors: "暂时未有导师",
      activities: "暂时未有活动",
    },
    filterModal: {
      all: "全部",
      region: "上课地区",
      price: "价钱",
      priceRange: {
        "0": "150-250",
        "1": "251-400",
        "2": "400以上",
      },
      level: "级别",
      reset: "重设所有筛选项目",
      search: "搜寻",
    },
  },
  activities: {
    title: "活动／比赛 - {type}",
    tabbar: {
      all: "所有活动",
      concert: "音乐会",
      workshop: "工作坊",
      contest: "比赛",
    },
    monthSelector: {
      emptyOptionLabel: "请选择月份",
      optionLabel: "{month}份活动",
    },
  },
  instructorCard: {
    ratingScore: {
      title: "评分",
    },
  },
  instructorDetailModal: {
    bio: "自我介绍理念",
    education: "学历",
    awards: "奖项与成就",
    performanceExperiences: "演出经验",
    teachingExperiences: "教学经验",
    languages: "上课语言",
    regions: "上课地区",
    selectLevel: "选择级别",
    bookCourse: "预约上课",
    courseName: "{level}（{duration}分钟）",
  },
  header: {
    auth: {
      login: "登入",
      register: "注册",
    },
  },
  tabbar: {
    search: "寻找导师",
    activities: "活动／比赛",
    tuning: "预约调音",
  },
  footer: {
    copyright: "© 寻乐有限公司 {year}",
    aboutUs: "关于我们",
    howWeWork: "如何运作",
    contactUs: "联系我们",
    terms: "服务条款及私隐声明",
  },
  bookCourse: {
    step1: {
      title: "1 学生资料",
      userIs: {
        label: "你是...",
      },
      studentName: {
        label: "学生姓名",
        placeholder: "请输入学生姓名",
        errors: {
          required: "请输入学生姓名",
        },
      },
      studentGender: {
        label: "学生性别",
      },
      studentAgeRange: {
        label: "学生年龄",
        errors: {
          required: "请选择学生年龄",
        },
      },
      level: {
        label: "学习级别",
        value: "{name}（{duration}分钟）",
      },
      remark: {
        label: "备注（如有）",
        placeholder: "例如：学生特殊需要、学习偏好等",
      },
      contactPhone: {
        label: "联络电话",
        placeholder: "请输入联络电话",
        errors: {
          required: "请输入联络电话",
          format: "请输入香港电话号码",
        },
      },
    },
    step2: {
      title: "2 上课地区及时间",
      venueType: {
        label: "上课地区",
        errors: {
          required: "请选择上课地区",
        },
      },
      studentAddress: {
        label: "学生地址",
        placeholder: "请输入学生地址",
        errors: {
          regionRequired: "请选择学生地区",
          addressRequired: "请输入学生地址",
        },
      },
      instructorAddress: {
        label: "导师地区",
        errors: {
          required: "请输入导师地区",
        },
        remark1: "我们将会在成功预约后提供详细地址。",
        remark2: "＊租用邻近琴行需另外收费（每小时约$40-$120元）",
      },
      timeslot: {
        intro: "请提供三个您方便试堂的时段，导师将会尽量配合您的意愿安排试堂：",
        label: "时段",
        errors: {
          required: "请提供试堂时段",
          duplicated: "试堂时段重覆",
        },
      },
    },
    step3: {
      title: "3 确认及付款",
      instructor: {
        label: "导师",
        value: "{name}（{skill}）",
      },
      student: {
        label: "学生",
        value: "{name}（{gender}，{ageRange}）",
      },
      level: {
        label: "学习级别",
        value: "{name}（{duration}分钟）",
      },
      contactPhone: {
        label: "联络电话",
      },
      venueType: {
        label: "上课地区",
      },
      studentAddress: {
        label: "学生住址",
      },
      instructorAddress: {
        label: "导师地区",
      },
      timeslot: {
        label: "选择时间",
      },
      price: {
        label: "首堂预缴费用",
      },
      remark1: "我们将在您预备首堂费用后，为您联络导师安排上课。",
      remark2:
        "如因导师未能安排试堂，学费不会退回，可另选其他导师（如另作安排的导师学费超过原本所选导师学费，需补回差额）",
      paymentMethods: "请选择付款方式：",
    },
    alipay: {
      title: "处理中...",
    },
    success: {
      title: "预约成功",
      description:
        "我们已收到阁下的资料，<br />与导师接洽后将尽快与你确认预约。",
      button: {
        label: "查看我的预约记录",
        whatsapp: "联络Music Map",
      },
    },
  },
  bookTuning: {
    intro: {
      charge: "收费",
      button: "预约调音",
    },
    step1: {
      title: "1 基本资料",
      instrument: {
        label: "调音乐器",
      },
      requireAccessaryReplacement: {
        label: "是否需要更换配件",
      },
      requireAccessaryReplacementRemark: {
        placeholder: "请注明所需配件",
        errors: {
          required: "请注明所需配件",
        },
      },
      requireRelocation: {
        label: "是否需要搬琴",
      },
      contactName: {
        label: "联络人",
        placeholder: "请输入联络人姓名",
        errors: {
          required: "请输入联络人姓名",
        },
      },
      contactPhone: {
        label: "联络电话",
        placeholder: "请输入联络电话",
        errors: {
          required: "请输入联络电话",
          format: "请输入香港电话号码",
        },
      },
      bookingSelector: {
        selectLabel: "选择套票",
        bookNowLabel: "预约调音",
      },
    },
    step2: {
      title: "2 地址及时间",
      address: {
        label: "地址",
        placeholder: "请输入地址",
        errors: {
          regionRequired: "请输入地区",
          addressRequired: "请输入地址",
        },
      },
      timeslot: {
        intro:
          "请提供三个最少三天后您想进行调音的时段（约为1小时），我们将按照您所选的时段提供调音师。",
        label: "时段",
        errors: {
          required: "請提供調音時段",
          duplicated: "調音時段重覆",
        },
      },
      remark: {
        label: "备注（如有）",
        placeholder: "例如：乐器特殊需要等",
      },
    },
    step3: {
      title: "3 确认及付款",
      instrument: {
        label: "调音乐器",
      },
      requireAccessaryReplacement: {
        yesLabel: "需要更换配件",
        noLabel: "不需要更换配件",
      },
      requireRelocation: {
        yesLabel: "需要搬琴",
        noLabel: "不需要搬琴",
      },
      contactName: {
        label: "联络人",
      },
      contactPhone: {
        label: "联络电话",
      },
      address: {
        label: "地址",
      },
      timeslot: {
        label: "选择时间",
      },
      price: {
        label: "预约费用",
      },
      paymentMethods: "请选择付款方式：",
    },
    alipay: {
      title: "处理中...",
    },
    success: {
      title: "预约成功",
      description:
        "我们已收到阁下的资料，<br />将为你安排适合的调音师后与你确认预约。",
      button: {
        label: "查看我的预约记录",
        whatsapp: "联络Music Map",
      },
    },
  },
  sidemenu: {
    search: "寻找导师",
    events: "活动／比赛",
    tuning: "预约调音",
    bookings: "我的预约记录",
    account: "更改帐户资料",
    aboutUs: "关于MusicMap",
    contactUs: "联系我们",
    howWeWork: "如何运作",
    logout: "登出",
  },
  applePay: {
    total: {
      label: "费用",
    },
  },
  alipay: {
    error: {
      CANCELED: "付款已取消。",
      FAILED: "付款失败，请稍后再尝试。",
    },
  },
  paymentForm: {
    title: "信用卡支付",
    cardHolderName: {
      label: "持卡人姓名",
      placeholder: "请输入持卡人姓名",
      errors: {
        required: "請輸入持卡人姓名",
      },
    },
    cardNumber: {
      label: "卡号",
      placeholder: "请输入卡号",
      errors: {
        required: "请输入卡号",
        format: "卡号不正确",
      },
    },
    expiryDate: {
      label: "到期日期",
      errors: {
        required: "请输入",
        format: "不正确",
      },
    },
    cvc: {
      label: "CVC",
      errors: {
        required: "请输入",
        format: "不正确",
      },
    },
    email: {
      label: "电邮地址",
      placeholder: "请输入电邮地址",
      hint: "收据将发送至电邮地址",
      errors: {
        required: "请输入电邮地址",
        format: "电邮地址不正确",
      },
    },
    submitButton: {
      label: "确认及付款",
    },
  },
  errorView: {
    message: "找不到页面或系统错误",
  },
  record: {
    empty: "你未有任何预约",
    button: {
      label: "立即搜寻",
    },
    notice:
      "预约一旦确认后，将不能取消试堂<br />如需更改时间，请于试堂日期三天前向导师更改",
    status: {
      PENDING: "处理中",
      CONFIRMED: "已确认",
      CANCELLED: "已取消",
      PUNCHEDIN: "已签到",
    },
    course: {
      details: {
        instructor: "导师",
        student: "学生",
        level: "学习级别",
        venue: "上课地区",
        time: "上课时间",
        payment: "已付款",
        unconfirmed: "待导师确认",
      },
      checkin: "签到",
      rating: {
        title: "评分",
        comment: {
          title: "评语",
          placeholder: "评语 (选填)",
        },
        submit: "送出",
      },
    },
    tuning: {
      title: "调音",
      details: {
        requireAccessaryReplacement: {
          yesLabel: "需要更换配件",
          noLabel: "不需要更换配件",
        },
        requireRelocation: {
          yesLabel: "需要搬琴",
          noLabel: "不需要搬琴",
        },
        contactName: "联络人",
        contactPhone: "联络电话",
        address: "地址",
        timeslot: "预约时段",
        unconfirmed: "待确认",
        date: "预约日期",
        time: "预约时间",
        payment: "已付款",
      },
    },
  },
  account: {
    name: {
      label: "姓名",
      placeholder: "请输入姓名",
      errors: {
        required: "请输入姓名",
      },
    },
    phone: {
      label: "电话",
      placeholder: "请输入电话",
      errors: {
        format: "请输入香港电话号码",
      },
    },
    email: {
      label: "电邮地址",
    },
    link: {
      title: "帐号连结",
      linked: "已连结",
      nolink: "未连结",
    },
    submit: "储存变更",
    success: {
      description: "帐户资料已更新",
      okay: "确定",
    },
  },
  activityDetail: {
    performanceUnit: "表演单位",
    date: "日期",
    time: "时间",
    address: "地点",
    fee: "费用",
    link: "浏览详情",
  },
  aboutUs: {
    title: "关于我们",
    content: `
      <p>以音乐谱出地图，伴随一生的旅程，寻找音乐生命中的快乐。</p>
      <p>当你聆听，可耳闻喜乐。</p>
      <p>当你演奏，可触摸音乐。</p>
      <p>寻乐MusicMap就是为了与你乐在此乐，让音乐伴随你的左右。</p>
      <h3>使命</h3>
      <p>整合不同音乐机构和专业人士，提供各方面的音乐活动资讯和服务，培养和加强音乐的修养和素质。</p>
      <h3>为家长/学生</h3>
      <p>提供专业和多元化的音乐服务，音乐活动资讯，培养对音乐的兴趣。</p>
      <h3>导师</h3>
      <p>能将知识和技术传授给予学生，透过音乐来帮助学生陶冶性情，和良好的品格。 </p>
      <h3>与音乐机构</h3>
      <p>音乐会、工作坊、活动班、比赛；汇聚不同的机构共建在「地图」上，在不同的地区推动和发展音乐。 </p>
      <h3>愿景</h3>
      <p>在香港这美丽的土地上，传承音乐的知识，技巧和分享；在音乐的路上，学习到音乐和快乐，以音乐伴随人生的每一段旅程。 </p>
    `,
  },
  howWeWork: {
    title: "如何使用／Q&A",
    content: `
      <ol>
        <li>登记成为会员，随时搜寻邻近地区的音乐导师，音乐活动和预约调音服务</li>
        <li>选择所想乐器，音乐活动，所有资料均一目了然</li>
        <li>提供三个预约上课时间，并缴交一堂的费用</li>
        <li>老师时间确认后，预约完成</li>
      </ol>
      <h3>MusicMap 是什么？</h3>
      <p>MusicMap 网站让您搜寻香港各式各样的音乐活动，按照您所选的地区，您能预约各种不同乐器导师、钢琴调音和音乐活动。</p>
      <h3>MusicMap 的导师资历</h3>
      <p>每一名导师均经过资历认证，才能成为 MusicMap 的导师。当中的导师不乏已考获演奏级，或是现就读于／毕业于香港或海外的音乐系。</p>
      <h3>试堂需要收费吗？</h3>
      <p>我们将预约的第一堂称为试堂，需要于预约时缴付一堂费用。试堂收费的本意之一是认为导师需要付出时间准备教材和心力教授。二是希望学生和导师双方试一堂后，了解双方的教学方针或教法会否合适，希望您能于 MusicMap 中找到最合适的老师。</p>
      <h3>预约试堂后更改课堂时间安排</h3>
      <p>如预约试堂后需更改试堂时间，须于试堂日子不少于三天前向导师提出更改，但不接受即日请假及单方面取消，若因天气（八号风球、十号风球、黑色暴雨）和能够出示试堂日子的医生纸证明，则可获豁免，并须于 14 天内向导师另约试堂日期和时间。若付款后两天内未获本公司的任何通知，您可致电／Whatsapp +85256062052 或发送电邮至<a href="mailto:info@musicmap.hk">info@musicmap.hk</a>查询。</p>
      <h3>如想预约导师，有哪些付款方式及有收据吗？</h3>
      <p>付款方式可透过 Apple Pay/Google Pay、支付宝、VISA 和 MasterCard 支付。不论预约了导师或钢琴调音服务，在确认及付款的页面上，必需填写 Email，在付款后，收据将发送至您所填写的 Email。</p>
      <h3>付款过程安全吗？</h3>
      <p>MusicMap 的付款过程由具 PCI Compliance 认证的一级服务供应商 Stripe 所代理，并以严格的行业标准来执行付款防护措施。顾客直接在「确认及付款」的页面输入信用卡资料结帐后，通过 Stripe 的系统，所有敏感的信用卡资料在最安全的方式下处理，过程简便快捷。欲了解更多 Stripe 的安全准则，可浏览：<a href="https://stripe.com/docs/security">https://stripe.com/docs/security</a></p>
      <h3>有什么情况可申请退款？</h3>
      <p>如因导师未能安排试堂，费用将会在 14 天日内全数退还。</p>
      <h3>签到及评价系统</h3>
      <p>签到系统于试堂当天便可使用，可作当天上课凭证，签到后可给予评价，以供其他使用者作为参考。</p>
    `,
  },
  contactUs: {
    title: "联系我们",
    content: `
      <p></p>
      <p>联络电话／WhatsApp：+85256062052</p>
      <p>地址：尖沙咀么地道, G/F,Shop 46W丽东大厦</p>
      <p>一般资料查询：<a href="mailto:info@musicmap.hk">info@musicmap.hk</a></p>
      <p>活动／合作查询：<a href="mailto:marketing@musicmap.hk">marketing@musicmap.hk</a></p>
      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSf7BycSJexQDkYEynimeRLuXVYLbQta8snpgP8gDJ1cZhusLQ/viewform">刊登活动表格</a></p>
      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSdwNjsiqfYZiAvosJ1xAIOxUJIQKqfvpJeLdR3GlVpeWK_dmA/viewform?fbclid=IwAR2u9qgdiRLjQALOKkoyfPZkAJucRmO1r2XFRGRrpRtE-CGUVkB5aJsrIQw">加入我们（成为导师）</a></p>
      <p>本网站之节目资讯来源包括由主办机构／艺术家提供、节目宣传单张、社交网络平台等</p>
      <p>如不欲有关节目资讯在本网站显示，请电邮 <a href="mailto:info@musicmap.hk">info@musicmap.hk</a>告知</p>
    `,
  },
  termsAndConditions: {
    title: "服务条款及私隐声明",
    serviceTerms: {
      title: "服务条款",
      content: `
        <p>生效日期: 2018年 12月 21日</p>
        <p>寻乐有限公司（以下简称 寻乐）是依据本服务条款提供本网站各项服务。当您注册完成或开始使⽤本服务时，即表示您已阅读、了解并同意接受本服务条款之所有内容。如果您不同意本服务条款的内容，或者您所属的国家或地域排除本服务条款内容之全部或部分时，您应立即停止使用本服务。本网站有权于任何时间修改或变更本服务条款之内容，并公告于本网站上，请您随时注意该等修改或变更。若您于任何修改或变更后继续使⽤本服务， 则视为您已阅读、了解并同意接受该等修改或变更。</p>
        <p>若您为未满⼗八岁之未成年⼈，则应请您的⽗母或监护人阅读、了解并同意本服务条款之所有内容及其后之修改变更，方得使⽤本服务。当您使用本服务时，即推定您的⽗⺟或监护⼈已阅读、了解并同意接受本服务条款之所有内容及其后之修改变更。</p>
        <p>完成登记后即代表完全明白有关内容，并会遵守所有条款和规则之约束。</p>
        <ol>
          <li>会员个⼈资料及帐户管理
            <ol>
              <li>在您使用网上会员服务之前，请先注册成为寻乐网上会员，无须支付任何会员费用；在完成注册后，可使用网上会员服务及本网站提供相关之活动。如所提供的资料有变动，请即时更新，以保障您的权益。</li>
              <li>请您妥善保管您的电邮地址、 帐户密码及个⼈资料，不应将任何该等资料提供给任何⼈使⽤，以保障您的资料安全。如您发现您的资料遭他人非法使⽤，应立即通知本公司。如您的资料遭黑客取得或因个⼈的保管疏忽导致您的资料遭他⼈非法使用，本公司概不承担任何责任。</li>
            </ol>
          </li>
          <li>特别授权事项
            <ol>
              <li>因使⽤本网站的服务所提供之网路交易或活动，须透过导师、服务提供者及相关之机构等來聯络用户，因此，会员同意并授权 寻乐 来得视该次网路交易或活动之需求及⽬的，将由会员所提供且为配送所必要之个人资料（包括但不限于⽤⼾姓名、上课地址、联络电话等），提供予导师、服务提供者及相关之机构，以利完成该次服务。</li>
            </ol>
          </li>
          <li>评分系统
            <ol>
              <li>当会员撰写「评分」并上传送出，或开始使用本项服务功能时，即表示已阅读、了解并同意接受本项服务功能条款约定之所有内容，并完全接受本项服务功能现有与未来衍⽣的项⽬及内容。</li>
              <li>本网站得依「评分」内容，⾃行决定这篇「评分」是否被刊登或删除。</li>
              <li>对于会员上载、传送、输入或提供之资料，会员同意并授权本网站得于合理之范围内蒐集、处理、保存、传递及使用该等资料，以提供使用者其他资讯或服务、或作成会员统计资料、或进⾏关于网路行为之调查或研究，或为任何之合法使⽤。</li>
              <li>任何资料一经会员上载、传送、输入或提供⾄本网站时，视为会员已允许并授权本网站得无条件使⽤、修改、重制、公开播送、改作、散布、发⾏、公开发表该等资料，并得将前述权利转授权他⼈，会员对此绝无异议。</li>
              <li>会员并授权本网站，得以依 业务需求，自⾏决定将「评分」之全部或部分内容，转载刊登于他处。</li>
            </ol>
          </li>
          <li>第三⽅方网站；附加产品及服务
            <ol>
              <li>在有关服务中，您可能会遇⾒⾄第三⽅网站的连结或提及第三⽅网站之处（「已连结网站」）。寻樂 并未就任何已连结网站或者任何已连结网站上所载的资讯、产品或服务进⾏背书或担保。已连结网站受其⾃⾝的使⽤条款及私隐政策所管限。您可能需要使⽤或获得其他产品或服务才能对有关服务进⾏使⽤，例如，⾏动设备、互联网存取及资料连接。您必须单独获得或使⽤该等产品或服务，并且⽀付所有相关费⽤（包括⽤于互联网存取或其他资料传输）。</li>
            </ol>
          </li>
          <li>其他事项
            <ol>
              <li>用⼾不得：
                <ol>
                  <li>使⽤任何装置、软件或程式⼲预或企图⼲预此网站之正常运作或任何在此网站进⾏的交易；</li>
                  <li>进⾏任何令此网站的基础设施承受不合理或不合比例的巨⼤负荷的活动；</li>
                  <li>把其密码向第三者透露或与第三者使用同⼀密码或将其密码⽤作未经许可的用途。</li>
                  <li>不得滥⽤本网站，蓄意引入病毒、⽊⾺程式、蠕虫病毒及逻辑炸弹，或任何其他恶意或技术上有害的素材，且不得以任何⽅式（包括但不限于透过阻断服务攻击或分散式阻断服务攻击）攻击本网站。</li>
                </ol>
              </li>
              <li>当你表示同意及遵守这份合约，这代表你能够理解这份合约的内容及有正常的判断⼒去作出决定。若果你不同意本公司之守则，请不要登记使⽤该项服务。本站有权停⽌提供服务给任何会员。 </li>
            </ol>
          </li>
          <li>免责声明及责任限制
            <ol>
              <li>鉴于科技及在线环境属不可预测，本公司并不保证（不论明示或暗示）本网站的功能、运作或连接能⼒会不受⼲预及无误，亦不保证当中的缺陷会得到修正，或本网站或可供本网站使⽤的伺服器将无病毒、⽊⾺程式、蠕虫程式或其他有害成份。您有责任确保您备有合适的设备以正常浏览及使⽤本网站，并排除任何可能损害您电脑的事项。本公司不会就由您使⽤或未能浏览本网站及／或与之有关的任何事项承担责任。</li>
              <li>本网站及其内容按「现状」提供，风险须由您自⾏承担，且无任何形式的保证。在适用法律法规所允许的最⼤程度下，本公司不会就本网站及当中所载资讯概不作出任何形式的保证（不论明示或暗示）。</li>
              <li>本公司不会就任何因天灾、火灾、洪涝、意外、暴乱、战争、恐怖袭击、政府⼲预、禁运、示威、劳资纠纷、设备故障（包括但不限于互联网系统故障)，或任何超出本公司合理控制的原因，⽽直接或间接引致本公司未能、延误及违反本公司的责任及义务而承担任何责任。</li>
              <li>下列情形发⽣时，本网站有权可以停⽌、中断提供本服务：
                <ol>
                  <li>对本服务相关软硬体设备进⾏更换、升级、保养或施⼯时。</li>
                  <li>发生突发性之电子通信设备故障时。</li>
                  <li>天灾或其他不可抗⼒之因素致使本网站无法提供服务时。</li>
                </ol>
              </li>
              <li>不担保⽤⼾、导师、服务提供者⼈⽣安全<br />寻樂 明确声明⽤户、导师、服务提供者的⼈⽣安全或其他个⼈利益得失，亦非 寻乐 控制范围之内。 寻乐 概不接纳或承担有关导师和家长或学生之间之任何法律责任。</li>
              <li>免除 寻乐 的法律责任<br />若家长或学⽣与导师、服务提供者之间出现任何纠纷或因使⽤服务⽽引起任何纠纷，与该等纠纷有关或因而引起的所有责任、索偿、索求、赔偿⾦（实质或间接），不论属何性质，已知及未知、怀疑与否、已公开及未公开者， 寻乐 ⼀概毋须负上有关法律责任。</li>
            </ol>
          </li>
          <li>私隐政策
            <ol>
              <li>本公司尊重您的私隐，并致⼒于保护您的个⼈资料。详情请参阅本网站的私隐政策。该政策为本条款的组成部分。</li>
            </ol>
          </li>
          <li>智慧财产权的保护
            <ol>
              <li>本网站所使⽤之软体、程式及网站上所有内容，包括但不限于著作、图⽚、档案、资讯、资料、网站架构、网⾴设计，均由本网站或其他权利⼈依法拥有其智慧财产权，包括但不限于商标权、专利权、著作权等。</li>
              <li>任何⼈不得迳⾏使⽤、修改、重制、公开播送、改作、散布、发⾏、公开发表、进⾏还原⼯程、解编或反向组译。如欲引⽤或转载前述之软体、程式或网站内容，必须依法取得本网站或其他权利⼈的事前书⾯同意。如有违反之情事，您应对本网站或其他权利⼈负损害赔偿责任（包括但不限于诉讼费⽤及律师费⽤等）。</il>
            </ol>
          </li>
          <li>修改权
            <ol>
              <li>当您开始使⽤本服务时，即表示您已充分阅读、了解与同意接受本条款之内容。本公司有权于任何时间修改与变更本条款之内容，并将不个别通知会员，建议您定期查阅本服务条款。如您于本条款修改与变更后仍继续使⽤本服务，则视为您已阅读、了解与同意接受本条款修改或变更。</li>
              <li>本公司有权暂时或永久修改或中⽌提供本服务给您，您不得因此要求任何赔偿。</li>
            </ol>
          </li>
          <li>查询、意⾒
            <ol>
              <li>倘您对本网站有任何查询、意⾒，请电邮⾄info@musicmap.hk。本公司会在可⾏情况下尽量即时回应您的每项查询，但本公司不会就任何延误回应或不回应承担责任及／或法律律责任。</li>
            </ol>
          </li>
          <li>遵守法律
            <ol>
              <li>所有⽤⼾在使⽤此网站服务时，须遵守所有适⽤法律、规程、 条例和守则。</li>
              <li>条款的独立性<br />倘根据任何具司法管辖权的法院所实际引⽤的适⽤法例，此合约中的任何条款属违法或无法实施，此等条款将与合约分开及视作无效论， ⽽绝不影响此合约的其余条款。然⽽，在有关法律容许的情况下，若有关法律条⽂可获宽免，各⽅须同意其获宽免，以便此合约的有关条款能够有效、具约束⼒及得以实施。</li>
            </ol>
          </li>
          <li>网站内容变更
            <ol>
              <li>您同意本公司可随时调整、变更、修改或撤销本条款及相关的服务的全部或部分，并于本公司公告后立即生效，不作另⾏个别通知。在任何情况下，本公司不会就本条款及或相关服务的修改或撤销向您承担任何责任。</li>
            </ol>
          </li>
          <li>终⽌服务
            <ol>
              <li>您有权随时终⽌使⽤本公司提供的⼀切服务，及要求删除⽤⼾所提供的个⼈资料，本公司在收到电邮／书⾯有关通知后的30天内，终⽌有关服务及删除您的个⼈资料。</li>
            </ol>
          </li>
        </ol>
      `,
    },
    privacyPolicy: {
      title: "私隱政策",
      content: `
        <p>寻乐 致力保障用户的私隐，并确保用户、导师、服务提供者和合作伙伴提供的所有资料被尊重看待和严格保密。</p>
        <p>生效日期： 2018年 9月 15日</p>
        <p>寻乐 或其代表会收集以下资料：</p>
        <ol>
          <li>你提供的资料
            <ol>
              <li>用户帐号<br />我们会在你建立或更新 寻乐 帐户时收集资料。这可包括但不限于:你的姓名、性别、年龄、电邮、电话号码、登入名称与密码、地址。</li>
              <li>用户内容<br />当你联络 寻乐 客户服务、对其他用户评分或赞赏，又或者联络 寻乐 时，我们可收集你所提交的资料。</li>
            </ol>
          </li>
          <li>使用服务时产生的资料
            <ol>
              <li>位置资料<br />视乎你使用的 寻乐 服务、Web 设定或装置权限而定，我们可透过 GPS、IP 位址和 WiFi 等数据收集你的精确或大概位置资料。</li>
              <li>信用卡资料<br />当使用信用卡进行网上付款时，你的信用卡资料会保存于Stripe – 一间提供安全支付服务的机构，目的纯粹是处理付款，寻乐在任何时候都不会保存这些资料。在即时签帐授权过程中，客户的付款详情会以SSL(Secure Socket Layer)技术安全传送。</li>
              <li>装置资料<br />我们可收集你存取服务时所使用的装置资料，包括硬件型号、装置 IP 位址、操作系统与版本、软件、档案名称与版本、偏好语言、唯一装置识别码、广告识别码、序号、装置动态资料及流动网络资料。</li>
            </ol>
          </li>
          <li>其他来源的资料
            <ol>
              <li>用户意见，例如评分或赞赏。</li>
              <li>在推荐计划中提供你资料的用户。</li>
              <li>寻乐 可将从上述来源所得的资料，结合 寻乐 所拥有的其他资料一并使用。</li>
            </ol>
          </li>
          <li>会将收集到的资料用于以下用途，包括但不限于：
            <ol>
              <li>提供服务及功能</li>
              <li>使用收集到的资料提供、个人化、维护及改善产品及服务。 这包括使用资料以：
                <ol>
                  <li>建立及更新你的帐户。</li>
                  <li>验证你的身分。</li>
                </ol>
              </li>
              <li>提供分享功能，让你与别人分享资料，包括但不限于:对导师、服务提供者的赞赏、推荐朋友使用 寻乐等。</li>
              <li>进行所需的内部运作，让服务得以提供，包括解决软件错误和运作问题、进行数据分析、测试和研究，以及监察和分析使用及活动趋势。</li>
              <li>研发<br />我们可将收集到的资料用于测试、研究、分析及产品开发用途，从而改善和提升服务的安全性与保障、开发新功能和产品，以及支援与服务关连的方案。</li>
              <li>用户与导师、服务提供者之间的通讯<br />寻乐 使用收集到的资料，让用户之间能够互相通讯。包括但不限于:预约确认后，导师将获知悉用户的个人资料：姓名，性别，联络电话，年龄，上课地址，而用户亦获知悉导师的个人资料：姓名，性别，联络电话，上课地址。用户、导师、服务提供者可致电或传送文字讯息给对方以确认试堂资料。</li>
              <li>寻乐 发出的通讯<br />寻乐 可使用收集到的资料，就产品、服务、推广、研究、问卷调查、最新消息、更新和活动与你联系。</li>
              <li>法律程序及规定<br />我们可将收集到的资料用于调查或处理你在使用 寻乐 服务时作出的申索或争议，又或者在适用法律允许、应监管机构、政府实体和官方查询的要求而使用收集到的资料。</li>
            </ol>
          </li>
          <li>Cookies的运用
            <ol>
              <li>本网站使用记录精灵(cookies)。记录精灵(cookies)属小档案，大部份网站均使用，以将纯文字资料传送至电脑硬碟或其他存取装置。</li>
              <li>Musicmap.hk网站会在下列情况下，在您浏览器中写入并读取Cookies：
                <ol>
                  <li>为提供更好、更个人化的服务，以及方便您参与个人化的互动活动。</li>
                  <li>为统计浏览人数及分析浏览模式，以了解网页浏览的情况，做为Musicmap.hk网站改善服务的参考。</li>
                </ol>
              </li>
              <li>阁下使用本网站，即表示同意此等记录精灵(cookies)的使用。</li>
              <li>阁下可修改浏览器设定，控制本网站所用记录精灵(cookies)。有关如何寻找储存记录精灵(cookies)档案或目录的指示，请参阅阁下互联网浏览器帮助与支援一栏。</li>
              <li>删除或控制记录精灵(cookies)<br />如删除记录精灵(cookies)或日后停用记录精灵(cookies)，阁下或无法使用本网站若干网页或功能。</li>
              <li>本网站或含有连结至外部网站。该等网站自行设定其记录精灵(cookies)，本网站并不控制该等记录精灵(cookies)的使用，如阁下按连结至任何外部网站时务请注意。</li>
            </ol>
          </li>
          <li>安全
            <ol>
              <li>所有传送至 寻乐 的资讯均以SSL(Secure Socket Layer)加密传输。SSL是一个公认的编码系统，令浏览器在你发送资料给我们前自动将之加密或扰乱。我们亦会把户口资料存放在网站安全的部分，只限 寻乐 合资格员工存取。但遗憾的是，互联网上没有百份百安全的数据传输。我们在尽力保护你的资料的同时，并不能确保或保证这些资讯的安全。</li>
            </ol>
          </li>
          <li>管辖法律及权限
            <ol>
              <li>使用条款受香港特别行政区法律管辖。你同意服从香港法院的非专属管辖权。</li>
            </ol>
          </li>
          <li>修改
            <ol>
              <li>保留在任何时候及毋须事先通知的情况下修改私隐政策的权利。任何修改在本网站更新后即时生效。</li>
              <li>我们会不定期更新本政策。我们若进行重大变更，会透过 Musicmap.hk （本网站内）或电子邮件等其他方式通知您。</li>
              <li>我们鼓励您定期检阅本政策，以便掌握我们隐私保障作法的最新资讯。</li>
            </ol>
          </li>
          <li>你的同意
            <ol>
              <li>使用本网站代表你同意我们根据此私隐政策收集和使用有关你的资讯。</li>
            </ol>
          </li>
        </ol>
      `,
    },
  },
};
