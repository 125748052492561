
































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AsyncState, AsyncDataParam, InstructorSkillWithOrder } from "@/@types";
import InstructorCard from "@/components/InstructorCard.vue";

const Instructors = namespace("instructors");

@Component({
  components: {
    InstructorCard,
  },
})
export default class FeaturedInstructors extends Vue {
  @Instructors.State("featuredInstructors")
  public featuredInstructorsState!: AsyncState<InstructorSkillWithOrder[]>;

  get shuffledFeaturedInstructors() {
    return (this.featuredInstructorsState.data || [])
      .sort((a, b) => a.order - b.order)
      .map(({ value }) => value);
  }
}
