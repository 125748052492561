













import { Component, Vue } from "vue-property-decorator";
@Component
export default class JoinUs extends Vue {
  get src() {
    switch (this.$i18n.locale) {
      case "hk":
        return require("@/assets/img/tutor-banner-hk.jpg");
      case "cn":
        return require("@/assets/img/tutor-banner-cn.jpg");
      case "en":
        return require("@/assets/img/tutor-banner-en.jpg");
    }
  }
}
