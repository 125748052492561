




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import { disableBodyScroll, enableBodyScroll } from "@/body-scroll-lock";
import { AsyncDataParam } from "@/@types";
import ErrorView from "@/components/ErrorView.vue";

@Component
export default class Modal extends Vue {
  @Prop({ required: true })
  public active!: boolean;

  @Prop({ default: 200 })
  public switchDelay!: number;

  private isShown = false;
  private timeoutId = 0;
  private modalElement?: HTMLElement;

  public updated() {
    const modalElement = this.$refs.modal as HTMLElement | undefined;
    if (this.modalElement !== modalElement) {
      if (this.modalElement) {
        enableBodyScroll(this.modalElement);
      } else if (modalElement) {
        disableBodyScroll(modalElement, {
          allowTouchMove: (el: HTMLElement) => {
            const els = modalElement.getElementsByClassName("allowtouchmove");
            return Array.prototype.some.call(els, (n: HTMLElement) => {
              return n.clientHeight < n.scrollHeight && n.contains(el);
            });
          },
          allowTouchMoveCallback: (e: Event, clientY: number) => {
            const els = modalElement.getElementsByClassName("allowtouchmove");
            Array.prototype.forEach.call(els, (n: HTMLElement) => {
              if (n.contains(e.target as HTMLElement)) {
                if (n.scrollTop === 0 && clientY > 0) {
                  e.preventDefault();
                } else if (
                  n.scrollHeight - n.scrollTop - n.clientHeight === 0 &&
                  clientY < 0
                ) {
                  e.preventDefault();
                }
              }
            });
          },
        });
      }
      this.modalElement = modalElement;
    }
  }

  public beforeDestroy() {
    if (this.modalElement) {
      enableBodyScroll(this.modalElement);
    }
  }

  @Watch("active")
  private onActiveChanged(active: boolean) {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => {
      this.isShown = active;
      this.timeoutId = 0;
    }, this.switchDelay);
  }
}
