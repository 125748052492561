










import { Component, Vue } from "vue-property-decorator";
import Sidemenu from "@/components/Top/Sidemenu.vue";
import Modal from "@/components/Modal.vue";

@Component({
  components: {
    Sidemenu,
    Modal,
  },
})
export default class MenuButton extends Vue {
  public isSidemenuExpanded: boolean = false;
}
