import skygear from "skygear";

export const configureApi = async (
  accessToken?: string,
  apiKey?: string,
  endpoint?: string
) => {
  const container = await skygear.config({
    apiKey: apiKey || "",
    endPoint: endpoint || "",
  });
  if (accessToken) {
    container.auth._accessToken = accessToken;
    await container.auth.whoami();
  }
  return container;
};
