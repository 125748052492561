












import { Component, Vue, Prop } from "vue-property-decorator";
import SearchRouterLink from "@/components/Search/SearchRouterLink.vue";
import { Skill } from "@/@types";

@Component({
  components: {
    SearchRouterLink,
  },
})
export default class PopularSkill extends Vue {
  @Prop({ default: false }) public isMore!: boolean;
  @Prop({ default: null }) public skill!: Skill | null;

  get iconSrc() {
    if (this.isMore || this.skill === null) {
      return require("@/assets/img/mm-icon-8.png");
    } else {
      return this.skill.iconImageUrl;
    }
  }
  get name() {
    if (this.isMore || this.skill === null) {
      return this.$t("landing.popularSkills.moreSkill");
    } else {
      return this.skill.name[this.$i18n.locale];
    }
  }
}
