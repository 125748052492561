

























import { Mixins, Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import { namespace } from "vuex-class";
import { User, SsoProviderType } from "@/@types";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import LoginForm from "@/components/Login/LoginForm.vue";
import SuccessForm from "@/components/SuccessForm.vue";
import SearchRouterLink from "@/components/Search/SearchRouterLink.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

const Auth = namespace("auth");

@Component({
  components: {
    Layout,
    Top,
    Bottom,
    LoginForm,
    SuccessForm,
    SearchRouterLink,
  },
})
export default class Login extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  @Auth.Getter("isLoggedIn") public isLoggedIn!: boolean;
  @Auth.State("user") public user!: User;
  @Auth.State("isNewUser") public isNewUser!: boolean;
  @Auth.State("pathBeforeLogin") public pathBeforeLogin!: string | null;

  get title() {
    return this.$t("login.loginForm.title");
  }

  public async created() {
    if (this.isLoggedIn) {
      const { sso } = this.$route.query;
      if (sso === "1" && this.user) {
        const { currentUser } = this.$skygear.auth;
        await this.$skygear.publicDB.save(currentUser!);
        this.loginSuccess(this.user);
      } else {
        this.$router.push(`/${this.$i18n.locale}`);
      }
    }
  }

  public destroyed() {
    this.$store.dispatch("auth/resetPathBeforeLogin");
  }

  get callbackURL() {
    const baseUrl = `${this.configs.VUE_APP_SKYGEAR_CLIENT_ENDPOINT}`.replace(
      /\/$/,
      ""
    );
    const basePath = (this.$router.options.base || "").replace(/\/$/, "");
    const { locale } = this.$i18n;
    const returnPath = `/${locale}/login?sso=1&path=${this.pathBeforeLogin ||
      ""}`;
    return encodeURI(`${baseUrl}${basePath}${returnPath}`);
  }

  public loginSuccess(user: User) {
    const pathBeforeLogin = this.$route.query.path || this.pathBeforeLogin;
    if (this.isNewUser) {
      window.setTimeout(() => {
        if (pathBeforeLogin) {
          this.$router.push(pathBeforeLogin);
        }
      }, 1000);
    } else {
      this.$router.push(pathBeforeLogin || `/${this.$i18n.locale}`);
    }
  }

  public async login(provider: SsoProviderType) {
    try {
      const user = await this.$store.dispatch("auth/login", {
        provider,
        callbackURL: this.callbackURL,
      });
      this.loginSuccess(user);
    } catch (error) {
      if (error.error && error.error.code === 127) {
        window.alert(this.$t("common.error.userDisabled"));
      } else if (
        error.error &&
        error.error.message !== "User cancel the login flow"
      ) {
        window.alert(this.$t("common.error.unknown"));
      }
    }
  }
  public beforeDestroy() {
    this.$store.dispatch("auth/resetIsNewUser");
  }
}
