




import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  public mounted() {
    this.$store.dispatch("auth/setUser");
  }
}
