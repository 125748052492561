




import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AsyncState, AsyncDataParam, Course, Instructor } from "@/@types";
import { AsyncStates, AsyncStatus } from "@/store/async";
import Layout from "@/components/Layout.vue";
import ErrorView from "@/components/ErrorView.vue";
import * as Sentry from "@sentry/browser";

const BookCourseStore = namespace("bookCourse");
@Component({
  components: {
    Layout,
    ErrorView,
  },
})
export default class BookCourse extends Vue {
  public static async asyncData({ store, route }: AsyncDataParam) {
    const { slug, skill, level, duration } = route.params;
    await Promise.all([
      store.dispatch("codes/fetchRegions"),
      store.dispatch("bookCourse/fetchInstructor", { slug }),
      store.dispatch("bookCourse/fetchCourse", {
        instructor: slug,
        skill,
        level,
        duration,
      }),
    ]);
  }

  @BookCourseStore.State("course")
  public courseState!: AsyncState<Course>;
  @BookCourseStore.State("instructor")
  public instructorState!: AsyncState<Instructor>;

  get isParamsValid() {
    return (
      (AsyncStates.statusOf(this.courseState) === AsyncStatus.LOADING ||
        this.courseState.data) &&
      (AsyncStates.statusOf(this.instructorState) === AsyncStatus.LOADING ||
        this.instructorState.data)
    );
  }

  public created() {
    const { error } = this.$route.query;
    if (!error) {
      this.$store.dispatch("bookCourse/setStepOne", null);
      this.$store.dispatch("bookCourse/setStepTwo", null);
      this.$store.dispatch("bookCourse/setStepThree", null);
    }
  }
}
