











































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "@/@types";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import SearchRouterLink from "@/components/Search/SearchRouterLink.vue";

const Auth = namespace("auth");

@Component({
  components: {
    I18nRouterLink,
    SearchRouterLink,
  },
})
export default class Sidemenu extends Vue {
  @Auth.Getter("name") public name!: () => string;

  public setMenuMaxHeight = () => {
    const el = document.getElementsByClassName(
      "sidemenu__nav"
    )[0] as HTMLElement;
    el.style.maxHeight = `${window.innerHeight - 150}px`;
  };
  public mounted() {
    if (!window.onresize) {
      this.setMenuMaxHeight();
      window.onresize = this.setMenuMaxHeight;
    }
  }
  public destroyed() {
    window.onresize = null;
  }
  public async logout() {
    this.close();
    const { locale } = this.$i18n;
    await this.$store.dispatch("auth/logout");
    this.$router.push(`/${locale}`);
  }
  public close() {
    this.$emit("close");
  }
}
