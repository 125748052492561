











import { Component, Vue, Prop } from "vue-property-decorator";
import ExpandedSearchBar from "@/components/Search/ExpandedSearchBar.vue";
import MinifiedSearchBar from "@/components/Search/MinifiedSearchBar.vue";
import { Skill } from "@/@types";

@Component({
  components: {
    ExpandedSearchBar,
    MinifiedSearchBar,
  },
})
export default class SearchBar extends Vue {
  @Prop({ default: true }) public expanded!: boolean;
  @Prop({ default: true }) public show!: boolean;

  public toggleSearchBar() {
    this.$emit("toggle");
  }
}
