import Vue from "vue";
import { Store } from "vuex";
import Router, { Route } from "vue-router";
import VueI18n from "vue-i18n";
import { RootState } from "@/@types";
import Transparent from "@/views/Transparent.vue";
import Login from "@/views/Login.vue";
import Landing from "@/views/Landing.vue";
import BookingRecordList from "@/views/BookingRecordList.vue";
import EditAccount from "@/views/EditAccount.vue";
import Search from "@/views/Search.vue";
import Activities from "@/views/Activities.vue";
import TuningIntro from "@/views/TuningIntro.vue";
import InstructorDetailModal from "@/views/InstructorDetailModal.vue";
import ActivityDetailModal from "@/views/ActivityDetailModal.vue";
import BookCourse from "@/views/BookCourse/BookCourse.vue";
import BookCourseStepOne from "@/views/BookCourse/StepOne.vue";
import BookCourseStepTwo from "@/views/BookCourse/StepTwo.vue";
import BookCourseStepThree from "@/views/BookCourse/StepThree.vue";
import BookCourseAliPay from "@/views/BookCourse/AliPay.vue";
import BookCoursePaymentSuccess from "@/views/BookCourse/PaymentSuccess.vue";
import BookTuning from "@/views/BookTuning/BookTuning.vue";
import BookTuningStepOne from "@/views/BookTuning/StepOne.vue";
import BookTuningStepTwo from "@/views/BookTuning/StepTwo.vue";
import BookTuningStepThree from "@/views/BookTuning/StepThree.vue";
import BookTuningAliPay from "@/views/BookTuning/AliPay.vue";
import BookTuningPaymentSuccess from "@/views/BookTuning/PaymentSuccess.vue";
import HowWeWork from "@/views/HowWeWork.vue";
import AboutUs from "@/views/AboutUs.vue";
import ContactUs from "@/views/ContactUs.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import ErrorPage from "@/views/ErrorPage.vue";

Vue.use(Router);

export const createRouter = (i18n: VueI18n, store: Store<RootState>) => {
  const router = new Router({
    mode: "history",
    base: "/",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    routes: [
      {
        path: "/:locale",
        component: Transparent,
        children: [
          { path: "", component: Landing },
          { path: "login", component: Login, name: "login" },
          {
            path: "bookings",
            component: BookingRecordList,
            meta: { requiresAuth: true },
          },
          {
            path: "account",
            component: EditAccount,
            meta: { requiresAuth: true },
          },
          { path: "search/:skill", component: Search },
          { path: "tutor/:slug/:skill", component: InstructorDetailModal },
          {
            path: "tutor/:slug/:skill/:level/:duration/book",
            component: BookCourse,
            meta: { requiresAuth: true },
            children: [
              { path: "", redirect: "1" },
              { path: "1", component: BookCourseStepOne },
              { path: "2", component: BookCourseStepTwo },
              { path: "3", component: BookCourseStepThree },
            ],
          },
          {
            path: "tutor/:slug/:skill/:level/:duration/book/alipay",
            component: BookCourseAliPay,
            meta: { requiresAuth: true },
          },
          {
            path: "tutor/:slug/:skill/:level/:duration/book/success",
            component: BookCoursePaymentSuccess,
            meta: { requiresAuth: true },
          },
          {
            path: "events/:type?",
            component: Activities,
          },
          { path: "event/:slug", component: ActivityDetailModal },
          { path: "tuning", component: TuningIntro },
          {
            path: "tuning/book",
            component: BookTuning,
            children: [
              { path: "", redirect: "1" },
              { path: "1", component: BookTuningStepOne },
              { path: "2", component: BookTuningStepTwo },
              { path: "3", component: BookTuningStepThree },
            ],
            meta: { requiresAuth: true },
          },
          {
            path: "tuning/book/alipay",
            component: BookTuningAliPay,
            meta: { requiresAuth: true },
          },
          {
            path: "tuning/book/success",
            component: BookTuningPaymentSuccess,
            meta: { requiresAuth: true },
          },
          { path: "about-us", component: AboutUs },
          { path: "how-we-work", component: HowWeWork },
          { path: "contact-us", component: ContactUs },
          { path: "terms-and-conditions", component: TermsAndConditions },
          {
            path: "*",
            component: ErrorPage,
            meta: { isError: true },
          },
        ],
      },
    ],
  });
  router.beforeEach((to, from, next) => {
    const locale = to.params.locale;
    if (to.meta.isError && !(locale in i18n.messages)) {
      next({ replace: true, path: `${i18n.fallbackLocale}/error` });
    } else if (!(locale in i18n.messages)) {
      next(i18n.fallbackLocale);
      return;
    }
    i18n.locale = locale;

    const isLoggedIn = store.getters["auth/isLoggedIn"];
    if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
      store.dispatch("auth/savePathBeforeLogin", to.fullPath);
      next({ name: "login", params: { locale: i18n.locale } });
      return;
    }

    next();
  });
  return router;
};

export const isOnlyLocaleChange = (from: Route, to: Route) => {
  if (!from) {
    return false;
  }
  if (from.params.locale === to.params.locale) {
    return false;
  }
  const fromFullNoLocale = from.fullPath.replace(`/${from.params.locale}`, "");
  const toFullNoLocale = to.fullPath.replace(`/${to.params.locale}`, "");
  if (fromFullNoLocale === toFullNoLocale) {
    return true;
  }
  return false;
};
