








































































import { Mixins, Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  AsyncState,
  AsyncDataParam,
  Tuning,
  TuningInstrument,
  TuningPackage,
  PageTuning,
} from "@/@types";
import Layout from "@/components/Layout.vue";
import Top from "@/components/Top.vue";
import TabBar from "@/components/TabBar.vue";
import Bottom from "@/components/Bottom.vue";
import I18nRouterLink from "@/components/I18nRouterLink.vue";
import TitleMixin from "@/mixins/TitleMixin.vue";
import OGDescriptionMixin from "@/mixins/OGDescriptionMixin.vue";
import OGImageMixin from "@/mixins/OGImageMixin.vue";

const BookTuning = namespace("bookTuning");
const Codes = namespace("codes");

@Component({
  components: {
    Layout,
    Top,
    TabBar,
    Bottom,
    I18nRouterLink,
  },
})
export default class TuningIntro extends Mixins(
  TitleMixin,
  OGDescriptionMixin,
  OGImageMixin
) {
  public static async asyncData({ store, route }: AsyncDataParam) {
    await Promise.all([
      store.dispatch("codes/fetchTuningInstruments"),
      store.dispatch("codes/fetchTuningPackages"),
      store.dispatch("codes/fetchPageTuning"),
      store.dispatch("bookTuning/fetchTunings"),
    ]);
  }

  @Codes.State("pageTuning")
  public pageTuningState!: AsyncState<PageTuning>;

  @Codes.State("tuningInstruments")
  public tuningInstrumentsState!: AsyncState<TuningInstrument[]>;

  @Codes.State("tuningPackages") public tuningPackagesState!: AsyncState<
    TuningPackage[]
  >;

  @BookTuning.Getter("findTuningByCode") public findTuningByCode!: (
    tuningInstrumentCode: string,
    tuningPackageCode: string
  ) => Tuning | undefined;

  get ogDescription() {
    return this.description.join("");
  }

  get ogImage() {
    return this.bannerSrc;
  }

  get instruments() {
    return this.tuningInstrumentsState.data || [];
  }
  get packages() {
    return this.tuningPackagesState.data || [];
  }

  get bannerSrc() {
    if (!this.pageTuningState.data) {
      return "";
    }
    return this.pageTuningState.data.bannerImageUrl;
  }

  get title() {
    if (!this.pageTuningState.data) {
      return "";
    }
    return this.pageTuningState.data.title[this.$i18n.locale];
  }

  get description() {
    if (!this.pageTuningState.data) {
      return [];
    }
    return this.pageTuningState.data.description[this.$i18n.locale]
      .trim()
      .split("\n");
  }

  get remark() {
    if (!this.pageTuningState.data) {
      return "";
    }
    return this.pageTuningState.data.remark[this.$i18n.locale]
      .trim()
      .split("\n");
  }
}
