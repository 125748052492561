export default {
  common: {
    comma: ", ",
    slash: "/",
    hyphen: "-",
    bracket: {
      open: "(",
      close: ")",
    },
    area: {
      hk: "Hong Kong Island",
      kl: "Kowloon",
      nt: "New Territories",
    },
    month: {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    },
    userIs: {
      STUDENT: "Student",
      PARENT: "Parent",
    },
    studentGender: {
      MALE: "Male",
      FEMALE: "Female",
    },
    studentGenderShort: {
      MALE: "Male",
      FEMALE: "Female",
    },
    venueType: {
      STUDENT: "Student's Home",
      INSTRUCTOR: "Tutor's studio/home or music center nearby*",
    },
    requireAccessaryReplacement: {
      TRUE: "Yes (Extra Fee)",
      FALSE: "No",
    },
    requireRelocation: {
      TRUE: "Yes (Extra Fee)",
      FALSE: "No",
    },
    paymentMethod: {
      card: "Credit Card",
      applepay: "Apple Pay",
      alipay: "Alipay",
    },
    og: {
      description:
        "A map of melody and music in mind. A map of a life – long odyssey in hand in which there are full of bliss and delights in which there are full of cheerful encounters. Listening to music, one finds joy. Playing the music, one finds himself. Let the music around as MusicMap is here for you.",
    },
    error: {
      unknown: "Sorry, something went wrong. Please try again after a while.",
      userDisabled: "User disabled. Please contact administrator.",
      transactionDeclined:
        "Transaction declined. Please try again with another credit card or payment method.",
    },
  },
  formFields: {
    selectField: {
      emptyOption: {
        label: "Please Select",
      },
    },
    timeslotDateInput: {
      placeholder: "Select Date",
    },
    timeslotTimeInput: {
      emptyOption: {
        label: "Select Time",
      },
    },
    nextButton: {
      label: "Next Step",
    },
  },
  landing: {
    title: "Search for Music Tutor",
    banner: {
      tagline1: "Search for Hong Kong Music Tutor︱Piano Tuning ︱Music Event",
      tagline2: "Music Tutor",
    },
    popularSkills: {
      title: "Popular Instruments",
      moreSkill: "More",
    },
    featuredInstructors: {
      title: "Recommended Tutors",
    },
    latestActivities: {
      title: "Latest Events",
    },
  },
  login: {
    loginForm: {
      title: "Start using Musicmap",
      description:
        "Please choose one of the following methods to connect to your account",
      button: {
        fb: "Login with Facebook",
        gl: "Login with Google",
      },
    },
    successForm: {
      title: "Registered successfully",
      description:
        "You can now book our professional music tutor and tuning service.",
      button: {
        search: "Search now",
      },
    },
  },
  search: {
    title:
      "{skill} Lesson｜{skill} Course｜{skill} Tutor｜Individual {skill} Lessons Hong Kong",
    minifiedSearchBar: {
      text: "{skill} - Search for Tutors",
    },
    noResult: {
      instructors: "No tutors found",
      activities: "No events found",
    },
    filterModal: {
      all: "All",
      region: "Teaching regions",
      price: "Price range",
      priceRange: {
        "0": "150-250",
        "1": "251-400",
        "2": "More than 400",
      },
      level: "Course level",
      reset: "Reset filters",
      search: "Search",
    },
  },
  activities: {
    title: "Events - {type}",
    tabbar: {
      all: "All",
      concert: "Concerts",
      workshop: "Workshops",
      contest: "Contests",
    },
    monthSelector: {
      emptyOptionLabel: "Please Select Month",
      optionLabel: "Activities in {month}",
    },
  },
  instructorCard: {
    ratingScore: {
      title: "Rating",
    },
  },
  instructorDetailModal: {
    bio: "Biography",
    education: "Education",
    awards: "Awards and achievements",
    performanceExperiences: "Performance experience",
    teachingExperiences: "Teaching experience",
    languages: "Teaching languages",
    regions: "Teaching regions",
    selectLevel: "Select Course Level",
    bookCourse: "Book Course",
    courseName: "{level} ({duration}mins)",
  },
  header: {
    auth: {
      login: "Login",
      register: "Register",
    },
  },
  tabbar: {
    search: "Search tutors",
    activities: "Events",
    tuning: "Tuning",
  },
  footer: {
    copyright: "© MusicMap Co., Ltd. {year}",
    aboutUs: "About Us",
    howWeWork: "How We Work",
    contactUs: "Contact Us",
    terms: "Service Terms & Privacy Policy",
  },
  bookCourse: {
    step1: {
      title: "1 Student Information",
      userIs: {
        label: "You are...",
      },
      studentName: {
        label: "Student Name",
        placeholder: "Please enter student name",
        errors: {
          required: "Please enter student name",
        },
      },
      studentGender: {
        label: "Student Gender",
      },
      studentAgeRange: {
        label: "Student Age Range",
        errors: {
          required: "Please select student age range",
        },
      },
      level: {
        label: "Course Level",
        value: "{name} ({duration}mins)",
      },
      remark: {
        label: "Remark (if any)",
        placeholder: "E.g. special needs & learning preference",
      },
      contactPhone: {
        label: "Contact Number",
        placeholder: "Please enter contact number",
        errors: {
          required: "Please enter contact number",
          format: "Please enter HK phone number",
        },
      },
    },
    step2: {
      title: "2 Time and Venue",
      venueType: {
        label: "Lessons Venue",
        errors: {
          required: "Please select lessons venue",
        },
      },
      studentAddress: {
        label: "Student's Home Address",
        placeholder: "Please enter student's home address",
        errors: {
          regionRequired: "Please select region",
          addressRequired: "Please enter address",
        },
      },
      instructorAddress: {
        label: "Tutor's Region",
        errors: {
          required: "Please select region",
        },
        remark1:
          "Exact address will be provided after booking is successfully made",
        remark2:
          "*Music center rental fee will be collected separately (around $40-$120 per hour)",
      },
      timeslot: {
        intro:
          "Please provide three available timeslots. We will try our best to arrange trial lesson according to your wishes.",
        label: "Timeslot",
        errors: {
          required: "Please provide available timeslot",
          duplicated: "Duplicated timeslots",
        },
      },
    },
    step3: {
      title: "3 Confirm and Pay",
      instructor: {
        label: "Tutor",
        value: "{name} ({skill})",
      },
      student: {
        label: "Student",
        value: "{name} ({gender}, {ageRange})",
      },
      level: {
        label: "Course Level",
        value: "{name} ({duration}mins)",
      },
      contactPhone: {
        label: "Contact Number",
      },
      venueType: {
        label: "Lessons Venue",
      },
      studentAddress: {
        label: "Student's Home Address",
      },
      instructorAddress: {
        label: "Tutor's Region",
      },
      timeslot: {
        label: "Available Timeslots",
      },
      price: {
        label: "First Lesson Fee",
      },
      remark1:
        "We will contact tutor and arrange for first lesson for you after payment is confirmed.",
      remark2:
        "If by any change we fail to arrange for the trial lesson, your payment will not be refunded. You can choose another tutor (May require to make up the price differece)",
      paymentMethods: "Please select payment method:",
    },
    alipay: {
      title: "Processing...",
    },
    success: {
      title: "Booking Success",
      description:
        "We have received your information and will confirm your booking with you as soon as possible.",
      button: {
        label: "View My Bookings",
        whatsapp: "Contact Music Map",
      },
    },
  },
  bookTuning: {
    intro: {
      charge: "Charge",
      button: "Book Tuning",
    },
    step1: {
      title: "1 Basic Information",
      instrument: {
        label: "Tuning Instrument",
      },
      requireAccessaryReplacement: {
        label: "Require Accessories Replacement?",
      },
      requireAccessaryReplacementRemark: {
        placeholder: "Please specify the required accessories",
        errors: {
          required: "Please specify the required accessories",
        },
      },
      requireRelocation: {
        label: "Require Moving?",
      },
      contactName: {
        label: "Contact Person",
        placeholder: "Please enter contact person's name",
        errors: {
          required: "Please enter contact person's name",
        },
      },
      contactPhone: {
        label: "Contact Number",
        placeholder: "Please enter contact number",
        errors: {
          required: "Please enter contact number",
          format: "Please enter HK phone number",
        },
      },
      bookingSelector: {
        selectLabel: "Select Package",
        bookNowLabel: "Book Tuning",
      },
    },
    step2: {
      title: "2 Time and Venue",
      address: {
        label: "Address",
        placeholder: "Please enter address",
        errors: {
          regionRequired: "Please select region",
          addressRequired: "Please enter address",
        },
      },
      timeslot: {
        intro:
          "Please provide three available timeslots (about 1 hour). We will arrange instruments tuner according to your wishes.",
        label: "Timeslot",
        errors: {
          required: "Please provide available timeslot",
          duplicated: "Duplicated timeslots",
        },
      },
      remark: {
        label: "Remark (if any)",
        placeholder: "E.g. special needs & preference",
      },
    },
    step3: {
      title: "3 Confirm and Pay",
      instrument: {
        label: "Tuning Instrument",
      },
      requireAccessaryReplacement: {
        yesLabel: "Require accessories replacement",
        noLabel: "Do not require accessories replacement",
      },
      requireRelocation: {
        yesLabel: "require moving",
        noLabel: "do not require moving",
      },
      contactName: {
        label: "Contact Person",
      },
      contactPhone: {
        label: "Contact Number",
      },
      address: {
        label: "Address",
      },
      timeslot: {
        label: "Available Timeslots",
      },
      price: {
        label: "Booking Fee",
      },
      paymentMethods: "Please select payment method:",
    },
    alipay: {
      title: "Processing...",
    },
    success: {
      title: "Booking Success",
      description:
        "We have received your information and will confirm your booking with you as soon as possible.",
      button: {
        label: "View My Bookings",
        whatsapp: "Contact Music Map",
      },
    },
  },
  sidemenu: {
    search: "Search tutors",
    events: "Events",
    tuning: "Tuning",
    bookings: "My booking records",
    account: "Edit account details",
    aboutUs: "About MusicMap",
    contactUs: "Contact Us",
    howWeWork: "How We Work",
    logout: "Logout",
  },
  applePay: {
    total: {
      label: "Fee",
    },
  },
  alipay: {
    error: {
      CANCELED: "Payment canceled",
      FAILED: "Payment failed. Please try again after a while.",
    },
  },
  paymentForm: {
    title: "Pay by Credit Card",
    cardHolderName: {
      label: "Card holder name",
      placeholder: "Please enter card holder name",
      errors: {
        required: "Please enter card holder name",
      },
    },
    cardNumber: {
      label: "Card number",
      placeholder: "Please enter card number",
      errors: {
        required: "Please enter card number",
        format: "Invalid card number",
      },
    },
    expiryDate: {
      label: "Expiry date",
      errors: {
        required: "Required",
        format: "Invalid",
      },
    },
    cvc: {
      label: "CVC",
      errors: {
        required: "Required",
        format: "Invalid",
      },
    },
    email: {
      label: "Email",
      placeholder: "Please enter email address",
      hint: "Reciept will be sent to Email",
      errors: {
        required: "Please enter email address",
        format: "Invalid email address",
      },
    },
    submitButton: {
      label: "Confirm & Pay",
    },
  },
  errorView: {
    message: "Page not found or system error",
  },
  record: {
    empty: "No booking records yet",
    button: {
      label: "Search Now",
    },
    notice:
      "After the reservation is confirmed, you will not be able to cancel the test hall. If you need to change the time, please change the guide three days before the test date.",
    status: {
      PENDING: "Processing",
      CONFIRMED: "Confirmed",
      CANCELLED: "Cancelled",
      PUNCHEDIN: "Signed in",
    },
    course: {
      details: {
        instructor: "Tutor",
        student: "Student",
        level: "Level",
        venue: "Class venue",
        time: "Class time",
        payment: "Paid",
        unconfirmed: "To be confirmed by the instructor",
      },
      checkin: "Check in",
      rating: {
        title: "Rating",
        comment: {
          title: "Comments",
          placeholder: "Comments (optional)",
        },
        submit: "Submit",
      },
    },
    tuning: {
      title: "Tuning",
      details: {
        requireAccessaryReplacement: {
          yesLabel: "Need to replace the accessories",
          noLabel: "No replacement parts are required",
        },
        requireRelocation: {
          yesLabel: "Need to move the piano",
          noLabel: "No need to move the piano",
        },
        contactName: "Contact",
        contactPhone: "Phone",
        address: "Address",
        timeslot: "Timeslot",
        unconfirmed: "To be confirmed",
        date: "Date",
        time: "Time",
        payment: "Paid",
      },
    },
  },
  account: {
    name: {
      label: "Name",
      placeholder: "Please enter your name",
      errors: {
        required: "Please enter your name",
      },
    },
    phone: {
      label: "Phone",
      placeholder: "Please enter your phone",
      errors: {
        format: "Please enter HK phone number",
      },
    },
    email: {
      label: "Email address",
    },
    link: {
      title: "Linked accounts",
      linked: "Linked",
      nolink: "Not linked",
    },
    submit: "Save changes",
    success: {
      description: "Account details updated",
      okay: "Okay",
    },
  },
  activityDetail: {
    performanceUnit: "Performance Unit",
    date: "Date",
    time: "Time",
    address: "Location",
    fee: "Fee",
    link: "Check Details",
  },
  aboutUs: {
    title: "About Us",
    content: `
      <p>A map of melody and music in mind.</p>
      <p>A map of a life – long odyssey in hand in which there are full of bliss and delights in which there are full of cheerful encounters.</p>
      <p>Listening to music, one finds joy.</p>
      <p>Playing the music, one finds himself.</p>
      <p>Let the music around as MusicMap is here for you.</p>
      <h3>Our Philosophy</h3>
      <p>Integrate various platforms and professionals to provide you with information about music events.</p>
      <p>To foster the music culture in Hong Kong, we aim to increase and nurture the awareness and interests among the young.</p>
      <h3>To Our Clients</h3>
      <p>Offer diverse services which allows you to stay in touch with musical performance and awaken your passion toward music.</p>
      <h3>The Tutors</h3>
      <p>From basics to practicals. From knowledge to artistic temperament. We are here for all the talents enthusiastically and sincerely.</p>
      <h3>The Network</h3>
      <p>You can find music workshops, competitions, performances and small groups/classes around Hong Kong or your neighborhood on our MusicMap App.</p>
      <h3>Our Vision</h3>
      <p>We wish to share the knowledge and skills of music with all in Hong Kong. In the rhythm wonderland, one may find pleasure, admiration and feeling of well-being.</p>
    `,
  },
  howWeWork: {
    title: "How We Work / Q&A",
  },
  contactUs: {
    title: "Contact Us",
    content: `
      <p></p>
      <p>Tel/WhatsApp: +85256062052</p>
      <p>Address：Shop 15,G/f,Beverley Commercial Centre,No.87-105 chatham road south, TST</p>
      <p>General Enquiries: <a href="mailto:info@musicmap.hk">info@musicmap.hk</a></p>
      <p>Events/Partnership: <a href="mailto:marketing@musicmap.hk">marketing@musicmap.hk</a></p>
      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSf7BycSJexQDkYEynimeRLuXVYLbQta8snpgP8gDJ1cZhusLQ/viewform">Posting Events on MusicMap</a></p>
      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSdwNjsiqfYZiAvosJ1xAIOxUJIQKqfvpJeLdR3GlVpeWK_dmA/viewform?fbclid=IwAR2u9qgdiRLjQALOKkoyfPZkAJucRmO1r2XFRGRrpRtE-CGUVkB5aJsrIQw">Join Us (Become a Tutor)</a></p>
      <p>The information sources on this website are from sponsors/artists, program leaflets, social networking platforms, etc.</p>
      <p>If you don't want to display program information, please email to us  <a href="mailto:info@musicmap.hk">info@musicmap.hk</a></p>
    `,
  },
};
