









import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class Layout extends Vue {
  @Prop({ default: 0 }) public contentPaddingTopDelta!: number;
  public contentPaddingTop = 0;

  get headerHeight() {
    const el = this.$el.firstElementChild;
    return el && el.className !== "content" ? el.clientHeight : 0;
  }

  public mounted() {
    this.contentPaddingTop = this.headerHeight;
  }

  @Watch("$route")
  public onRouteChange(route: Route) {
    this.contentPaddingTop = this.headerHeight;
  }

  @Watch("contentPaddingTopDelta")
  public onContentPaddingTopDeltaChange(delta: number) {
    this.contentPaddingTop += delta;
  }
}
