import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { AuthState, RootState, User, AsyncState } from "@/@types";
import { parseSkygearUser, parseUser } from "@/store/parsers";
import { Container } from "skygear";
import { AsyncStates, AsyncCompletion } from "./async";
import { isWebview } from "@/helpers/userAgent";

const AUTH_COOKIE_NAME = "access-token";
const SSO_COOKIE_ANME = "sso_data";

const updateAuthCookie = (accessToken?: string) => {
  if (typeof document === "undefined") {
    return;
  }

  const cookieValue = encodeURIComponent(accessToken || "");
  if (cookieValue.length > 0) {
    document.cookie = `${AUTH_COOKIE_NAME}=${cookieValue}; max-age=31536000; path=/; secure`;
  } else {
    document.cookie = `${AUTH_COOKIE_NAME}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
  document.cookie = `${SSO_COOKIE_ANME}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

const createState = (skygear: Container): AuthState => {
  const { accessToken, currentUser } = skygear.auth;
  return {
    accessToken,
    user: currentUser ? parseSkygearUser(currentUser) : null,
    isNewUser: false,
    pathBeforeLogin: null,
    userForEdit: AsyncStates.default(),
  };
};

const createGetters = (): GetterTree<AuthState, RootState> => {
  const _getName = (state: AuthState) => {
    const { user } = state;
    return user ? user.displayName : "";
  };
  return {
    isLoggedIn(state: AuthState) {
      return state.user !== null;
    },
    name(state: AuthState) {
      return _getName(state);
    },
    email(state: AuthState) {
      const { user } = state;
      if (user && !user.email.endsWith("@musicmap.hk")) {
        return user.email;
      }
      return "";
    },
    phoneNumber(state: AuthState) {
      const { user } = state;
      if (user && user.phoneNumber) {
        return user.phoneNumber || "";
      }
      return "";
    },
    defaultStudentName(state: AuthState) {
      const { user } = state;
      if (user && user.defaultStudentName) {
        return user.defaultStudentName || "";
      }
      return _getName(state);
    },
    ssoProviders(state: AuthState) {
      const { user } = state;
      if (user && user.ssoProviders) {
        return user.ssoProviders;
      }
      return {};
    },
  };
};

const createActions = (
  skygear: Container
): ActionTree<AuthState, RootState> => {
  return {
    async setUser({ commit }) {
      updateAuthCookie(skygear.auth.accessToken);
      if (skygear.auth.currentUser) {
        const currentUser = await skygear.auth.whoami();
        const user = parseSkygearUser(currentUser);
        commit("setUser", user);
        return user;
      } else {
        commit("setUser", null);
        return null;
      }
    },
    async login({ dispatch, commit }, { provider, callbackURL }) {
      if (isWebview()) {
        await skygear.auth.loginOAuthProviderWithRedirect(provider, {
          callbackURL,
        } as any);
      } else {
        const user = await skygear.auth.loginOAuthProviderWithPopup(
          provider,
          {} as any
        );
        commit("setIsNewUser", !user.display_name);
        // To trigger user hook
        const currentUser = await skygear.auth.whoami();
        await skygear.publicDB.save(currentUser);
        return dispatch("setUser");
      }
    },
    async logout({ dispatch }) {
      await skygear.auth.logout();
      return dispatch("setUser");
    },
    resetIsNewUser({ commit }) {
      commit("setIsNewUser", false);
    },
    savePathBeforeLogin({ commit }, path) {
      commit("setPathBeforeLogin", path);
      return path;
    },
    resetPathBeforeLogin({ commit }) {
      commit("setPathBeforeLogin", null);
      return null;
    },
    async updateAccountDetail({ commit }, params) {
      const response = await skygear.lambda(
        "musicmap:update_account_detail",
        params
      );
      const user = parseUser(response);
      commit("setUser", user);
    },
    async fetchUserForEdit({ state, commit }, params) {
      let data: User | undefined;
      let error: Error | undefined;
      commit("fetchUserForEditBegin");
      const requestId = state.userForEdit.currentRequestId;
      try {
        await skygear.auth.whoami();
        data = parseSkygearUser(skygear.auth.currentUser!);
      } catch (e) {
        error = e;
      }

      commit("fetchUserForEditCompleted", { requestId, data, error });
    },
  };
};

const createMutations = (): MutationTree<AuthState> => {
  return {
    setUser(state: AuthState, user: User) {
      state.user = user;
    },
    setIsNewUser(state: AuthState, isNewUser: boolean) {
      state.isNewUser = isNewUser;
    },
    setPathBeforeLogin(state: AuthState, path: string | null) {
      state.pathBeforeLogin = path;
    },
    fetchUserForEditBegin(state: AuthState) {
      AsyncStates.begin(state.userForEdit, false);
    },
    fetchUserForEditCompleted(state: AuthState, params: AsyncCompletion<User>) {
      AsyncStates.completed(state.userForEdit, params);
    },
  };
};

export const createAuthModule = (skygear: Container) => {
  return {
    namespaced: true,
    state: createState(skygear),
    getters: createGetters(),
    actions: createActions(skygear),
    mutations: createMutations(),
  } as Module<AuthState, RootState>;
};
