









import moment, { Moment } from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import SelectInput from "@/components/FormFields/SelectInput.vue";

interface Option {
  value: string;
  label: string;
}

@Component({
  components: { SelectInput },
})
export default class TimeslotTimeInput extends Vue {
  @Prop({ required: true }) public selected!: Moment;
  @Prop({ default: false }) public error!: boolean;
  @Prop({ default: null }) public from!: Moment | null;
  @Prop({ default: null }) public to!: Moment | null;

  public options: Option[] = [];
  public _from: Moment | null = null;
  public _to: Moment | null = null;

  public created() {
    const today = moment().startOf("day");
    if (this.from) {
      this._from = this.createMoment(today, this.from);
    } else {
      this._from = moment(today).add(8, "hours"); // 8am
    }
    if (this.to) {
      this._to = this.createMoment(today, this.to);
    } else {
      this._to = moment(today).add(21, "hours"); // 9pm
    }
    this.options = this.createOptions();
  }
  public createMoment(today: Moment, m: Moment) {
    return moment(today).set({
      hour: m.get("hour"),
      minute: m.get("minute"),
      second: m.get("second"),
      millisecond: m.get("millisecond"),
    });
  }
  public createOptions() {
    const results = [];
    while (!this._from!.isAfter(this._to!)) {
      results.push(moment(this._from!));
      this._from!.add(15, "minutes");
    }
    return results.map((m) => ({
      value: m.format("HH:mm"),
      label: m.format("h:mma"),
    }));
  }

  public onChange(event: string) {
    this.$emit("change", event === "" ? null : moment(event, "HH:mm"));
  }
}
