




























import { Component, Prop, Vue } from "vue-property-decorator";
import I18nRouterLink from "@/components/I18nRouterLink.vue";

@Component({
  components: {
    I18nRouterLink,
  },
})
export default class NotFound extends Vue {
  public isDebug = this.configs.VUE_APP_DEBUG === "true";
  @Prop({ required: false }) public error?: any;

  @Prop({ default: true }) public showsHeader!: boolean;
}
