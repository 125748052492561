







import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PriceRange extends Vue {
  @Prop() public min!: number;
  @Prop() public max!: number;
}
