



















import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import InstrumentProfileImage from "@/components/Record/Tuning/InstrumentProfileImage.vue";
import ProfileTag from "@/components/ProfileTag.vue";
import RecordStatus from "@/components/Record/RecordStatus.vue";
import TuningDetails from "@/components/Record/Tuning/TuningDetails.vue";
import {
  AsyncState,
  AsyncDataParam,
  BookingTuning,
  PageTuning,
  Payment,
} from "@/@types";

const Codes = namespace("codes");

@Component({
  components: {
    InstrumentProfileImage,
    ProfileTag,
    RecordStatus,
    TuningDetails,
  },
})
export default class TuningRecordCard extends Vue {
  @Codes.State("pageTuning")
  public pageTuningState!: AsyncState<PageTuning>;

  @Prop({ required: true }) public bookingTuning!: BookingTuning;
  @Prop({ required: true }) public payment!: Payment;

  get bannerSrc() {
    if (!this.pageTuningState.data) {
      return "";
    }
    return this.pageTuningState.data.bannerImageUrl;
  }
}
